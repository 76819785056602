import { AppThunk } from '../app/store';
import { AppVersion } from './appSlice';
import {
    Auction,
    Bid,
    BiddingLadder,
    BoardStatKeys,
    BoardStats,
    BridgePosition,
    Card,
    CardId,
    Contract,
    DOMRectObj,
    ForeignBoardReviewData,
    GamePhase,
    PossibleButtonId,
    SeatData,
    SeatPosition,
    SharkGameResults,
    SharkGameResultsV2,
    SuitOrder,
    TableResult,
    Trick,
    Vulnerable,
    WalkThrough,
} from '../app/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { WalkThroughComponentName } from '../features/walk-through-component/walk-through-component';
import { cardActions } from './cardsSlice';
import { defaultBiddingLadder, defaultSeatData } from '../app/defaults';
import { getNextBridgePositionsClockwise, sfsProps } from '../utils/mixed';

type UpdateBid = Pick<Bid, 'call'> & Partial<Omit<Bid, 'call'>>;

export type TableInfoObject = {
    label: string;
    value: string;
    hidden?: boolean;
};

export type TableInfo = TableInfoObject | string;

export type AuxiliaryMessage = {
    text: string;
    title: string;
    titleColor: string;
    buttonOk: string;
    buttonCancel: string;
    buttonAuxiliary: string;
    bottom?: boolean;
    value?: object;
};

export type ButtonKey =
    | 'button-0'
    | 'button-1'
    | 'button-2'
    | 'button-3'
    | 'button-4'
    | 'button-5'
    | 'button-6'
    | 'button-7'
    | 'button-8'
    | 'button-9'
    | 'button-10'
    | 'button-11';

export type Button = {
    label: string;
    id: PossibleButtonId;
    value: any;
    highlighted: boolean;
    key: ButtonKey;
    icon?: boolean;
};

export type Tooltip = {
    name: string;
    boundingClientRect: DOMRectObj;
};

export type Video = {
    micro?: boolean;
    sound?: boolean;
    camera?: boolean;
};

export type TableState = {
    active?: BridgePosition;
    auction: Auction;
    auxiliaryMessages: {
        center?: AuxiliaryMessage;
        left?: AuxiliaryMessage;
        right?: AuxiliaryMessage;
    };
    biddingLadder: BiddingLadder;
    board?: {
        label?: string;
        value?: string;
        boardNumber?: number;
    };
    boardStats: BoardStats[];
    bpnRecord?: string;
    bridgeTableHTML?: string;
    bridgeTableVisibility: boolean;
    buttons: Partial<Record<ButtonKey, Button>>;
    cardBackground: number;
    cardWidthMultiplier: number;
    centerSeat: boolean;
    contract?: Contract;
    conventionCards: {
        ns?: string;
        ew?: string;
        url?: string;
    };
    conventionCardUrlSet: boolean;
    dealer?: BridgePosition;
    declarer?: BridgePosition;
    directorIssue: string;
    directorIssueDone: boolean;
    ewtricks: number;
    footerButtons: Partial<Record<ButtonKey, Button>>;
    forceShowAllCards: boolean;
    foreignBoardReviewData?: ForeignBoardReviewData;
    gamePhase: GamePhase;
    indexButtons: Button[];
    isBlockedByTeacher: boolean;
    isSpectator: boolean;
    jitsi: {
        configOverwrite?: any;
        domain?: string;
        fullSize: boolean;
        interfaceConfigOverwrite?: any;
        password?: string;
        roomName?: string;
        userName?: string;
        breakOut?: string;
        jwt?: string;
        goToBreakout: boolean;
    };
    myBoardStatKeys?: BoardStatKeys;
    myHighlightedCards: Card['id'][];
    nstricks: number;
    player?: BridgePosition;
    seatData: Record<SeatPosition, SeatData>;
    sharkGameResults?: SharkGameResults;
    sharkGameResultsV2?: SharkGameResultsV2;
    sharkMetaData?: {
        director: string;
        eventName: string;
        hostName: string;
        resultUrl: string;
        round: string;
        tableResults: TableResult[];
        welcomeMessage: string;
        comment: string;
        timetoround: Date;
        isSupervised: boolean;
        isBiddingQuiz: boolean;
    };
    shouldCloseTrick: boolean;
    showAuctionLine: boolean;
    showAuctionBox: boolean;
    showClaimedTrick?: number;
    showGameResults?: 'fullscreen' | 'modal';
    showIndexButtons: boolean;
    showInvites: boolean;
    showTrickCounter?: AppVersion;
    suitOrder: SuitOrder;
    tableInfo: {
        left?: TableInfo[];
        right?: TableInfo[];
    };
    tableSymbol: boolean;
    timeToGameStart?: number;
    timeToGameEnd?: number;
    tooltip?: Tooltip;
    tricks: Trick[];
    userShowAuctionLine: boolean;
    video?: Video;
    vulnerable?: Vulnerable;
    walkThrough?: WalkThrough;
    walkThroughIframe?: string;
    walkThroughShowComponents: WalkThroughComponentName[];
};

export const initialState: TableState = {
    auction: [],
    auxiliaryMessages: {},
    biddingLadder: defaultBiddingLadder,
    boardStats: [],
    bridgeTableVisibility: false,
    buttons: {},
    cardBackground: 0,
    cardWidthMultiplier: 1,
    centerSeat: false,
    conventionCards: {},
    conventionCardUrlSet: false,
    directorIssue: '',
    directorIssueDone: false,
    ewtricks: 0,
    footerButtons: {},
    forceShowAllCards: false,
    gamePhase: GamePhase.PRE,
    // indexButtons: mockedFooterButtons as Button[],
    indexButtons: [],
    isBlockedByTeacher: false,
    isSpectator: false,
    jitsi: {
        fullSize: false,
        goToBreakout: false,
    },
    myHighlightedCards: [],
    nstricks: 0,
    seatData: {
        [SeatPosition.top]: defaultSeatData,
        [SeatPosition.right]: defaultSeatData,
        [SeatPosition.bottom]: defaultSeatData,
        [SeatPosition.left]: defaultSeatData,
    },
    shouldCloseTrick: false,
    showAuctionLine: false,
    showAuctionBox: true,
    showIndexButtons: false,
    showInvites: false,
    suitOrder: SuitOrder.SHCD,
    tableInfo: {},
    tableSymbol: false,
    tricks: [],
    showTrickCounter: 'audrey',
    userShowAuctionLine: false,
    walkThroughShowComponents: ['sharkBoardReviewComponent'],
};

export const tableSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {
        table_addTrick: (state, { payload }: PayloadAction<Trick>) => {
            state.tricks = [...state.tricks, payload];
        },
        table_claimTrick: (state, { payload }: PayloadAction<BridgePosition>) => {
            const lastTrickIndex = state.tricks.length - 1;
            state.tricks[lastTrickIndex] = {
                ...state.tricks[lastTrickIndex],
                winner: payload,
            };
        },
        table_playCard: (state, { payload }: PayloadAction<CardId>) => {
            const lastTrickIndex = state.tricks.length - 1;
            state.tricks[lastTrickIndex] = {
                ...state.tricks[lastTrickIndex],
                cardIds: [...state.tricks[lastTrickIndex].cardIds, payload],
            };
        },
        table_setActive: (state, { payload }: PayloadAction<TableState['active']>) => {
            state.active = payload;
        },
        table_setAuction: (state, { payload }: PayloadAction<TableState['auction']>) => {
            state.auction = payload;
        },
        table_setAuxiliaryMessage: (
            state,
            {
                payload: { position, message },
            }: PayloadAction<{
                position: keyof TableState['auxiliaryMessages'];
                message: AuxiliaryMessage;
            }>,
        ) => {
            state.auxiliaryMessages = {
                ...state.auxiliaryMessages,
                [position]: message,
            };
        },
        table_setBiddingLadder: (state, { payload }: PayloadAction<TableState['biddingLadder']>) => {
            state.biddingLadder = payload;
        },
        table_setBoard: (state, { payload }: PayloadAction<TableState['board']>) => {
            state.board = payload;
        },
        table_setBoardStats: (state, { payload }: PayloadAction<TableState['boardStats']>) => {
            state.boardStats = payload;
        },
        table_setBridgeTableHTML: (state, { payload }: PayloadAction<TableState['bridgeTableHTML']>) => {
            state.bridgeTableHTML = payload;
        },
        table_setBridgeTableVisibility: (state, { payload }: PayloadAction<TableState['bridgeTableVisibility']>) => {
            state.bridgeTableVisibility = payload;
        },
        table_setCardBackground: (state, { payload }: PayloadAction<TableState['cardBackground']>) => {
            state.cardBackground = payload;
        },
        table_setCardWidthMultiplier: (state, { payload }: PayloadAction<TableState['cardWidthMultiplier']>) => {
            state.cardWidthMultiplier = payload;
        },
        table_setConventionCards: (
            state,
            { payload }: PayloadAction<{ partnership: 'ns' | 'ew' | 'url'; url: string }>,
        ) => {
            const { partnership, url } = payload;
            state.conventionCards = {
                ...state.conventionCards,
                [partnership]: url,
            };
        },
        table_setCenterSeat: (state, { payload }: PayloadAction<TableState['centerSeat']>) => {
            state.centerSeat = payload;
        },
        table_setContract: (state, { payload }: PayloadAction<TableState['contract']>) => {
            state.contract = payload;
        },
        table_setDealer: (state, { payload }: PayloadAction<TableState['dealer']>) => {
            state.dealer = payload;
        },
        table_setDeclarer: (state, { payload }: PayloadAction<TableState['declarer']>) => {
            state.declarer = payload;
        },
        table_setFooterButtons: (state, { payload }: PayloadAction<TableState['footerButtons']>) => {
            state.footerButtons = payload;
        },
        table_setForceShowAllCards: (state, { payload }: PayloadAction<TableState['forceShowAllCards']>) => {
            state.forceShowAllCards = payload;
        },
        table_setForeignBoardReviewData: (state, { payload }: PayloadAction<TableState['foreignBoardReviewData']>) => {
            state.foreignBoardReviewData = payload;
        },
        table_setGamePhase: (state, { payload }: PayloadAction<TableState['gamePhase']>) => {
            state.gamePhase = payload;
        },
        table_setIndexButtons: (state, { payload }: PayloadAction<TableState['indexButtons']>) => {
            state.indexButtons = payload;
        },
        table_setMyBoardStatKeys: (state, { payload }: PayloadAction<TableState['myBoardStatKeys']>) => {
            state.myBoardStatKeys = payload;
        },
        table_setPlayer: (state, { payload }: PayloadAction<TableState['player']>) => {
            state.player = payload;
        },
        table_setPrimaryLabelToBridgePosition: (state) => {
            state.seatData[SeatPosition.top].labelPrimary = state.seatData[SeatPosition.top].bridgePosition;
            state.seatData[SeatPosition.right].labelPrimary = state.seatData[SeatPosition.right].bridgePosition;
            state.seatData[SeatPosition.bottom].labelPrimary = state.seatData[SeatPosition.bottom].bridgePosition;
            state.seatData[SeatPosition.left].labelPrimary = state.seatData[SeatPosition.left].bridgePosition;
        },
        table_setSeatData: (state, { payload }: PayloadAction<{ seatPosition: SeatPosition } & Partial<SeatData>>) => {
            const { seatPosition, ...seatData } = payload;
            state.seatData = {
                ...state.seatData,
                [seatPosition]: {
                    ...state.seatData[seatPosition],
                    ...seatData,
                },
            };
        },
        table_setSharkGameResults: (state, { payload }: PayloadAction<TableState['sharkGameResults']>) => {
            state.sharkGameResults = payload;
        },
        table_setSharkGameResultsV2: (state, { payload }: PayloadAction<TableState['sharkGameResultsV2']>) => {
            state.sharkGameResultsV2 = payload;
        },
        table_setSharkMetaData: (state, { payload }: PayloadAction<TableState['sharkMetaData']>) => {
            state.sharkMetaData = payload;
        },
        table_setShowAuctionLine: (state, { payload }: PayloadAction<TableState['showAuctionLine']>) => {
            state.showAuctionLine = payload;
        },
        table_setShowTrickCounter: (state, { payload }: PayloadAction<TableState['showTrickCounter']>) => {
            state.showTrickCounter = payload;
        },
        table_setSuitOrder: (state, { payload }: PayloadAction<TableState['suitOrder']>) => {
            state.suitOrder = payload;
        },
        table_setTableInfo: (state, { payload }: PayloadAction<TableState['tableInfo']>) => {
            state.tableInfo = payload;
        },
        table_setTableSymbol: (state, { payload }: PayloadAction<TableState['tableSymbol']>) => {
            state.tableSymbol = payload;
        },
        table_setTopBridgePosition: (state, { payload }: PayloadAction<BridgePosition>) => {
            const bridgePosition = payload;
            state.seatData = {
                [SeatPosition.top]: {
                    ...state.seatData[SeatPosition.top],
                    bridgePosition: bridgePosition,
                    sfsProp: sfsProps[bridgePosition],
                    seatPosition: SeatPosition.top,
                },
                [SeatPosition.right]: {
                    ...state.seatData[SeatPosition.right],
                    bridgePosition: getNextBridgePositionsClockwise[bridgePosition][0],
                    sfsProp: sfsProps[getNextBridgePositionsClockwise[bridgePosition][0]],
                    seatPosition: SeatPosition.right,
                },
                [SeatPosition.bottom]: {
                    ...state.seatData[SeatPosition.bottom],
                    bridgePosition: getNextBridgePositionsClockwise[bridgePosition][1],
                    sfsProp: sfsProps[getNextBridgePositionsClockwise[bridgePosition][1]],
                    seatPosition: SeatPosition.bottom,
                    isMe: true,
                },
                [SeatPosition.left]: {
                    ...state.seatData[SeatPosition.left],
                    bridgePosition: getNextBridgePositionsClockwise[bridgePosition][2],
                    sfsProp: sfsProps[getNextBridgePositionsClockwise[bridgePosition][2]],
                    seatPosition: SeatPosition.left,
                },
            };
        },
        table_setTooltip: (state, { payload }: PayloadAction<TableState['tooltip']>) => {
            state.tooltip = payload;
        },
        table_setTricks: (state, { payload }: PayloadAction<TableState['tricks']>) => {
            state.tricks = payload;
        },
        table_setTrickCounts: (
            state,
            { payload }: PayloadAction<{ ns: TableState['nstricks']; ew: TableState['ewtricks'] }>,
        ) => {
            state.nstricks = payload.ns;
            state.ewtricks = payload.ew;
        },
        table_setVulnerable: (state, { payload }: PayloadAction<TableState['vulnerable']>) => {
            state.vulnerable = payload;
        },
        table_setWalkTrough: (state, { payload }: PayloadAction<TableState['walkThrough']>) => {
            state.walkThrough = payload;
        },
        table_setWalkThroughIframe: (state, { payload }: PayloadAction<TableState['walkThroughIframe']>) => {
            state.walkThroughIframe = payload;
        },
        table_shouldCloseTrick: (state, { payload }: PayloadAction<TableState['shouldCloseTrick']>) => {
            state.shouldCloseTrick = payload;
        },
        table_showClaimedTrick: (state, { payload }: PayloadAction<TableState['showClaimedTrick']>) => {
            state.showClaimedTrick = state.showClaimedTrick === payload ? undefined : payload;
        },
        table_toggleUserShowAuctionLine: (state) => {
            state.userShowAuctionLine = !state.userShowAuctionLine;
        },
        table_updateAuctionBids: (state, { payload }: PayloadAction<UpdateBid[]>) => {
            state.auction = getUpdatedBids(state.auction, payload);
        },
        table_updateBiddingLadder: (state, { payload }: PayloadAction<Partial<TableState['biddingLadder']>>) => {
            state.biddingLadder = {
                ...state.biddingLadder,
                ...payload,
            };
        },
        table_updateBiddingLadderBids: (state, { payload }: PayloadAction<UpdateBid[]>) => {
            state.biddingLadder = {
                ...state.biddingLadder,
                bids: getUpdatedBids(state.biddingLadder.bids, payload),
            };
        },
        table_updateFooterButtons: (state, { payload }: PayloadAction<{ key: ButtonKey; button: Button }[]>) => {
            payload.forEach(({ key, button }) => {
                if (button) {
                    state.footerButtons[key] = button;
                } else {
                    delete state.footerButtons[key];
                }
            });
        },
        table_updateSeatData: (
            state,
            {
                payload: { seatPosition, seatData },
            }: PayloadAction<{ seatPosition: SeatPosition; seatData: Partial<SeatData> }>,
        ) => {
            state.seatData = {
                ...state.seatData,
                [seatPosition]: {
                    ...state.seatData[seatPosition],
                    ...seatData,
                },
            };
        },
        table_updateTableInfo: (
            state,
            {
                payload: { side, info },
            }: PayloadAction<{ side: keyof TableState['tableInfo']; info: TableState['tableInfo']['left'] }>,
        ) => {
            state.tableInfo = {
                ...state.tableInfo,
                [side]: info,
            };
        },
        table_updateTableInfoLine: (
            state,
            {
                payload: { side, info, index },
            }: PayloadAction<{ side: keyof TableState['tableInfo']; info: TableInfo; index: number }>,
        ) => {
            state.tableInfo[side]![index] = info;
        },
        table_updateTrick: (state, { payload: { index, trick } }: PayloadAction<{ index: number; trick: Trick }>) => {
            if (index < 0) {
                state.tricks[state.tricks.length + index] = trick;
            } else {
                state.tricks[index] = trick;
            }
        },
        table_setVideo: (state, { payload }: PayloadAction<Partial<TableState['video']>>) => {
            state.video = payload == null ? undefined : { ...(initialState.video ?? {}), ...payload };
        },
        // generic partial table update
        table_updateTable: (state, { payload }: PayloadAction<Partial<TableState>>) => {
            return {
                ...state,
                ...payload,
            };
        },
        // all the reset stuff
        table_reset: () => initialState,
        table_resetPartial: (state, { payload }: PayloadAction<(keyof TableState)[]>) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            payload.forEach((tableStateKey) => (state[tableStateKey] = initialState[tableStateKey]));
        },
        table_resetBiddingLadderBids: (state) => {
            state.biddingLadder.bids = defaultBiddingLadder.bids;
        },
    },
});

export const tableActions = tableSlice.actions;

const getUpdatedBids = (currentBids: Bid[], updateBids: UpdateBid[]): Bid[] => {
    const updatedBids = currentBids.map((currentBid) => {
        const updateBid = updateBids.find((_updateBid) => _updateBid.call === currentBid.call);
        if (updateBid) {
            return {
                ...currentBid,
                ...updateBid,
            };
        }
        return currentBid;
    });
    return updatedBids;
};

export const playCard =
    (cardId: CardId): AppThunk =>
    (dispatch) => {
        dispatch(tableActions.table_playCard(cardId));
        dispatch(cardActions.cards_playCard(cardId));
    };

// TODO: add some selectors
// export const selectHighlighted = (state: RootState) => state.card.highlighted;
// export const selectRaised = (state: RootState) => state.card.raised;
// export const selectRank = (state: RootState) => state.card.rank;
// export const selectSuit = (state: RootState) => state.card.suit;
// export const selectSuitSymbol = (state: RootState) => state.card.suitSymbol;
// export const selectVisible = (state: RootState) => state.card.visible;

export default tableSlice.reducer;
