import './table-component.scss';
import { AuxiliaryMessageComponent } from '../auxiliary-message-component/auxiliary-message-component';
import { SeatComponent } from '../seat-component/seat-component';
import { SeatPosition } from '../../app/types';
import { SimpleTricksCounterComponent } from '../simple-tricks-counter-component/simple-tricks-counter-component';
import { TableInfoComponent } from '../table-info-component/table-info-component';
import { TricksComponent } from '../tricks-component/tricks-component';
import { classNames, getCardsOfSeat, getMaxCardsPerGroup } from '../../utils/mixed';
import { environment } from '../../env';
import { outputActions } from '../../slices/outputSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import AuctionBoxComponent from '../auction-box-component/auction-box-component';
import BiddingKeyboardComponent from '../bidding-keybord-component/bidding-keyboard-component';
import React, { useMemo } from 'react';
import TableSymbolComponent from '../table-symbol-component/table-symbol-component';
import TrickCounterComponent from '../trick-counter-component/trick-counter-component';


export const TableComponent: React.FC = () => {
    const dispatch = useAppDispatch();
    const { output_setButton } = outputActions;
    const {
        bridgeTableHTML,
        bridgeTableVisibility,
        cardWidthMultiplier,
        centerSeat,
        contract,
        seatData,
        suitOrder,
        auxiliaryMessages,
        showTrickCounter,
        shouldCloseTrick,
        walkThrough,
        tableSymbol,
    } = useAppSelector((state) => state.table);
    const cardsState = useAppSelector((state) => state.cards);
    const seatsArray = [SeatPosition.top, SeatPosition.right, SeatPosition.bottom, SeatPosition.left];
    const maxCards = useMemo(() => {
        return [
            SeatPosition.top,
            SeatPosition.right,
            SeatPosition.bottom,
            SeatPosition.left,
        ].reduce<React.CSSProperties>((currCssVars, seatPosition) => {
            const bridgePosition = seatData[seatPosition].bridgePosition;
            if (bridgePosition) {
                const cards = getCardsOfSeat(cardsState, seatData[seatPosition].bridgePosition, contract, suitOrder);
                return {
                    ...currCssVars,
                    [`--max-cards-${seatPosition}`]: getMaxCardsPerGroup(cards),
                };
            }
            return currCssVars;
        }, {});
    }, [cardsState]);

    const style = {
        '--tableBaseCardWidthMultiplier': cardWidthMultiplier,
        // '--bottomSeatCardWidthMultiplier': seatData[SeatPosition.bottom].cardWidthMultiplier,
        '--dummyVerticalMargin': walkThrough ? 20 : 5,
        '--baseCardWidthMultiplier-top': cardWidthMultiplier * seatData[SeatPosition.top].cardWidthMultiplier,
        '--baseCardWidthMultiplier-right': cardWidthMultiplier * seatData[SeatPosition.right].cardWidthMultiplier,
        '--baseCardWidthMultiplier-bottom': cardWidthMultiplier * seatData[SeatPosition.bottom].cardWidthMultiplier,
        '--baseCardWidthMultiplier-left': cardWidthMultiplier * seatData[SeatPosition.left].cardWidthMultiplier,
        ...maxCards,
    } as React.CSSProperties;

    const seatSizes = seatsArray.map((seatPosition) => `seat-size-${seatPosition}-${seatData[seatPosition].size}`);
    return (
        <section
            className={classNames(
                'TableComponent',
                centerSeat && 'center-seat',
                seatSizes.join(' '),
                !bridgeTableVisibility && 'bridge-table-hidden',
            )}
            style={{ ...style }}
            onMouseDown={() => {
                shouldCloseTrick && dispatch(output_setButton({ id: 'closeTrick', value: true }));
            }}
        >
            {shouldCloseTrick && (
                <div
                    className={classNames('close-trick')}
                    onMouseDown={() => {
                        dispatch(output_setButton({ id: 'closeTrick', value: true }));
                    }}
                />
            )}

            <SeatComponent seatPosition={SeatPosition.top} />
            {!centerSeat && (
                <>
                    <SeatComponent seatPosition={SeatPosition.right} />
                    <SeatComponent seatPosition={SeatPosition.bottom} />
                    <SeatComponent seatPosition={SeatPosition.left} />
                </>
            )}
            <TricksComponent />
            {showTrickCounter === 'audrey' && <SimpleTricksCounterComponent />}
            {showTrickCounter === 'shark' && <TrickCounterComponent />}
            {environment === 'shark' && (
                    <BiddingKeyboardComponent />
                )
            }

            <TableInfoComponent side="left" />
            <TableInfoComponent side="right" />
            {tableSymbol && <TableSymbolComponent />}

            {auxiliaryMessages.center && <AuxiliaryMessageComponent position="center" />}
            {auxiliaryMessages.left && <AuxiliaryMessageComponent position="left" />}
            {auxiliaryMessages.right && <AuxiliaryMessageComponent position="right" />}

            {bridgeTableHTML && (
                <div className="bridge-table-html" dangerouslySetInnerHTML={{ __html: bridgeTableHTML }} />
            )}
        </section>
    );
};
