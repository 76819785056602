import './footer-component.scss';
import { ButtonComponent } from '../button-component/button-component';
import { classNames } from '../../utils/mixed';
import { outputActions } from '../../slices/outputSlice';
import { settingsButton } from '../../app-interfaces/buttonsState';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import React from 'react';

export const FooterComponent: React.FC = () => {
    const { footerButtons, forceShowAllCards } = useAppSelector((state) => state.table);
    const dispatch = useAppDispatch();
    const { output_setButton } = outputActions;
    const { engineState } = useAppSelector((state) => state.app);
    return (
        <footer className={classNames('FooterComponent', engineState.service)}>
            {Object.values(footerButtons).map((button) => (
                <ButtonComponent key={`${button.id} ${button.key}`} button={button} />
            ))}
            <ButtonComponent button={settingsButton} />
            {engineState.service === 'BetterBridgeClub' && (
                <div className="card-button" onClick={() => dispatch(output_setButton({ id: 'showAll', value: true }))}>
                    {forceShowAllCards ? (
                        <img src="./card_set/cardback_blue.png" alt="" />
                    ) : (
                        <img src="./card_set/sk.png" alt="" />
                    )}
                </div>
            )}
        </footer>
    );
};
