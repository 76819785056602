import './button-component.scss';
import { Button } from '../../slices/tableSlice';
import { classNames } from '../../utils/mixed';
import { environment } from '../../env';
import { outputActions } from '../../slices/outputSlice';
import { useAppDispatch } from '../../app/hooks';
import React from 'react';

export const ButtonComponent: React.FC<{ button: Button }> = ({ button: { label, highlighted, icon, ...rest } }) => {
    const dispatch = useAppDispatch();
    const { output_setButton } = outputActions;
    return (
        <button
            className={classNames(
                'ButtonComponent',
                highlighted ? 'highlighted' : '',
                icon ? 'icon' : '',
                rest.key,
                rest.id,
            )}
            onClick={() => dispatch(output_setButton(rest))}
        >
            {environment === 'audrey' && rest.id === 'showSharkSettings' && <img src="./images/settings30_30.png" />}
            <span>{label}</span>
        </button>
    );
};
