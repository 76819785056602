import {
    Bid,
    BridgePosition,
    Card,
    ClickEventData,
    SharkGameResultsPairV2,
    SharkGameResultsTravelerV2,
    SharkGameResultsV2,
} from '../app/types';
import { Button } from './tableSlice';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type OutputState = {
    approveClaim?: 'approve' | 'reject';
    approveUndo?: 'approve' | 'reject';
    button?: Pick<Button, 'id' | 'value'>;
    bid?: Bid;
    bidExp?: Bid,
    card?: Card;
    changePlay?: 'startPlay' | 'stopPlay' | 'replay';
    claim?: number;
    clickEventData?: ClickEventData;
    closeResults?: true;
    conventionCardUrl?: string;
    director?: string;
    invite?: {
        email: string;
        bridgePosition: BridgePosition;
    };
    loadBoardReview?: {
        bn: SharkGameResultsTravelerV2['bn'];
        pair: SharkGameResultsPairV2['uuid'];
        game: SharkGameResultsV2['UUID'];
        comparisonUuid?: SharkGameResultsPairV2['uuid'];
    };
    prevNext?: 'prev' | 'next';
    undo?: true;
};

export const initialState: OutputState = {};

export const outputSlice = createSlice({
    name: 'output',
    initialState,
    reducers: {
        output_loadBoardReview: (state, { payload }: PayloadAction<OutputState['loadBoardReview']>) => {
            state.loadBoardReview = payload;
        },
        output_sendConventionCardUrl: (state, { payload }: PayloadAction<OutputState['conventionCardUrl']>) => {
            state.conventionCardUrl = payload;
        },
        output_setApproveClaim: (state, { payload }: PayloadAction<OutputState['approveClaim']>) => {
            state.approveClaim = payload;
        },
        output_setApproveUndo: (state, { payload }: PayloadAction<OutputState['approveUndo']>) => {
            state.approveUndo = payload;
        },
        output_setButton: (state, { payload }: PayloadAction<OutputState['button']>) => {
            state.button = payload;
        },
        output_setBid: (state, { payload }: PayloadAction<Bid>) => {
            state.bid = payload;
        },
        output_setBidExp: (state, { payload }: PayloadAction<Bid>) => {
            state.bidExp = payload;
        },
        output_setCard: (state, { payload }: PayloadAction<Card>) => {
            state.card = payload;
        },
        output_setChangePlay: (state, { payload }: PayloadAction<OutputState['changePlay']>) => {
            state.changePlay = payload;
        },
        output_setCloseResults: (state, { payload }: PayloadAction<OutputState['closeResults']>) => {
            state.closeResults = payload;
        },
        output_setDirector: (state, { payload }: PayloadAction<OutputState['director']>) => {
            state.director = payload;
        },
        output_setInvite: (state, { payload }: PayloadAction<OutputState['invite']>) => {
            state.invite = payload;
        },
        output_setMakeClaim: (state, { payload }: PayloadAction<number>) => {
            state.claim = payload;
        },
        output_setPrevNext: (state, { payload }: PayloadAction<OutputState['prevNext']>) => {
            state.prevNext = payload;
        },
        output_setRequestUndo: (state, { payload }: PayloadAction<OutputState['undo']>) => {
            state.undo = payload;
        },
        output_setWalkThruTarget: (state, { payload }: PayloadAction<OutputState['clickEventData']>) => {
            state.clickEventData = payload;
        },
        // resets
        output_reset: () => initialState,
    },
});

export const outputActions = outputSlice.actions;

// TODO: add some selectors
// export const selectHighlighted = (state: RootState) => state.card.highlighted;
// export const selectRaised = (state: RootState) => state.card.raised;
// export const selectRank = (state: RootState) => state.card.rank;
// export const selectSuit = (state: RootState) => state.card.suit;
// export const selectSuitSymbol = (state: RootState) => state.card.suitSymbol;
// export const selectVisible = (state: RootState) => state.card.visible;

export default outputSlice.reducer;
