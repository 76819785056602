import { Bid, BridgePosition, ForeignBoardReviewData, ForeignBoardReviewPlayerData } from '../../app/types';
import { SharkBidComponent } from '../shark-bid-component/shark-bid-component';
import { getMySeatData, isVulnerable } from '../../utils/mixed';
import { outputActions } from '../../slices/outputSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent, useRef, useState } from 'react';

import './auction-box-component.scss';
import { convertStringToBid } from '../../app-interfaces/shark-game-engine-helper';

const rotationHelper = [
    BridgePosition.north,
    BridgePosition.east,
    BridgePosition.south,
    BridgePosition.west,
    BridgePosition.north,
    BridgePosition.east,
    BridgePosition.south,
    BridgePosition.west,
];

export type AuctionBoxProps = {
    foreignBoardData?: Pick<ForeignBoardReviewData, 'seatData' | 'vulnerable' | 'showAuctionBox'> & {
        auction: ForeignBoardReviewPlayerData['auction'];
    };
};

const AuctionBoxComponent: FunctionComponent<AuctionBoxProps> = ({ foreignBoardData }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [lastAuctionLength, setLastAuctionLength] = useState<number>(0);
    const currentBidder = useRef(null);
    const table = useAppSelector((state) => state.table);
    const { sharkLayout } = useAppSelector((state) => state.app);
    const { output_setBid } = outputActions;

    let seatData = table.seatData;
    let vulnerable = table.vulnerable;
    let showAuctionBox = table.showAuctionBox;
    let auction = table.auction;

    if (foreignBoardData) {
        seatData = foreignBoardData.seatData;
        vulnerable = foreignBoardData.vulnerable;
        showAuctionBox = foreignBoardData.showAuctionBox;
        auction = foreignBoardData.auction;
    }

    const { selectedCardSet } = sharkLayout;

    const mySeatData = getMySeatData(seatData);

    if (lastAuctionLength !== auction.length) {
        setLastAuctionLength(auction.length);
        setTimeout(() => {
            if (currentBidder?.current) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // currentBidder.current.scrollIntoView({
                //     behavior: 'smooth'
                // });
            }
        }, 100);
    }

    if (!mySeatData?.bridgePosition) {
        return null;
    }

    const index = rotationHelper.indexOf(mySeatData.bridgePosition);
    const auctionRotation = rotationHelper.slice(index + 1, index + 5);
    let dealerIndex = 0;

    const renderBiddingHistory = () => {
        const shiftedBiddingHistory: (Bid | undefined)[] = [undefined, undefined, undefined, undefined];
        let biddingHistory = shiftedBiddingHistory.slice(0, dealerIndex).concat(auction);

        const { length } = auction;

        if (
            !(
                auction.length >= 4 &&
                auction[length - 1].call.toLowerCase() === 'pass' &&
                auction[length - 2].call.toLowerCase() === 'pass' &&
                auction[length - 3].call.toLowerCase() === 'pass'
            )
        ) {
            biddingHistory = biddingHistory.concat([undefined]);
        }

        return biddingHistory
            .map((call, index) => {
                if (call?.call) {
                    const bid = convertStringToBid(call.call);
                    return (
                        // <td key={`call-${index}`} onClick={() => clickBid(index - dealerIndex)}>
                        <td key={`call-${index}`} onClick={() => dispatch(output_setBid(bid))}>
                            <SharkBidComponent
                                key={`${bid.level}-${bid.strain}-${index}`}
                                bid={bid}
                                selectedCardSet={selectedCardSet}
                            />
                        </td>
                    );
                } else {
                    const props = {
                        className: 'empty',
                        ...(index === biddingHistory.length - 1 ? { id: 'currentBidder' } : {}),
                        ...(index === biddingHistory.length - 1 ? { ref: currentBidder } : {}),
                    };
                    return <td key={`empty-${index}`} {...props} />;
                }
            })
            .reduce((prev: JSX.Element[][], curr: JSX.Element, index) => {
                const _prev = [...prev];
                if (index % 4 === 0) {
                    _prev.push([]);
                }
                _prev[_prev.length - 1].push(curr);
                return _prev;
            }, [])
            .map((tr, index) => <tr key={`tr-${index}`}>{tr}</tr>);
    };

    return (
        <section
            className={`AuctionBox vulnerable-${vulnerable} ${showAuctionBox ? 'showAuctionBox' : 'hideAuctionBox'}`}
        >
            <div className="thead">
                <table className="auction">
                    <thead className="bridgePositions">
                        <tr>
                            {auctionRotation.map((bridgePosition, index) => {
                                if (table.dealer === bridgePosition) {
                                    dealerIndex = index;
                                }
                                return (
                                    <th
                                        key={`bridgePosition-${index}`}
                                        className={`bridgePosition ${
                                            isVulnerable(bridgePosition, vulnerable) ? 'vulnerable' : ''
                                        }`}
                                    >
                                        {t(`bridgePosition.${bridgePosition}`)}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="tbody">
                <table className="auction">
                    <tbody className="setWidths">
                        <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                    </tbody>
                    <tbody className="calls">{renderBiddingHistory()}</tbody>
                </table>
            </div>
        </section>
    );
};

export default AuctionBoxComponent;
