import './settings-component.scss';
import { AppState, appActions } from '../../slices/appSlice';
import { Speed, localStorageKeys } from '../../app/types';
import { classNames, setLocalstorage } from '../../utils/mixed';
import { speeds } from '../../app/defaults';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import React, { useState } from 'react';

export const SettingsComponent: React.FC = () => {
    const dispatch = useAppDispatch();
    const { app_setSettings, app_setShowSettings } = appActions;
    const { settings } = useAppSelector((state) => state.app);
    const [localSettings, setLocalSettings] = useState<AppState['settings']>(settings);
    const { speedCards, fourColorCards, requiredNext, sound, speedScreens, bidLayConfirm } = localSettings;

    return (
        <section className="SettingsComponent">
            <div className="backdrop" />
            <div className="modal">
                <div className="title">Settings</div>
                <div className="body">
                    <Checkbox checked={sound} onClick={() => setLocalSettings({ ...localSettings, sound: !sound })}>
                        Sound
                    </Checkbox>
                    <Checkbox
                        checked={fourColorCards}
                        onClick={() => setLocalSettings({ ...localSettings, fourColorCards: !fourColorCards })}
                    >
                        4 Color cards
                    </Checkbox>
                    <Checkbox
                        checked={requiredNext}
                        onClick={() => setLocalSettings({ ...localSettings, requiredNext: !requiredNext })}
                    >
                        Next button required to advance screens
                    </Checkbox>
                    <ButtonGroup
                        selected={speedScreens}
                        onChange={(speed) => setLocalSettings({ ...localSettings, speedScreens: speed })}
                    >
                        Lesson Screen Change Speed
                    </ButtonGroup>
                    <ButtonGroup
                        selected={speedCards}
                        onChange={(speed) => setLocalSettings({ ...localSettings, speedCards: speed })}
                    >
                        Bidding & Card Play Speed
                    </ButtonGroup>
                    <Checkbox
                        checked={bidLayConfirm}
                        onClick={() => setLocalSettings({ ...localSettings, bidLayConfirm: !bidLayConfirm })}
                    >
                        Confirm when placing a bid or playing a card
                    </Checkbox>
                </div>
                <div className="footer">
                    <button
                        onClick={() => {
                            dispatch(app_setSettings(localSettings as Partial<AppState['settings']>));
                            dispatch(app_setShowSettings(false));
                            setLocalstorage(localStorageKeys.audrey.settings, localSettings);
                        }}
                    >
                        OK
                    </button>
                    <button onClick={() => dispatch(app_setShowSettings(false))}>Cancel</button>
                </div>
            </div>
        </section>
    );
};

const Checkbox: React.FC<{ checked: boolean; children: string; onClick: () => void }> = ({
    checked,
    children,
    onClick,
}) => {
    return (
        <div className={classNames('checkbox', checked && 'checked')} onClick={onClick}>
            {children}
        </div>
    );
};

const ButtonGroup: React.FC<{ children: string; selected: Speed; onChange: (speed: Speed) => void }> = ({
    children,
    selected,
    onChange,
}) => {
    const buttons = [];
    for (const [speed, label] of speeds) {
        buttons.push(
            <div
                className={classNames('speed', selected === speed && 'selected')}
                key={speed}
                onClick={() => speed !== selected && onChange(speed)}
            >
                {label}
            </div>,
        );
    }

    return (
        <div className="button-group">
            <label>{children}</label>
            <div className="speeds">{buttons.map((button) => button)}</div>
        </div>
    );
};
