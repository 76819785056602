import './tricks-component.scss';
import { TrickComponent } from '../trick-component/trick-component';
import { environment } from '../../env';
import { useAppSelector } from '../../app/hooks';
import React from 'react';

export const TricksComponent: React.FC = () => {
    const { tricks, showClaimedTrick } = useAppSelector((state) => state.table);
    return (
        <section className="TricksComponent">
            {tricks.map((trick, index) => (
                <TrickComponent
                    key={`trick-${index}`}
                    trick={trick}
                    show={environment === 'shark' ? index === tricks.length - 2 : showClaimedTrick === index}
                />
            ))}
        </section>
    );
};
