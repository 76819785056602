import { CardPosition, Cards, HandLayout, SeatPosition } from '../../app/types';
import { TableState } from '../../slices/tableSlice';
import { getCardsByBridgePosition, getCardsOfSeat, sortCardsBySuitOrder } from '../../utils/mixed';
import { useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import React, { CSSProperties, FunctionComponent } from 'react';
import SharkHandComponent from '../shark-hand-component/shark-hand-component';

import './short-table-component.scss';

export type ShortTableProps = {
    cards: Cards;
    contract: TableState['contract'];
    forceShowAll?: boolean;
    gamePhase?: TableState['gamePhase'];
    scale?: number;
    seatData: TableState['seatData'];
};

export const ShortTableComponent: FunctionComponent<ShortTableProps> = ({
    scale = 1,
    forceShowAll,
    cards,
    seatData,
    contract,
}) => {
    const { t } = useTranslation();
    const { sharkLayout } = useAppSelector((state) => state.app);

    const renderHand = (seatPosition: SeatPosition): JSX.Element | undefined => {
        const seat = seatData[seatPosition];
        if (seat) {
            const { bridgePosition } = seat;
            if (!bridgePosition) {
                return;
            }
            const _cards = getCardsByBridgePosition(cards, bridgePosition);
            const suitOrder = sharkLayout.suitOrder;
             
            const hand = sortCardsBySuitOrder(_cards, suitOrder, contract);
            const forceHandLayout: HandLayout = 'paper-style';
            const handProps = {
                currentSeatData: seat,
                hand,
                userVisible: false,
                forceHandLayout,
            };
            return (
                <div className={`SeatPosition ${seatPosition}`}>
                    <h3>{t(`bridgePosition.${bridgePosition}`)}</h3>
                    {(seat.isVisible || seat.isMe || forceShowAll) && <SharkHandComponent {...handProps} />}
                </div>
            );
        }
    };

    return (
        <section className="ShortTableComponent" style={{ '--scale': scale } as CSSProperties}>
            {renderHand(SeatPosition.top)}
            <div className="middle">
                {renderHand(SeatPosition.left)}
                {renderHand(SeatPosition.right)}
            </div>
            {renderHand(SeatPosition.bottom)}
        </section>
    );
};

export default ShortTableComponent;
