import {
    AudreySettings,
    BridgePosition,
    GameEngineInterfaceState,
    GlossaryEntry,
    Modal,
    SharkLayout,
    SharkSettings,
    Speed,
    SuitOrder,
    localStorageKeys,
} from '../app/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SoundFiles } from '../_basics/audioPlayer';
import { getLocalstorage } from '../utils/mixed';
import { isMobile } from 'react-device-detect';
import { tableBackgrounds, trayBackgrounds } from '../features/shark-settings-component/options';

export type AppVersion = 'audrey' | 'shark';

export enum SFSConnectionStatus {
    connected = 'connected',
    connecting = 'connecting',
    disconnected = 'disconnected',
    ignore = 'ignore',
    reconnecting = 'reconnecting',
    registering = 'registering',
}

export type GameService = 'OnlineBridgeClub' | 'BetterBridgeClub' | undefined;
export type AppState = {
    appToken: string;
    bridgePosition?: BridgePosition;
    engineState: {
        initialized: boolean;
        sfsConnectionStatus: SFSConnectionStatus;
        service?: GameService;
        sfsHost?: string;
        interface: GameEngineInterfaceState;
    };
    glossaryDictionary: GlossaryEntry[];
    isLoggedIn: boolean;
    isInteractive: boolean;
    loginError?: string;
    modals: Modal[];
    partner?: string;
    pid?: string;
    rememberMe: boolean;
    requestLogin: boolean;
    requestLogout: boolean;
    settings: AudreySettings;
    sharkLayout: SharkLayout;
    sharkSettings: SharkSettings;
    showSettings: boolean;
    soundPlaying?: keyof SoundFiles;
    tableNumber?: number;
    token?: string;
    urlParams?: Record<string, string>;
    user?: string;
    uuid?: string;
    version: AppVersion;
};

export const initialState: AppState = {
    appToken: 'q9wfpprecaxqs07w13qn',
    engineState: {
        initialized: false,
        sfsConnectionStatus: SFSConnectionStatus.ignore,
        interface: {
            isMiniBridge: false,
            lag: 10,
            playerID: 0,
            competeMode: false,
            boardData: {},
            hasNext: false,
        },
    },
    glossaryDictionary: [],
    isLoggedIn: false,
    isInteractive: true,
    modals: [] as Modal[],
    rememberMe: false,
    requestLogin: false,
    requestLogout: false,
    settings: {
        fourColorCards: false,
        requiredNext: false,
        sound: true,
        speedCardAnimations: Speed.instant,
        speedCards: Speed.medium,
        speedScreens: Speed.medium,
        bidLayConfirm: true,
        ...(getLocalstorage(localStorageKeys.audrey.settings) ?? {}),
    },
    sharkLayout: {
        cardBack: 0,
        colorSets: {
            suits: 0,
            background: 0,
        },
        generalLayout: 'default',
        handLayout: 'straight',
        selectedCardSet: 1,
        suitOrder: SuitOrder.SHDC,
        tableBackground: 0,
        ...(getLocalstorage(localStorageKeys.shark.layout) ?? {}),
    },
    sharkSettings: {
        animations: true,
        currentPlayerHighlight: ['background'],
        interactionCard: isMobile ? 'confirm' : 'instant',
        interactionBid: isMobile ? 'confirm' : 'instant',
        selectedCurrentPlayerBackground: trayBackgrounds[1],
        showMock: false,
        showTableSlotBorders: false,
        showVideo: true,
        silent8x8: false,
        sounds: {
            tournamentStart: false,
            yourTurn: false,
        },
        tableBackgrounds: {
            default: tableBackgrounds[0],
            blockedByTeacher: tableBackgrounds[1],
        },
        visible: false,
        ...(getLocalstorage(localStorageKeys.shark.settings) ?? {}),
    },
    showSettings: false,
    version: 'audrey',
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        app_addModal: (state, { payload }: PayloadAction<Modal>) => {
            state.modals = [...state.modals, payload];
        },
        app_removeModal: (state, { payload }: PayloadAction<Modal['id'] | undefined>) => {
            if (!payload) {
                const modals = [...state.modals];
                modals.pop();
                state.modals = modals;
            } else {
                state.modals = state.modals.filter((modal) => modal.id !== payload);
            }
        },
        app_clearModals: (state) => {
            state.modals = [];
        },
        app_requestLogin: (
            state,
            {
                payload,
            }: PayloadAction<{
                user: AppState['user'];
                token: AppState['token'];
                uuid: AppState['uuid'];
                rememberMe: AppState['rememberMe'];
                partner?: AppState['partner'];
                tableNumber?: AppState['tableNumber'];
                bridgePosition?: AppState['bridgePosition'];
            }>,
        ) => {
            state.user = payload.user;
            state.token = payload.token;
            state.uuid = payload.uuid;
            state.rememberMe = payload.rememberMe ?? false;
            state.partner = payload.partner;
            state.tableNumber = payload.tableNumber;
            state.bridgePosition = payload.bridgePosition;
        },
        app_requestLogout: (state) => {
            state.requestLogout = true;
        },
        app_setEngineState: (state, { payload }: PayloadAction<Partial<AppState['engineState']>>) => {
            state.engineState = {
                ...state.engineState,
                ...payload,
            };
        },
        app_updateEngineInterfaceState: (
            state,
            { payload }: PayloadAction<Partial<AppState['engineState']['interface']>>,
        ) => {
            state.engineState.interface = {
                ...state.engineState.interface,
                ...payload,
            };
        },
        app_setGlossaryDictionary: (state, { payload }: PayloadAction<AppState['glossaryDictionary']>) => {
            state.glossaryDictionary = payload;
        },
        app_setIsLoggedIn: (state, { payload }: PayloadAction<AppState['isLoggedIn']>) => {
            state.isLoggedIn = payload;
        },
        app_setSettings: (state, { payload }: PayloadAction<Partial<AppState['settings']>>) => {
            state.settings = {
                ...state.settings,
                ...payload,
            };
        },
        app_setSharkLayout: (state, { payload }: PayloadAction<Partial<AppState['sharkLayout']>>) => {
            state.sharkLayout = {
                ...state.sharkLayout,
                ...payload,
            };
        },
        app_setSharkSettings: (state, { payload }: PayloadAction<Partial<AppState['sharkSettings']>>) => {
            state.sharkSettings = {
                ...state.sharkSettings,
                ...payload,
            };
        },
        app_setShowSettings: (state, { payload }: PayloadAction<AppState['showSettings']>) => {
            state.showSettings = payload;
        },
        app_setSoundPlaying: (state, { payload }: PayloadAction<AppState['soundPlaying']>) => {
            state.soundPlaying = payload;
        },
        app_setUrlParams: (state, { payload }: PayloadAction<AppState['urlParams']>) => {
            state.urlParams = payload;
        },
        app_setPID: (state, { payload }: PayloadAction<AppState['pid']>) => {
            state.pid = payload;
        },
        app_reset: () => initialState,
    },
});

export const appActions = appSlice.actions;

// TODO: add some selectors
// export const selectHighlighted = (state: RootState) => state.card.highlighted;
// export const selectRaised = (state: RootState) => state.card.raised;
// export const selectRank = (state: RootState) => state.card.rank;
// export const selectSuit = (state: RootState) => state.card.suit;
// export const selectSuitSymbol = (state: RootState) => state.card.suitSymbol;
// export const selectVisible = (state: RootState) => state.card.visible;

export default appSlice.reducer;
