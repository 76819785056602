import { BridgePosition } from '../../app/types';
import { GameEngine, convertIntToBridgePosition } from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';
import { emptyTrick } from '../../app/defaults';

export function MID_TIME_CLOSE_TRICK(cmdObj: SFSObject, sender: GameEngine) {
    const cp: BridgePosition = convertIntToBridgePosition(cmdObj.getInt('ctw'));
    if (sender.props.table.tricks[sender.props.table.tricks.length - 1].cardIds.length === 4) {
        sender.props.table_claimTrick(cp);
        if (sender.props.table.tricks.length < 13) {
            sender.props.table_addTrick(emptyTrick);
        }
    }
    sender.props.table_setTrickCounts({ ns: cmdObj.getInt('nstr'), ew: cmdObj.getInt('ewtr') });

    //todo : SB Code that needs to be checked
    /*
    *        this.props.setActiveSeat(undefined);
        const currentPlayer: number = cmdObj.getInt(SFSVAR.SFSGAME_CURENT_PLAYER);
        this.setState({
            currentPlayerOnServer: currentPlayer
        });
        // Move the trick from center to  bottom right
        this.props.setIsHighlighted(convertIntToBridgePosition(currentPlayer), true);
        this.props.closeTrick(convertIntToBridgePosition(cmdObj.getInt(SFSVAR.SFSGAME_CURENT_TRICK_WINNER))); // This is the winner of the trick and leader of the next one.
        this.props.setTrickCount({ ns: cmdObj.getInt('nstr'), ew: cmdObj.getInt('ewtr') });
        this.updateBoardData(undefined);

    * */
}
