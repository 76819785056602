import { Bid, BonusIndicator, BridgePosition, Call, SeatPosition } from '../../app/types';
import { GameEngine, convertIntToBridgePosition, convertIntToCall, fixColor } from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';
import { SFSVAR } from '../sfsVar';
import { settingsButton, undoButton } from '../buttonsState';

export function MID_TIME_TO_BID(cmdObj: SFSObject, sender: GameEngine) {
    if (cmdObj.containsKey('highbid') && !cmdObj.containsKey('validbid')) {
        return;
    }
    const player: BridgePosition = convertIntToBridgePosition(cmdObj.getInt(SFSVAR.SFSGAME_CURENT_PLAYER));
    if (sender.props.app.engineState.interface.isMiniBridge) {
        sender.setSimpleMessage(
            'right',
            'This is a mini bridge game!</br></br>Select a contract from the bidding ladder on the right.',
            undefined,
        );
        return;
    } else if (sender.props.app.engineState.service !== 'BetterBridgeClub') {
        sender.setSimpleMessage('right', 'Select a call from the Bidding Ladder on the right.', undefined);
        const bid: Bid = {
            bridgePosition: player,
            call: 'q',
            backgroundColor: undefined,
        };
        sender.props.table_setAuction([...sender.props.table.auction, bid]);
    }
    const stake: number = cmdObj.getInt('stake');
    const validCall: number = cmdObj.containsKey('validbid')
        ? cmdObj.getInt('validbid')
        : cmdObj.containsKey('highbid')
        ? cmdObj.getInt('highbid')
        : 0;
    const minCall: Call = validCall === 0 ? ('1c' as Call) : convertIntToCall(validCall);
    sender.props.table_updateBiddingLadder({
        showArrow: true,
        firstVisibleCall: minCall,
        minimalValidCall: minCall,
        isActive: true,
        isVisible: true,
        showBonusIndicator: true,
        bonusIndicators: [BonusIndicator.j, BonusIndicator.m, BonusIndicator.s, BonusIndicator.g],
        stake:
            stake === 4
                ? {
                      backgroundColor: undefined,
                      call: 'rdbl',
                      disabled: false,
                      invisible: false,
                  }
                : {
                      backgroundColor: undefined,
                      call: 'xdbl',
                      disabled: stake !== 2,
                      invisible: false,
                  },
    });

    sender.props.table_updateTable({
        player: player,
    });

    Object.values(sender.props.table.seatData).forEach((value, index) => {
        sender.props.table_setSeatData({
            seatPosition: value.seatPosition ?? SeatPosition.top,
            background: value.bridgePosition === player ? fixColor('y') : undefined,
        });
    });

    if (sender.shouldShowUndo() && sender.props.table.auction.length >= 4) {
        sender.showButtons([undoButton]);
    }
}
