import { GameEngine } from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';
import { SFSVAR } from '../sfsVar';
import { bridgePos } from '../../app/defaults';
import { getUndoNotificationConfig } from '../../utils/shark-helper';

export function MID_UNDO_NOTIFICATION(cmdObj: SFSObject, sender: GameEngine) {
    //North visible if handVisibility & 2 != 0, East & 4, South & 8, West &16
    sender.props.app_addModal(getUndoNotificationConfig(bridgePos(cmdObj.getInt(SFSVAR.SFSGAME_SEAT_ID))));
}
