import { OutputState, outputActions } from '../../slices/outputSlice';
import { SharkGameResultsPairV2, SharkGameResultsTravelerV2 } from '../../app/types';
import { tableActions } from '../../slices/tableSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent, PropsWithChildren, ReactElement, useState } from 'react';

import './shark-game-results-component.scss';
import { classNames } from '../../utils/mixed';
import { contractSuits, suits } from '../shark-board-stats-component/shark-board-stats-component';
import { convertIntToBid, suitArray } from '../../app-interfaces/shark-game-engine-helper';
import { convertIntToCall } from '../../app-interfaces/game-engine-helper';
import SharkBoardReviewComponent from '../shark-board-review-component/shark-board-review-component';
import moment from 'moment';

const SharkGameResults: FunctionComponent<
    PropsWithChildren<{
        test?: string;
    }>
> = (props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { children } = props;
    const { table_setForeignBoardReviewData } = tableActions;
    const { output_loadBoardReview } = outputActions;
    const game = useAppSelector((state) => state.table);
    const { sharkGameResultsV2: sharkGameResults } = game;
    const { pid, urlParams, sharkLayout } = useAppSelector((state) => state.app);

    const [showScores, setShowScores] = useState<boolean>(true);
    const [showPair, setShowPair] = useState<SharkGameResultsPairV2 | undefined>(undefined);
    const [showBoard, setShowBoard] = useState<SharkGameResultsTravelerV2['bn'] | undefined>(undefined);
    const [showBoardReview, setShowBoardReview] = useState<OutputState['loadBoardReview'] | undefined>(undefined);
    const [boardSelectOpened, setBoardSelectOpened] = useState(false);
    const [comparisonSelectOpened, setComparisonSelectOpened] = useState(false);
    // for going back
    const [lastPair, setLastPair] = useState<SharkGameResultsPairV2 | undefined>(undefined);

    const d = [
        t('boardStats.bridgePosition.n'),
        t('boardStats.bridgePosition.e'),
        t('boardStats.bridgePosition.s'),
        t('boardStats.bridgePosition.w'),
    ];

    const { Pairs, Scoring } = sharkGameResults ?? {};
    const travelerKeys: (keyof SharkGameResultsTravelerV2)[] = [
        'dir',
        'name',
        'c',
        'd',
        'l',
        'r',
        's',
        `re${Scoring ?? 'MP'}`,
        `ru${Scoring ?? 'MP'}`,
    ];
    const isAcblGame = sharkGameResults?.HostName?.toLowerCase() === 'acbl';

    const handleShowPair = (pairId: SharkGameResultsPairV2['uuid']) => () => {
        if (!sharkGameResults) {
            return;
        }

        const { Pairs } = sharkGameResults;
        setShowScores(false);
        setShowBoard(undefined);

        const selectedPair = Pairs !== undefined ? Pairs.find((pair) => pair.uuid === pairId) : undefined;

        if (selectedPair) {
            setShowPair(selectedPair);
        }
    };

    const handleShowBoard = (bn: SharkGameResultsTravelerV2['bn']) => (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setLastPair(showPair);
        setShowBoard(bn);
    };

    const handleShowBoardReview =
        (bn: SharkGameResultsTravelerV2['bn'], p_uuid: string) => (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            if (p_uuid) {
                const boardReviewData: OutputState['loadBoardReview'] = {
                    bn,
                    pair: p_uuid,
                    game: game.sharkGameResultsV2
                        ? game.sharkGameResultsV2.Game
                        : game.sharkGameResults
                        ? game.sharkGameResults.Game
                        : '',
                };
                setShowBoardReview(boardReviewData);
                dispatch(output_loadBoardReview(boardReviewData));
            }
        };

    const handleChangeBoardReview = (partial: Partial<OutputState['loadBoardReview']>) => {
        const boardReviewData = showBoardReview ? { ...showBoardReview, ...partial } : showBoardReview;
        setShowBoardReview(boardReviewData);
        setBoardSelectOpened(false);
        setComparisonSelectOpened(false);
        dispatch(output_loadBoardReview(boardReviewData));
    };

    const handleHideBoardReview = () => {
        setShowBoardReview(undefined);
        table_setForeignBoardReviewData(undefined);
    };

    const goBackToScores = () => {
        setShowScores(true);
        setShowBoard(undefined);
        setShowPair(undefined);
        setLastPair(undefined);
    };

    const goBackToPair = () => {
        setShowScores(false);
        setShowBoard(undefined);
        setShowPair(lastPair);
        setLastPair(undefined);
    };

    const formatValues = (
        key: keyof SharkGameResultsPairV2 | keyof SharkGameResultsTravelerV2,
        value: any,
    ): any | string => {
        //(key);
        switch (key) {
            case 'd':
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                return value >= 0 ? d[value] : '-';
            case 'ruMP': {
                return (value as number).toFixed(2) + '%';
            }
            case 'reMP': {
                return (value as number).toFixed(2) + '%';
            }
            case 'ruIMP':
                return (value as number).toFixed(0);
            case 'reIMP':
                return (value as number).toFixed(0);
            case 'r': {
                return value === 0 ? '=' : value > 0 ? `+${value}` : value;
            }
            case 'masterPoints': {
                const number = Number(value);
                return isFinite(number) ? number.toFixed(2) : '-';
            }
            default:
                return value;
        }
    };

    const getSuit = (suit: string) => {
        const suits: Record<string, string> = {
            s: 'spades',
            d: 'diamonds',
            c: 'clubs',
            h: 'hearts',
        };

        if (suits[suit]) {
            return <span className={`suit ${suits[suit]}`} />;
        }
        return suit;
    };

    const renderTraveler = (
        key: keyof SharkGameResultsTravelerV2,
        traveler: SharkGameResultsTravelerV2,
    ): ReactElement | any => {
        switch (key) {
            case 'name':
                return (
                    <span className="link" onClick={handleShowPair(traveler.uuid)}>
                        {traveler.name}
                    </span>
                );
            case 'c':
                return (
                    <div
                        className={classNames(
                            'contract suit',
                            traveler.c >= 10 ? contractSuits[`${traveler.c}`.substring(1, 2)] : 'passOut',
                        )}
                    >
                        {`${traveler.c}`.substring(1, 2) === '4' && (
                            <div className="notrump">{contractSuits[`${traveler.c}`.substring(1, 2)]}</div>
                        )}
                        <div className="level">{traveler.c > 0 ? `${traveler.c}`.substring(0, 1) : 'Pass out'}</div>
                        <div className="x">{' XX'.substring(0, traveler.x)}</div>
                    </div>
                );
            case 'l':
                return (
                    <div
                        className={classNames(
                            'suit',
                            traveler.l >= 0 ? suits[suitArray[traveler.l].substring(0, 1) as keyof typeof suits] : '',
                        )}
                    >
                        <span className="rank">{traveler.l >= 0 ? suitArray[traveler.l].substring(1) : '-'}</span>
                    </div>
                );
            default:
                return formatValues(key, traveler[key]);
        }
    };

    const sortByScoring = (a: SharkGameResultsPairV2, b: SharkGameResultsPairV2) => {
        if (!sharkGameResults) {
            return 0;
        }
        const { Scoring, ShowRanks } = sharkGameResults;
        if (!ShowRanks) {
            if (a.me) {
                return -1;
            }
            if (b.me) {
                return 1;
            }
            const isMe =
                pid &&
                (a.uuid === pid ||
                    (a.p1?.pid && a.p1?.pid === pid) ||
                    (a.p1?.PK && a.p1?.PK === pid) ||
                    (a.p2?.pid && a.p2?.pid === pid) ||
                    (a.p2?.PK && a.p2?.PK === pid));
            if (isMe) {
                return -1;
            }

            const isBMe =
                pid &&
                (b.uuid === pid ||
                    (b.p1?.pid && b.p1?.pid === pid) ||
                    (b.p1?.PK && b.p1?.PK === pid) ||
                    (b.p2?.pid && b.p2?.pid === pid) ||
                    (b.p2?.PK && b.p2?.PK === pid));
            if (isBMe) {
                return 1;
            }
        }
        if (Scoring === 'IMP') {
            return b.ruIMP - a.ruIMP;
        }
        return b.ruMP - a.ruMP;
    };

    const sortByResultScoring = (a: SharkGameResultsTravelerV2, b: SharkGameResultsTravelerV2) => {
        if (!sharkGameResults) {
            return 0;
        }
        const { Scoring, ShowRanks } = sharkGameResults;
        if (!ShowRanks) {
            if (a.me) {
                return -1;
            }
            if (b.me) {
                return 1;
            }
        }
        if (Scoring === 'IMP') {
            return b.reIMP - a.reIMP;
        }
        return b.reMP - a.reMP;
    };

    const renderGameResultsScores = () => {
        if (!sharkGameResults) {
            return null;
        }

        const { Pairs, Scoring, Movement, ShowRanks } = sharkGameResults;
        const pairKeys: (keyof SharkGameResultsPairV2)[] = [`ruMPP`, `ru${Scoring}`, 'masterPoints'];
        const pairs: SharkGameResultsPairV2[][] =
            Movement === 'Mitchell'
                ? [Pairs.filter((pair) => pair.dir === 'ns'), Pairs.filter((pair) => pair.dir === 'ew')]
                : [Pairs];
        const { Rounds, BoardsPerRound } = sharkGameResults;
        const boardspergame: number =
            sharkGameResults.BoardsPerRound && sharkGameResults.Rounds
                ? sharkGameResults.BoardsPerRound * sharkGameResults.Rounds
                : sharkGameResults.BoardsPerRound
                ? sharkGameResults.BoardsPerRound
                : 0;
        return pairs.map((_pairs, index) => (
            <div key={`_pairs-${index}`} className="sharkGameResultsScores">
                {Movement === 'Mitchell' && <h2>{_pairs[0]?.dir}</h2>}
                <table>
                    <thead>
                        <tr>
                            <th key={'sharkGameResults.scores.thead.rank'} className={ShowRanks ? '' : 'hide'}>
                                {t(`sharkGameResults.scores.thead.rank`)}
                            </th>
                            <th key={'sharkGameResults.scores.thead.pair'}>
                                {t(`sharkGameResults.scores.thead.pair`)}
                            </th>
                            <th key={'sharkGameResults.scores.thead.ruMP'} colSpan={2}>
                                {t(`sharkGameResults.scores.thead.ruMP`)}
                            </th>
                            {pairKeys.map((key, thIndex) => {
                                if (isAcblGame && key === 'masterPoints') {
                                    return <th key={`th-${thIndex}`}>{t(`sharkGameResults.scores.thead.${key}`)}</th>;
                                }
                            })}
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {[..._pairs].sort(sortByScoring).map((pair: SharkGameResultsPairV2, trIndex) => {
                            const isMe =
                                pair.me ||
                                (pid &&
                                    ((pair.p1?.pid && pair.p1?.pid === pid) ||
                                        (pair.p1?.PK && pair.p1?.PK === pid) ||
                                        (pair.p2?.pid && pair.p2?.pid === pid) ||
                                        (pair.p2?.PK && pair.p2?.PK === pid)));
                            return (
                                <tr
                                    key={`tr-${trIndex}`}
                                    className={isMe ? 'isMe' : 'isNotMe'}
                                    onClick={handleShowPair(pair.uuid)}
                                >
                                    <td className={ShowRanks ? '' : 'hide'}>
                                        {Scoring === 'IMP' ? pair.rIMP : pair.rMP}
                                    </td>
                                    <td className="pair">
                                        {boardspergame > pair.traveler.length ? '* ' : ''}
                                        {pair.play_name ?? pair.name}
                                    </td>
                                    {pairKeys.map((key, tdIndex) => {
                                        if (isAcblGame || key !== 'masterPoints') {
                                            return (
                                                <td key={`tr-${trIndex}-td-${tdIndex}`} className={key}>
                                                    {formatValues(key, pair[key])}
                                                </td>
                                            );
                                        }
                                    })}
                                    <td className="action">
                                        <button onClick={handleShowPair(pair.uuid)}>
                                            {t('sharkGameResults.scores.showPair')}
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        ));
    };

    const renderPairThead = (travelerKeys: (keyof SharkGameResultsTravelerV2)[], table: 'pair' | 'board') => {
        return (
            <thead>
                <tr>
                    {travelerKeys.map((key, thIndex) => {
                        switch (key) {
                            case 'reMP':
                                return (
                                    <th key={`th-${thIndex}-1`} colSpan={1}>
                                        {t(`sharkGameResults.pair.thead.result`)}
                                    </th>
                                );
                            case 'ruMP':
                                return (
                                    <th key={`th-${thIndex}-1`} colSpan={1}>
                                        {t(`sharkGameResults.pair.thead.running`)}
                                    </th>
                                );
                            case 'c':
                                return (
                                    <th key={`th-${thIndex}-1`} colSpan={4}>
                                        {t(`sharkGameResults.pair.thead.summary`)}
                                    </th>
                                );
                            default:
                                if (!['d', 'l', 'r', 'reIMP', 'ruIMP'].includes(key)) {
                                    return <th key={`th-${thIndex}-1`} />;
                                }
                                return null;
                        }
                    })}
                </tr>
                <tr>
                    {travelerKeys.map((key, thIndex) => (
                        <th key={`th-${thIndex}-2`}>{t(`sharkGameResults.${table}.thead.${key}`)}</th>
                    ))}
                </tr>
            </thead>
        );
    };

    const RenderGameResultsPair = () => {
        if (!showPair) {
            return null;
        }

        const travelerKeys: (keyof SharkGameResultsTravelerV2)[] = [
            'bn',
            'dir',
            'c',
            'd',
            'l',
            'r',
            's',
            `re${Scoring ?? 'MP'}`,
            `ru${Scoring ?? 'MP'}`,
        ];

        return (
            <>
                <div className="header">
                    <button className="back" onClick={showBoardReview ? handleHideBoardReview : goBackToScores} />
                    <div className="info">
                        {<RenderPlayerName playerName={showPair.name} />}
                        {showBoardReview && <RenderSelectBoard bn={showBoardReview.bn} />}
                        {showBoardReview && <RenderComparisonSelect />}
                    </div>
                </div>
                <table className={classNames('sharkGameResultsPair v2', showBoardReview && 'hidden')}>
                    {renderPairThead(travelerKeys, 'pair')}
                    <tbody>
                        {[...(showPair.traveler || [])]
                            .sort((a, b) => {
                                return a.bn - b.bn;
                            })
                            .map((traveler, trIndex) => (
                                <tr
                                    key={`tr-${trIndex}`}
                                    className={'isNotMe'}
                                    onClick={handleShowBoardReview(traveler.bn, showPair?.uuid)}
                                >
                                    {travelerKeys.map((key, tdIndex) => (
                                        <td key={`tr-${trIndex}-td-${tdIndex}`} className={key}>
                                            {renderTraveler(key, { ...traveler })}
                                        </td>
                                    ))}
                                    <td className="action">
                                        <button onClick={handleShowBoard(traveler.bn)}>
                                            {t('sharkGameResults.pair.showBoard')} {traveler.bn}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </>
        );
    };

    const RenderGameResultsBoard = () => {
        if (!sharkGameResults) {
            return null;
        }
        return (
            <>
                <div className="header ">
                    <button className="back" onClick={showBoardReview ? handleHideBoardReview : goBackToPair} />
                    <h2>
                        {showBoard && showBoardReview && <RenderSelectBoard bn={showBoardReview.bn ?? showBoard} />}
                        {showBoardReview && Pairs
                            ? getTravellerByUuid(showBoardReview.pair, Pairs)?.name ?? '-'
                            : t(`sharkGameResults.board.headline`, { boardNumber: showBoard })}
                        {showBoardReview && <RenderComparisonSelect />}
                    </h2>
                </div>
                {showBoard && <RenderGameResultsBoardTable boardNumber={showBoard} />}
            </>
        );
    };

    const RenderGameResultsBoardTable = ({
        boardNumber,
        selectionData,
    }: {
        boardNumber: SharkGameResultsTravelerV2['bn'];
        selectionData?: {
            uuid?: SharkGameResultsTravelerV2['uuid'] | undefined;
        };
    }) => {
        const travelers: SharkGameResultsTravelerV2[] = (Pairs ?? []).reduce(
            (prev: SharkGameResultsTravelerV2[], next) => {
                const { traveler, name, uuid, me, p1, p2 } = next;
                return [
                    ...prev,
                    ...traveler
                        .filter((travelerData) => travelerData.bn === boardNumber)
                        .map((travelerData) => ({
                            ...travelerData,
                            name,
                            uuid,
                            me:
                                travelerData.me ?? pid
                                    ? uuid === pid
                                        ? true
                                        : p1?.pid && p1?.pid === pid
                                        ? true
                                        : p1?.PK && p1?.PK === pid
                                        ? true
                                        : p2?.pid && p2?.pid === pid
                                        ? true
                                        : p2?.PK && p2?.PK === pid
                                        ? true
                                        : false
                                    : false,
                        })),
                ];
            },
            [],
        );

        return (
            <div>
                {['ns'].map((dir) => (
                    <table key={dir} className={classNames('sharkGameResultsBoards v2', showBoardReview && 'hidden')}>
                        {renderPairThead(travelerKeys, 'board')}
                        <tbody>
                            {[...travelers]
                                .filter((traveler) => traveler.dir === dir)
                                .sort(sortByResultScoring)
                                .map((traveler, trIndex) => {
                                    return (
                                        <tr
                                            key={`${dir}-tr-${trIndex}`}
                                            className={classNames(
                                                selectionData?.uuid === traveler.uuid && `selected`,
                                                traveler.me ? 'isMe' : 'isNotMe',
                                            )}
                                            onClick={
                                                selectionData
                                                    ? comparisonSelectOpened
                                                        ? () =>
                                                              handleChangeBoardReview({ comparisonUuid: traveler.uuid })
                                                        : () => setComparisonSelectOpened(true)
                                                    : handleShowBoardReview(traveler.bn, traveler.uuid)
                                            }
                                        >
                                            {travelerKeys.map((key, tdIndex) => (
                                                <td key={`${dir}-tr-${trIndex}-td-${tdIndex}`} className={key}>
                                                    {renderTraveler(key, traveler)}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                ))}
            </div>
        );
    };

    const RenderSelectBoard = ({ bn }: { bn: SharkGameResultsTravelerV2['bn'] }) => {
        return (
            <div className={classNames('select-board', boardSelectOpened && 'opened')}>
                {[...(showPair?.traveler || [])]
                    .sort((a, b) => {
                        return a.bn - b.bn;
                    })
                    .map((traveler) =>
                        traveler.bn === bn ? (
                            <div
                                key={`${traveler.name}-tr-${traveler.bn}`}
                                className="current-board"
                                onClick={() => setBoardSelectOpened(!boardSelectOpened)}
                            >
                                Board {traveler.bn}
                            </div>
                        ) : (
                            <div
                                key={`${traveler.name}-tr-${traveler.bn}`}
                                onClick={() => handleChangeBoardReview({ bn: traveler.bn })}
                            >
                                Board {traveler.bn}
                            </div>
                        ),
                    )}
            </div>
        );
    };

    const RenderPlayerName = ({ playerName }: { playerName: string }) => {
        return <div className="current-player">{playerName}</div>;
    };

    const RenderComparisonSelect = () => {
        return showBoardReview?.bn ? (
            <div className={classNames('select-comparison', comparisonSelectOpened && 'opened')}>
                <RenderGameResultsBoardTable
                    boardNumber={showBoardReview.bn}
                    selectionData={{
                        // uuid: showBoardReview?.comparisonUuid ?? Pairs?.[0]?.uuid
                        uuid:
                            showBoardReview?.comparisonUuid ??
                            (showPair?.uuid === Pairs?.[0]?.uuid ? Pairs?.[1]?.uuid : Pairs?.[0]?.uuid),
                    }}
                />
            </div>
        ) : null;
    };

    return (
        <section className="SharkGameResults">
            {sharkGameResults && (
                <h1>
                    <span>
                        {sharkGameResults.EventName} -{' '}
                        {moment
                            .utc(moment.unix(sharkGameResults.OriginalStartUnixTime))
                            .format(t('sharkGameResults.dateFormat'))}{' '}
                        / {sharkGameResults.HostName}
                    </span>
                    {isAcblGame && <span className="acblGameWarning">{t('sharkGameResults.acblGameWarning')}</span>}
                    <span className="updatedOn">
                        {t('sharkGameResults.updatedOn')}:{' '}
                        {moment
                            .utc(moment.unix(sharkGameResults.TimeStamp))
                            .local()
                            .format(t('sharkGameResults.dateTimeFormat'))}
                    </span>
                </h1>
            )}
            {showScores && renderGameResultsScores()}
            {!!showPair && !showBoard && <RenderGameResultsPair />}
            {!!showBoard && <RenderGameResultsBoard />}
            {showBoardReview && (
                <div className="showBoardReview">
                    <SharkBoardReviewComponent isStandAlone />
                </div>
            )}
            {children}
        </section>
    );
};

const getTravellerByUuid = (
    uuid: SharkGameResultsPairV2['uuid'],
    pairs: SharkGameResultsPairV2[],
): SharkGameResultsPairV2 | undefined => {
    return pairs.find((pair) => pair.uuid === uuid);
};

export default SharkGameResults;
