import './bidding-ladder-component.scss';
import { Bid, BidComponentProps, BiddingLadder, Call } from '../../app/types';
import { allBonusIndicators } from '../../app/defaults';
import { classNames } from '../../utils/mixed';
import { outputActions } from '../../slices/outputSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';

export const BiddingLadderComponent: React.FC = () => {
    const dispatch = useAppDispatch();
    const { biddingLadder } = useAppSelector((state) => state.table);
    const { output_setBid } = outputActions;
    const bidsEl = useRef<HTMLDivElement>(null);
    const [initialScroll, setInitialScroll] = useState(true);

    const {
        bids,
        bonusIndicators,
        firstVisibleCall,
        isActive,
        minimalValidCall,
        showArrow,
        showBonusIndicator,
        stake,
        pass,
    } = biddingLadder ?? {};

    const handleBidClick = useCallback((bid: Bid) => {
        dispatch(output_setBid(bid));
    }, []);

    useEffect(() => {
        if (bidsEl.current && firstVisibleCall) {
            const el = document.getElementById(firstVisibleCall);
            if (el?.offsetTop && bidsEl.current) {
                bidsEl.current.scrollTo({
                    top: el.offsetTop - bidsEl.current.offsetHeight + el.offsetHeight,
                    left: 0,
                    behavior: initialScroll ? 'auto' : 'smooth',
                });
                setInitialScroll(false);
            }
        }
    }, [bidsEl.current, firstVisibleCall]);

    const stakes: BidComponentProps[] = [
        {
            bid: {
                call: stake.call,
                backgroundColor: stake.backgroundColor,
            },
            isDisabled: Boolean(stake.disabled),
            isActive,
            onClick: handleBidClick,
        },
        {
            bid: {
                call: 'pass',
                backgroundColor: pass.backgroundColor,
            },
            isDisabled: Boolean(pass.disabled),
            isActive,
            onClick: handleBidClick,
        },
    ];

    return (
        <section
            className={classNames(
                'BiddingLadderComponent',
                isActive && 'is-active',
                showBonusIndicator && 'show-bonus-indicator',
                showArrow && 'show-arrow',
            )}
        >
            <div className="bids" ref={bidsEl}>
                {(bids ?? []).map((bid, index) => (
                    <React.Fragment key={bid.call}>
                        <BidComponent
                            bid={bid}
                            isActive={isActive}
                            isDisabled={bids.findIndex((bid) => bid.call === minimalValidCall) < index}
                            onClick={handleBidClick}
                        />
                        {showBonusIndicator && bid.call && (
                            <BonusIndicatorComponent bonusIndicators={bonusIndicators} call={bid.call} />
                        )}
                    </React.Fragment>
                ))}
            </div>
            <div className="stakes">
                {stakes.map((props, index) => (
                    <React.Fragment key={`stake-${index}`}>
                        <BidComponent {...props} />
                        {showBonusIndicator && <div className="BonusIndicator" />}
                    </React.Fragment>
                ))}
                {showArrow && <div className="arrow" style={{ backgroundImage: 'url("/images/up_arrow.png")' }} />}
            </div>
        </section>
    );
};

const BidComponent: React.FC<BidComponentProps> = ({ bid, isActive, isDisabled, onClick }) => {
    const props = {
        id: bid.call,
        className: classNames('bid', isActive && 'is-active', isDisabled && 'is-disabled'),
        style: { backgroundImage: `url("/calls/${bid.call}.png")`, backgroundColor: bid.backgroundColor },
        ...(isActive && !isDisabled
            ? {
                  onClick: () => {
                      onClick(bid);
                  },
              }
            : {}),
    };
    return <div {...props} />;
};

const BonusIndicatorComponent: React.FC<{ bonusIndicators: BiddingLadder['bonusIndicators']; call: Call }> = ({
    bonusIndicators,
    call,
}) => {
    const highlighted = (bonusIndicators ?? []).find((bonusIndicator) =>
        allBonusIndicators[bonusIndicator].includes(call),
    );

    return <div className={classNames('BonusIndicator', highlighted && 'highlighted')} />;
};
