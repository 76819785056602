import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Modal } from '../app/types';
import { TableState } from '../slices/tableSlice';
import { Translate } from '../_basics/translate';
import React from 'react';
import SharkGameResultsComponentV2 from '../features/shark-game-results-component/shark-game-results-component-v2';

export function modalMakeClaim(numberOfTricks: number, makeClaim: ActionCreatorWithPayload<number>): Modal {
    const claims = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
    claims.splice(claims.length - numberOfTricks);
    const modalId = 'modalMakeClaim';
    return {
        id: modalId,
        noClickOutside: true,
        header: <Translate contentKey="modal.claim.header" />,
        body: [
            <p key="makeClaimModal-body">
                <Translate contentKey="modal.claim.body" />
            </p>,
            <div key="makeClaimModal-claims" className="claims">
                {claims.map((claim) => (
                    <button
                        key={`claim-${claim}`}
                        type="button"
                        onClick={() => {
                            makeClaim(claim);
                            // props.removeModal('makeClaimModal');
                            // props.addModal({
                            //   id: 'awaitClaimApproval',
                            //   header: t('modal.claim.awaitApproval.header', { claim }),
                            //   body: [<p>{t('modal.claim.awaitApproval.body')}</p>, <Loading />]
                            // });
                        }}
                    >
                        {claim}
                    </button>
                ))}
            </div>,
        ],
        className: 'makeClaimModal',
    };
}

export function modalCencede(numberOfTricks: number, makeClaim: ActionCreatorWithPayload<number>): Modal {
    const tricks = 13-numberOfTricks;
    const modalId = 'modalMakeClaim';
    return {
        id: modalId,
        noClickOutside: true,
        header: <Translate contentKey="modal.concede.header" interpolate = {{tricks: `${tricks}`}}/>,
        body: [
            <p key="makeConcedeModal-body">
                <Translate contentKey="modal.concede.body" />
            </p>
        ],
        cancelButtonLabel: <Translate contentKey="modal.no" />,
        buttons: [
            {
                label: <Translate contentKey="modal.yes" />,
                primary: true,
                onClick: () => {
                    makeClaim(0);
                },
            },
        ],
        className: 'makeConcedeModal',
    };
}

export function modalGameResults({
                                     gameResults,
                                     fullSize = false,
                                     noClickOutside = false,
                                     noCancel = false,
                                     onCancel = () => {
                                         //
                                     },
                                 }: {
    gameResults: TableState['sharkGameResultsV2'];
} & Pick<Modal, 'fullSize' | 'noClickOutside' | 'noCancel' | 'onCancel'>): Modal | undefined {
    if (!gameResults) {
        return;
    }
    return {
        id: 'modalGameResults',
        cancelButtonLabel: <Translate contentKey="modal.close" />,
        noClickOutside,
        noCancel,
        onCancel,
        fullSize,
        // header: (
        //     <span>
        //         <span className="base">
        //             {gameResults.EventName ? gameResults.EventName : <Translate contentKey="gameResults.header" />}
        //         </span>
        //         <span className="base">{gameResults.HostName ? '/ ' + gameResults.HostName + ' /' : ''}</span>
        //         <span className="timeStamp">
        //             {gameResults?.Movement} - {gameResults?.Scoring} &nbsp;&nbsp;&nbsp;
        //         </span>
        //         <span className="timeStamp">
        //             <Translate contentKey={'gameResults.updatedOn'} />:{' '}
        //             {moment
        //                 .utc(moment.unix(gameResults.TimeStamp - 120))
        //                 .local()
        //                 .format('dddd, MMMM Do YYYY HH:mm (UTC Z)')}
        //         </span>
        //     </span>
        // ),
        body: [<SharkGameResultsComponentV2 key="SharkGameResultsComponentV2" />],
    };
}

export function modalGeneric(
    message: string,
    title: string,
    closeAction: ActionCreatorWithPayload<string | undefined>,
): Modal {
    const modalId = 'modalGeneric';
    return {
        id: modalId,
        noClickOutside: true,
        header: <Translate contentKey="modal.claim.header" />,
        body: [
            <p key="genericModal-title" dangerouslySetInnerHTML={{ __html: title }} />,
            <p key="genericModal-body" dangerouslySetInnerHTML={{ __html: message }} />,
        ],
        className: 'genericModal',
    };
}
