import { createSlice } from '@reduxjs/toolkit';

export type StarterState = {
    someData: any[];
};

export const initialState: StarterState = {
    someData: [],
};

export const starterSlice = createSlice({
    name: 'starter',
    initialState,
    reducers: {
        // resets
        starter_reset: () => initialState,
    },
});

export const starterActions = starterSlice.actions;

export default starterSlice.reducer;
