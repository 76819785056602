import { BridgePosition, SeatPosition } from '../../app/types';
import { GameEngine, SeatPosFor, convertIntToBridgePosition } from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';
import { SFSVAR } from '../sfsVar';
import { bridgePos } from '../../app/defaults';
import { claimButton, concedeButton, undoButton } from '../buttonsState';
import { sfsService } from '../sfs-service';

export function MID_TIME_TO_PLAY(cmdObj: SFSObject, sender: GameEngine) {
    handleTrickClosure(cmdObj, sender);
    const currentPlayerId: number = cmdObj.getInt(SFSVAR.SFSGAME_CURENT_PLAYER);
    const currentPlayer: BridgePosition = convertIntToBridgePosition(currentPlayerId);
    const bridgePosition = bridgePos(currentPlayerId);

    updateEngineInterfaceState(sender, bridgePosition);
    resetSeatData(sender);
    setSeatDataForCurrentPlayer(sender, bridgePosition);

    sender.setCurrentPlayer(currentPlayer);

    if (sender.shouldShowClaim()) {
        sender.showButtons([claimButton]);
    }
    else if (sender.shouldShowConcede()){
        sender.showButtons([concedeButton]);
    }
}

function handleTrickClosure(cmdObj: SFSObject, sender: GameEngine) {
    if (!cmdObj.containsKey('closetrick') || !cmdObj.getBool('closetrick')) {
        sender.props.table_shouldCloseTrick(false);
    }
}

function updateEngineInterfaceState(sender: GameEngine, currentPlayer: BridgePosition) {
    sender.props.app_updateEngineInterfaceState({
        currentPlayer: currentPlayer,
    });
}

function resetSeatData(sender: GameEngine) {
    Object.values(SeatPosition).forEach((seatPosition) => {
        sender.props.table_setSeatData({
            seatPosition,
            background: undefined,
        });
    });
}

function setSeatDataForCurrentPlayer(sender: GameEngine, currentPlayer: BridgePosition) {
    sender.props.table_setSeatData({
        seatPosition: SeatPosFor(currentPlayer, sender.props.table),
        isInteractive: true,
        isVisible: true,
        background: '#fce303',
    });
}

function showButtonsIfNeeded(sender: GameEngine) {
    if (sender.shouldShowClaim()) {
        sender.showButtons([claimButton]);
    }
    if (sender.shouldShowUndo()) {
        sender.showButtons([undoButton]);
    }
}
