import './auction-component.scss';
import { Bid } from '../../app/types';
import { classNames } from '../../utils/mixed';
import { outputActions } from '../../slices/outputSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import React from 'react';

type AuctionComponentProps = {
    bid: Bid;
};

export const AuctionComponent: React.FC<AuctionComponentProps> = ({ bid }) => {
    const { walkThrough } = useAppSelector((state) => state.table);
    const dispatch = useAppDispatch();
    const { output_setBidExp } = outputActions;
    return (
        <section
            className={classNames('AuctionComponent', walkThrough && 'walkThrough')}
            style={{ backgroundColor: bid.backgroundColor }}
            onClick= {() => dispatch(output_setBidExp(bid))}
        >
            <img src={`/calls/${bid.call.toLowerCase()}.png`} alt={`${bid.call.toLowerCase()}`} />
        </section>
    );
};
