import {
    BridgePosition,
    Call,
    Card,
    CardId,
    CardPosition,
    Cards,
    ForeignBoardReviewData,
    ForeignBoardReviewPlayerData,
    GamePhase,
    SeatPosition,
    Trick,
    levels,
} from '../app/types';
import { CardDeck } from '../utils/shark-initialHelper';
import {
    convertCalltoStrain,
    convertIntToBridgePosition,
    convertIntToCall,
    intToVuln,
    strToCardId,
} from './game-engine-helper';
import { convertStringToBid, convertSuitRank } from './shark-game-engine-helper';
import { defaultCards, defaultSeatData } from '../app/defaults';

interface S3Player {
    pn: string;
}

interface S3TrickCard {
    p: number;
    c: CardId;
    w: boolean;
}

interface S3Board {
    auction: string;
    dealer: number;
    eh: string[];
    ewtr: number;
    nh: string[];
    nstr: number;
    play: S3TrickCard[];
    sh: string[];
    vuln: number;
    wh: string[];
    traveler: {
        c: number;
        d: number;
    };
}

type S3Boards = Record<string, S3Board>;

interface S3MyResults {
    boards: S3Boards;
    p1: S3Player;
    p2: S3Player;
    ruMP: number;
    rMP: number;
    ruIMP: number;
    rIMP: number;
    username?: string;
    uuid: string;
}

export interface S3BoardData {
    myresults: S3MyResults;
}

export const convertS3toForeignBoardReviewData = (
    s3data: S3BoardData,
    bn: number,
    index: number,
): ForeignBoardReviewData => {
    const cards: Cards = CardDeck();
    const bIndex = bn - 1;
    const s3board: S3Board = s3data.myresults.boards[bIndex];
    if (!s3board) {
        return {} as ForeignBoardReviewData;
    }

    //Assign all cards from hands
    s3board.nh.forEach((card) => {
        const c: Card = cards[strToCardId(card)];
        c.bridgePosition = BridgePosition.north;
        c.cardPosition = CardPosition.HAND;
    });
    s3board.eh.forEach((card) => {
        const c: Card = cards[strToCardId(card)];
        c.bridgePosition = BridgePosition.east;
        c.cardPosition = CardPosition.HAND;
    });
    s3board.sh.forEach((card) => {
        const c: Card = cards[strToCardId(card)];
        c.bridgePosition = BridgePosition.south;
        c.cardPosition = CardPosition.HAND;
    });
    s3board.wh.forEach((card) => {
        const c: Card = cards[strToCardId(card)];
        c.bridgePosition = BridgePosition.west;
        c.cardPosition = CardPosition.HAND;
    });

    //Add one player data
    const playerData: ForeignBoardReviewPlayerData = convertS3toForeignBoardReviewPlayerData(
        s3board,
        cards,
        s3data.myresults.uuid,
    );
    //Set the auction
    const contractCall: Call = convertIntToCall(s3board.traveler.c);
    const result: ForeignBoardReviewData = {
        cards: cards,
        seatData: {
            [SeatPosition.top]: {
                ...defaultSeatData,
                bridgePosition: BridgePosition.north,
                seatPosition: SeatPosition.top,
                isMe: false,
                isVisible: true,
            },
            [SeatPosition.right]: {
                ...defaultSeatData,
                bridgePosition: BridgePosition.east,
                seatPosition: SeatPosition.right,
                isMe: false,
                isVisible: true,
            },
            [SeatPosition.bottom]: {
                ...defaultSeatData,
                bridgePosition: BridgePosition.south,
                seatPosition: SeatPosition.bottom,
                isMe: true,
                isVisible: true,
            },
            [SeatPosition.left]: {
                ...defaultSeatData,
                bridgePosition: BridgePosition.west,
                seatPosition: SeatPosition.left,
                isMe: false,
                isVisible: true,
            },
        },
        contract: {
            call: contractCall,
            level: levels[contractCall[0] as unknown as number],
            strain: convertCalltoStrain(contractCall),
            stake: 1,
        },
        gamePhase: GamePhase.END,
        vulnerable: intToVuln(s3board.vuln),
        showAuctionBox: true,
        boardStats: [],
        myBoardStatKeys: {
            r: 0,
            s: 0,
            c: 0,
            d: 0,
            x: 0,
            l: 0,
        },
        dealer: convertIntToBridgePosition(s3board.dealer),
        declarer: convertIntToBridgePosition(s3board.traveler.d),
        //    winners: [],
        playerData: [playerData],
    };
    return result;
};

export const convertS3toForeignBoardReviewPlayerData = (
    s3board: S3Board,
    cards: Cards,
    uuid: string,
): ForeignBoardReviewPlayerData => {
    //Add one player data
    const playerData: ForeignBoardReviewPlayerData = {
        auction: [],
        tricks: [],
        winners: [],
        uuid: uuid,
        declarer: undefined,
    } as ForeignBoardReviewPlayerData;
    //Set the auction

    const auctionArray = s3board.auction.split(' ');
    auctionArray.forEach((call) => {
        playerData.auction.push(convertStringToBid(call.toLowerCase()));
    });

    //Set the trick history
    const s3Tricks: S3TrickCard[] = s3board.play;
    let playsCount = 0;
    let trickCount = 0;
    playerData.tricks = [];
    s3Tricks.forEach((card) => {
        playsCount++;
        trickCount = Math.ceil(playsCount / 4) - 1;
        const trick: Trick = playerData.tricks[trickCount] ?? { cardIds: [], winner: undefined };
        if (trick.cardIds.length === 0) {
            playerData.tricks.push(trick);
        }
        trick.cardIds.push(strToCardId(card.c));
        if (card.w) {
            trick.winner = convertIntToBridgePosition(card.p);
            //       playerData.winners.push(convertIntToBridgePosition(card.p));
        }
    });

    playerData.declarer = convertIntToBridgePosition(s3board.traveler.d);
    return playerData;
};
