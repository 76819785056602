import { GameEngine } from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';
import { SFSVAR } from '../sfsVar';
import { sfsService } from '../sfs-service';

export function MID_WAIT_FOR_ROUND(cmdObj: SFSObject, sender: GameEngine) {
    const endTime: number = sfsService.getLobbyNumber(SFSVAR.SFSGAME_ROOM_UTC_STRTIME, undefined);
    const currentTime: number = Math.floor(Date.now() / 1000);

    sender.props.table_updateTable({
        timeToGameStart: undefined,
        timeToGameEnd: endTime ? endTime - currentTime : undefined,
    });

    const startTime: number = sfsService.getLobbyNumber(SFSVAR.SFSGAME_ROOM_STT, undefined);
    if (startTime) {
        sender.props.table_updateTable({
            timeToGameStart: startTime
        });
    }
}
