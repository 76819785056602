import { GameEngine } from '../game-engine-helper';
import { Modal } from '../../app/types';
import { SFSObject } from 'sfs2x-api';
import { Translate } from '../../_basics/translate';
import { insertSuits } from '../../utils/shark-helper';
import { modalGameResults } from '../../utils/modal.helper';
import React from 'react';

export function MID_END_OF_SET(cmdObj: SFSObject, sender: GameEngine) {
    //North visible if handVisibility & 2 != 0, East & 4, South & 8, West &16
    sender.props.app_clearModals();
    const message = cmdObj.getUtfString('message') ?? '';
    if (cmdObj.containsKey('message') || sender.props.app.engineState.interface.farewellMessage !== undefined) {
        const modalview: Modal = {
            id: 'endofSet',
            noClickOutside: true,
            noHeaderClose: true,
            header: sender.props.table.sharkMetaData?.eventName ?? '',
            body: [
                <div key="MID_END_OF_SET-claims">
                    <p dangerouslySetInnerHTML={{ __html: cmdObj.getUtfString('message') }} />
                    <p
                        dangerouslySetInnerHTML={{
                            __html: sender.props.app.engineState.interface.farewellMessage ?? '',
                        }}
                    />
                    <p dangerouslySetInnerHTML={{ __html: insertSuits(cmdObj.getUtfString('oe')) }} />
                </div>,
            ],
            cancelButtonLabel: <Translate contentKey="modal.ok" />,
            onCancel: () => {
                const _modalGameResults = modalGameResults({
                    gameResults: sender.props.table.sharkGameResultsV2,
                    noCancel: false,
                    noClickOutside: true,
                    fullSize: true,
                });
                if (_modalGameResults) {
                    sender.props.app_addModal(_modalGameResults);
                }
            },
        };
        sender.props.app_addModal(modalview);
    }
}
