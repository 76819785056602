import { AuxiliaryMessage } from '../../slices/tableSlice';
import { GameEngine } from '../game-engine-helper';
import { Modal } from '../../app/types';
import { SFSObject } from 'sfs2x-api';
import { Translate } from '../../_basics/translate';
import { insertSuits } from '../../utils/shark-helper';
import { modalGameResults } from '../../utils/modal.helper';
import React from 'react';

export function MID_TEXT_MESSAGE(cmdObj: SFSObject, sender: GameEngine) {
    if (cmdObj.containsKey('exitshark') && cmdObj.getBool('exitshark')) {
        const _modalGameResults = modalGameResults({
            gameResults: sender.props.table.sharkGameResultsV2,
            noCancel: false,
            onCancel: () => {
                parent.postMessage('reloaddash', '*');
            },
            noClickOutside: true,
            fullSize: true,
        });

        if (cmdObj.containsKey('o')) {
            //if we have GAme Results, show the message and reload results on the background.
            sender.props.app_clearModals();
            const message = cmdObj.getUtfString('o') ?? '';
            const modalview: Modal = {
                id: 'endofSet',
                noClickOutside: true,
                noHeaderClose: true,
                header: sender.props.table.sharkMetaData?.eventName ?? '',
                body: [
                    <p
                        key="MID_TEXT_MESSAGE-2"
                        dangerouslySetInnerHTML={{
                            __html: sender.props.app.engineState.interface.farewellMessage ?? message,
                        }}
                    />,
                    <p
                        key="MID_TEXT_MESSAGE-3"
                        dangerouslySetInnerHTML={{ __html: insertSuits(cmdObj.getUtfString('oe')) }}
                    />,
                ],
                cancelButtonLabel: <Translate contentKey="modal.ok" />,
                onCancel: () => {
                    if (_modalGameResults) {
                        sender.props.app_addModal(_modalGameResults);
                    } else {
                        parent.postMessage('reloaddash', '*');
                    }
                },
            };
            sender.props.app_addModal(modalview);
            // if (cmdObj.containsKey('message') || sender.state.farewellMessage !== undefined) {
            //
            // }
        }

        // console.log("MID_TEXT_MESSAGE", cmdObj.getDump());
        // //window.location.href = `${sender.props.table.sharkMetaData?.resultUrl}&pn=${sender.sfs?.mySelf.name}`;
        // const m: AuxiliaryMessage = {
        //     text: cmdObj.getUtfString('0'),
        //     title: undefined,
        //     titleColor: undefined,
        //     buttonOk: "Go to results",
        //     buttonCancel: "Exit",
        //     buttonAuxiliary: "Board's walk thru",
        // };
        // sender.props.table_setAuxiliaryMessage({ position: 'center', message: m });
        // this.props.table_setAuxiliaryMessage({ position: position, message: m });
        //
        // if (cmdObj.containsKey('exitshark') && cmdObj.getBool('exitshark')) {
        //     const modalview: IModal = {
        //         noClickOutside: true,
        //         noHeaderClose: true,
        //         header: this.props.game.metaData.eventName,
        //         body: [
        //             <div>
        //                 <p dangerouslySetInnerHTML={{ __html: insertSuits(cmdObj.getUtfString('o')) }} />
        //     <p dangerouslySetInnerHTML={{ __html: this.state.farewellMessage ? this.state.farewellMessage : '' }} />
        //     <p dangerouslySetInnerHTML={{ __html: insertSuits(cmdObj.getUtfString('oe')) }} />
        //     </div>
        // ],
        //     cancelButtonLabel: <Translate contentKey="modal.ok" />,
        //         onCancel: () => {
        //         this.props.game.gameResults || this.props.game.gameResultsV2
        //         ? this.showModal({
        //             ...getGameResultBlockingModalConfig(
        //                 this.props.game.gameResultsV2 ? this.props.game.gameResultsV2 : this.props.game.gameResults,
        //                 !!this.props.game.gameResultsV2
        //             ),
        //             onCancel: () => this.updateBoardData(undefined)
        //         })
        //         : (window.location.href =
        //             this.props.game.metaData.resultUrl + '&pn=' + this.sfs?.mySelf.getVariable(SFSVAR.SFS_NICKNAME).value);
        //     }
        // };
        //     this.showModal(modalview);
        // } else {
        //     this.showModal(
        //         getAlert([
        //             <div>
        //                 <p dangerouslySetInnerHTML={{ __html: insertSuits(cmdObj.getUtfString('o')) }} />
        //     <p dangerouslySetInnerHTML={{ __html: insertSuits(cmdObj.getUtfString('oe')) }} />
        //     </div>
        // ])
        // );
        // }
    }
}
