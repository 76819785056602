import './App.scss';
import { AppState, GameService, SFSConnectionStatus, appActions } from './slices/appSlice';
import { FooterComponent } from './features/footer-component/footer-component';
import { LoadingComponent } from './features/loading-component/loading-component';
import { RootComponent } from './features/root-component/root-component';
import { classNames } from './utils/mixed';
import { environment } from './env';
import { useAppDispatch, useAppSelector } from './app/hooks';
import AudreyGameEngineInterface from './app-interfaces/game-engine-interface.audrey.class';
import React, { Suspense, useEffect } from 'react';
import SharkGameEngineInterface from './app-interfaces/game-engine-interface.shark.class';
function App() {
    const { urlParams, engineState } = useAppSelector((state) => state.app);
    const { app_setPID, app_setUrlParams, app_setEngineState } = appActions;
    const dispatch = useAppDispatch();

    useEffect(() => {
        const urlHasParams = window.location.href.indexOf('?') !== -1;
        const urlParamsString = urlHasParams
            ? window.location.href.substring(window.location.href.indexOf('?') + 1)
            : '';
        const searchParams = new URLSearchParams(urlParamsString);
         
        const _urlParams: Record<string, string> | undefined = [...searchParams.entries()].reduce<
            AppState['urlParams']
        >((cumulated, current) => {
            return { ...cumulated, [current[0]]: current[1] };
        }, {});
        const _service: GameService = _urlParams?.['service'] as GameService;
        dispatch(
            app_setEngineState({
                service: _service,
                sfsHost: _urlParams?.['sfshost']?.toLowerCase(),
                sfsConnectionStatus: _service ? SFSConnectionStatus.disconnected : SFSConnectionStatus.ignore,
            }),
        );
        dispatch(app_setUrlParams(_urlParams));
        dispatch(app_setPID(_urlParams?.['pn']));
    }, []);
    return (
        <Suspense fallback="loading">
            <LoadingComponent />
            {urlParams && engineState && (
                <>
                    {engineState.service === 'BetterBridgeClub' ? (
                        <AudreyGameEngineInterface />
                    ) : (
                        <SharkGameEngineInterface />
                    )}
                    <div className={classNames('App', environment)}>
                        <RootComponent />
                        {engineState.service && <FooterComponent />}
                    </div>
                </>
            )}
        </Suspense>
    );
}

export default App;
