import React, { FunctionComponent, useState } from 'react';

import './labeled-input.scss';

type LabeledInputProps = {
    label: string;
    type: 'text' | 'number' | 'email' | 'password' | 'checkbox';
    value?: string | number;
    name?: string;
    required?: boolean;
    disabled?: boolean;
    error?: string;
    checked?: boolean;
    maxLength?: number;
    className?: string;

    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const LabeledInput: FunctionComponent<LabeledInputProps> = (props) => {
    const { label, error, onChange, value: initialValue, ...inputProps } = props;
    const [value, setValue] = useState<string | number | undefined>(initialValue);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <label className={`LabeledInput ${props.type} ${error ? 'error' : ''}`}>
            {!!error && <div className="error">{error}</div>}
            <input {...inputProps} value={value} onChange={handleChange} />
            <span className="label">{label}</span>
        </label>
    );
};
