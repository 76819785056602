import './hand-component.scss';
import { Card, SeatData } from '../../app/types';
import {
    CardBackComponent,
    CardComponent,
    CardComponentProps,
    CardPlaceholderComponent,
} from '../card-component/card-component';
import { classNames, suitOrders } from '../../utils/mixed';
import { useAppSelector } from '../../app/hooks';
import React, { useState } from 'react';

type HandComponentProps = {
    cards: Card[];
} & Pick<SeatData, 'dummyCards' | 'dynamicCardColumnsWidth' | 'isInteractive' | 'isVertical' | 'isVisible'>;

export const HandComponent: React.FC<HandComponentProps> = (props) => {
    const { cards, dummyCards, dynamicCardColumnsWidth, isVertical } = props;
    const [selectedCard, setSelectedCard] = useState<Card | undefined>(undefined);
    const cssCards: React.CSSProperties = isVertical
        ? {}
        : ({ '--cards': (cards ?? []).length + dummyCards } as React.CSSProperties);
    const setSelected: CardComponentProps['setSelected'] = (card?: Card) => {
        setSelectedCard(card);
    };
    return (
        <section
            className={classNames(
                'HandComponent',
                isVertical ? 'vertical' : 'horizontal',
                dynamicCardColumnsWidth && 'dynamic-card-columns-width',
            )}
            style={cssCards}
        >
            {cards?.length || dummyCards > 0 ? (
                isVertical ? (
                    <VerticalLayout {...props} setSelected={setSelected} selectedCard={selectedCard} />
                ) : (
                    <HorizontalLayout {...props} setSelected={setSelected} selectedCard={selectedCard} />
                )
            ) : (
                <div>
                    <CardPlaceholderComponent />
                </div>
            )}
        </section>
    );
};

export const HorizontalLayout: React.FC<
    HandComponentProps &
        Pick<CardComponentProps, 'setSelected'> & {
            selectedCard?: Card;
        }
> = ({ cards, isInteractive, isVisible, dummyCards, selectedCard, setSelected }) => {
    return (
        <>
            {cards.map((card, index) => (
                <div
                    key={card.id}
                    className={classNames(
                        'normalCard',
                        `normalCard-${index}`,
                        selectedCard?.id === card.id && 'selected',
                    )}
                >
                    <CardComponent
                        card={card}
                        hand={{ isVisible, isInteractive }}
                        setSelected={setSelected}
                        selected={selectedCard?.id === card.id}
                    />
                </div>
            ))}
            {Array.from(Array(dummyCards).keys()).map((index) => (
                <div key={`dummyCard-${index}`} className={`dummyCard-${index}`}>
                    <CardBackComponent />
                </div>
            ))}
        </>
    );
};

export const VerticalLayout: React.FC<
    HandComponentProps &
        Pick<CardComponentProps, 'setSelected'> & {
            selectedCard?: Card;
        }
> = ({ cards, isInteractive, isVisible, selectedCard, setSelected }) => {
    const { suitOrder } = useAppSelector((state) => state.table);

    return (
        <>
            {suitOrders[suitOrder].map((suit) => {
                return (
                    <div key={suit} className={`suit ${suit}`}>
                        <div className="suit-inner">
                            {cards
                                .filter((card) => card.suit === suit)
                                .map((card, cardIndex) => (
                                    <div
                                        key={card.id}
                                        className={classNames(
                                            'normalCard',
                                            `normalCard-${cardIndex}`,
                                            selectedCard?.id === card.id && 'selected',
                                        )}
                                    >
                                        <CardComponent
                                            card={card}
                                            hand={{ isVisible, isInteractive }}
                                            selected={selectedCard?.id === card.id}
                                            setSelected={setSelected}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                );
            })}
        </>
    );
};
