import { AppState } from '../../slices/appSlice';
import { BidComponentProps, levels } from '../../app/types';
import { Sets } from '../card-component/sets';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent } from 'react';

import './shark-bid-component.scss';
import { convertCalltoStrain } from '../../app-interfaces/game-engine-helper';
import { getLevelStrainFromCall } from '../../utils/mixed';

export type BiddingBoxProps = {
    bid: BidComponentProps['bid'];
    selectedCardSet: AppState['sharkLayout']['selectedCardSet'];
};

export const SharkBidComponent: FunctionComponent<BiddingBoxProps> = (props) => {
    const { t } = useTranslation();
    const { bid, selectedCardSet } = props;
    const { alertMessage } = bid;

    const isStake = ['rdbl', 'xdbl', 'pass', 'auctionAlert'].indexOf(bid.call) !== -1;
    const strain = convertCalltoStrain(bid.call);
    const { level } = getLevelStrainFromCall(bid.call);

    return (
        <section
            className={`SharkBidComponent ${strain} ${levels[level]} ${bid.call} ${alertMessage ? 'withAlert' : ''}`}
        >
            {isStake ? <div>{t(`stake.${bid.call}`)}</div> : Sets[selectedCardSet].biddings}
            {/*{strain ? (*/}
            {/*    ['double', 'redouble', 'pass', 'auctionAlert', 'auctionStop'].indexOf(strain) !== -1 ? (*/}
            {/*        <div>{t(`strain.${strain}`)}</div>*/}
            {/*    ) : (*/}
            {/*        Sets[selectedCardSet].biddings*/}
            {/*    )*/}
            {/*) : (*/}
            {/*    <div />*/}
            {/*)}*/}
        </section>
    );
};
