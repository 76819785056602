import { Bid, BidComponentProps } from '../../app/types';
import { SharkBidComponent } from '../shark-bid-component/shark-bid-component';
import { outputActions } from '../../slices/outputSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import React, { FC, FunctionComponent, useState } from 'react';

import './bidding-keyboard-component.scss';

export const BiddingKeyboardComponent: FunctionComponent = () => {
    const { t } = useTranslation();
    const [selectedBid, setSelectedBid] = useState<Bid | undefined>(undefined);
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);

    const { biddingLadder } = useAppSelector((state) => state.table);
    const {
        sharkLayout: { selectedCardSet },
        sharkSettings,
    } = useAppSelector((state) => state.app);
    const { output_setBid } = outputActions;
    const dispatch = useAppDispatch();

    const makeCall = (bid: Bid) => () => {
        if ((alert || sharkSettings.interactionBid === 'confirm') && selectedBid?.call !== bid.call) {
            setSelectedBid(bid);
        } else if (sharkSettings.interactionBid === 'instant' || selectedBid?.call === bid.call) {
            dispatch(output_setBid(bid));
            setSelectedBid(undefined);
            setAlertMessage(undefined);
            setAlert(false);
        }
    };

    const handleCancel = () => {
        setSelectedBid(undefined);
        setAlertMessage(undefined);
        setAlert(false);
    };

    const globallyDisabled = !biddingLadder.isActive;
    const minimalValidCallIndex = biddingLadder.bids.findIndex((bid) => bid.call === biddingLadder.minimalValidCall);
    return (
        <section className="BiddingKeyboardComponent">
            <div className="bids">
                {biddingLadder.bids.map((bid, index) => {
                    const disabledCell = globallyDisabled || index > minimalValidCallIndex;
                    const props = {
                        key: `biddingCell-${bid.call}`,
                        className: [
                            `biddingCell`,
                            disabledCell ? 'disabled' : '',
                            selectedBid?.call === bid.call ? 'selected' : '',
                        ].join(' '),
                        ...(disabledCell ? {} : { onClick: makeCall(bid) }),
                    };
                    return (
                        <div key={bid.call} {...props}>
                            <SharkBidComponent bid={bid} selectedCardSet={selectedCardSet} />
                        </div>
                    );
                })}
            </div>
            <BiddingActions
                globallyDisabled={globallyDisabled}
                selectedBid={selectedBid}
                makeCall={makeCall}
                alert={alert}
            />
            {(alert || sharkSettings.interactionBid === 'confirm') && !!selectedBid && (
                <div className="selectedBid">
                    <SharkBidComponent bid={selectedBid} selectedCardSet={selectedCardSet} />
                    <div className="confirmCancel">
                        <button className="cancel" onClick={handleCancel} />
                        <button className="confirm" onClick={makeCall(selectedBid)} />
                    </div>
                    {alert && (
                        <div className="alertMessage">
                            <label>{t('biddingBox.alertMessage')}</label>
                            <input type="text" value={alertMessage} onChange={(e) => setAlertMessage(e.target.value)} />
                        </div>
                    )}
                </div>
            )}
        </section>
    );
};

const BiddingActions: FC<{
    globallyDisabled: boolean;
    selectedBid: Bid;
    makeCall: (bid: Bid) => () => void;
    alert: boolean;
    alertMessage?: string;
}> = ({ globallyDisabled, selectedBid, makeCall, alert, alertMessage }) => {
    const {
        sharkLayout: { selectedCardSet },
    } = useAppSelector((state) => state.app);
    const { biddingLadder } = useAppSelector((state) => state.table);
    const { stake, pass, isActive } = biddingLadder;
    const stakes: BidComponentProps[] = [
        {
            bid: {
                call: stake.call,
                backgroundColor: stake.backgroundColor,
                alertMessage,
            },
            isDisabled: Boolean(stake.disabled),
            isActive,
            onClick: makeCall,
        },
        {
            bid: {
                call: 'pass',
                backgroundColor: pass.backgroundColor,
                alertMessage,
            },
            isDisabled: Boolean(pass.disabled),
            isActive,
            onClick: makeCall,
        },
        // {
        //     bid: {
        //         call: 'auctionAlert',
        //         backgroundColor: pass.backgroundColor,
        //         alertMessage,
        //     },
        //     isDisabled: Boolean(pass.disabled),
        //     isActive,
        //     onClick: makeCall,
        // },
    ];
    return (
        <div className="biddingActions">
            {stakes.map((stake) => {
                const actionDisabled = globallyDisabled;
                const props = {
                    key: `biddingCell-${stake.bid.call}`,
                    className: [
                        `biddingCell`,
                        actionDisabled ? 'disabled' : '',
                        stake.bid.call,
                        selectedBid?.call === stake.bid.call ? 'selected' : '',
                        // stake.bid.call === 'auctionAlert' && alert ? 'highlighted' : '',
                    ].join(' '),
                    ...(actionDisabled ? {} : { onClick: makeCall(stake.bid) }),
                };
                return (
                    <div key={stake.bid.call} {...props}>
                        <SharkBidComponent bid={stake.bid} selectedCardSet={selectedCardSet} />
                    </div>
                );
            })}
        </div>
    );
};

export default BiddingKeyboardComponent;
