import { SFSRoom, SmartFox } from 'sfs2x-api';
import { SFSVAR } from './sfsVar';

class SfsService {
    private static _instance: SfsService;
    // private config = {
    //     host: 'sfs03.emabridge.com',
    //     port: 8443,
    //     useSSL: true,
    //     zone: 'BetterBridgeClub',
    // };
    private initialized = false;
    public sfs?: SmartFox;
    public lobby?: SFSRoom;
    public table?: SFSRoom;

    private constructor() {
        console.log('SfsService constructed');
        //...
    }

    public init(config: any) {
        // init() from game.-engine-interface goes here
        // plus check for already connected or connection requested
        if (this.initialized) {
            return;
        }
        this.sfs = new SmartFox(config);
        this.initialized = true;
    }

    public connect() {
        if (this.sfs?.isConnected) {
            return;
        }
        this.sfs?.connect();
    }

    public addEventListener(evtType: string, callback: (...params: any[]) => any, scope: any) {
        this.sfs?.addEventListener(evtType, callback, scope);
    }

    public ping() {
        return 'ping';
    }

    public initialize() {
        //console.log('SfsService initialized');
    }

    public static get Instance() {
        console.log('SfsService instantiated');
        // Do you need arguments? Make it a regular static method instead.
        return this._instance || (this._instance = new this());
    }

    //useful game states
    public get isMiniBridge() {
        return sfsService.getBoolean(SFSVAR.SFSGAME_MINIBRIDGE, false);
    }

    //Useful sfs wrappers
    public get isPlayerJoined(): boolean {
        return this.sfs !== undefined && this.sfs.mySelf.getPlayerId(this.sfs.lastJoinedRoom) > 0;
    }


    public hasPlayerVar(varName: string) {
        return this.sfs?.mySelf.containsVariable(varName);
    }

    public getPlayerNumber(varName: string, defValue = 0): number {
        if (this.hasPlayerVar(varName)) {
            return this.sfs?.mySelf.getVariable(varName).value as number;
        }
        return defValue;
    }

    public getPlayerBoolean(varName: string, defValue = false): boolean {
        if (this.hasPlayerVar(varName)) {
            return this.sfs?.mySelf.getVariable(varName).value as boolean;
        }
        return defValue;
    }

    public getPlayerString(varName: string, defValue: string = undefined): string | undefined {
        if (this.hasPlayerVar(varName)) {
            return this.sfs?.mySelf.getVariable(varName).value as string;
        }
        return defValue;
    }

    public hasLobbyVar(varName: string) {
        return this.lobby?.containsVariable(varName);
    }

    public getLobbyNumber(varName: string, defValue: number | undefined  ): number {
        if (this.hasLobbyVar(varName)) {
            return this.lobby?.getVariable(varName).value as number;
        }
        return defValue;
    }

    public getLobbyBoolean(varName: string, defValue = false): boolean {
        if (this.hasLobbyVar(varName)) {
            return this.lobby?.getVariable(varName).value as boolean;
        }
        return defValue;
    }

    public getLobbyString(varName: string, defValue: string = undefined): string | undefined {
        if (this.hasLobbyVar(varName)) {
            return this.lobby?.getVariable(varName).value as string;
        }
        return defValue;
    }

    public hasVar(varName: string) {
        return this.table?.containsVariable(varName);
    }

    public getNumber(varName: string, defValue = 0): number {
        if (this.hasVar(varName)) {
            return this.table?.getVariable(varName).value as number;
        }
        return defValue;
    }

    public getBoolean(varName: string, defValue = false): boolean {
        if (this.hasVar(varName)) {
            return this.table?.getVariable(varName).value as boolean;
        }
        return defValue;
    }

    public getString(varName: string, defValue: string = undefined): string | undefined {
        if (this.hasVar(varName)) {
            return this.table?.getVariable(varName).value as string;
        }
        return defValue;
    }

    public hasRoomVar(room: SFSRoom, varName: string) {
        return room.containsVariable(varName);
    }

    public getRoomNumber(room: SFSRoom, varName: string, defValue = 0): number {
        if (room.containsVariable(varName)) {
            return room.getVariable(varName).value as number;
        }
        return defValue;
    }

    public getRoomBoolean(room: SFSRoom, varName: string, defValue = false): boolean {
        if (room.containsVariable(varName)) {
            return room.getVariable(varName).value as boolean;
        }
        return defValue;
    }

    public getRoomString(room: SFSRoom, varName: string, defValue: string = undefined): string | undefined {
        if (room.containsVariable(varName)) {
            return room.getVariable(varName).value as string;
        }
        return defValue;
    }

}

export const sfsService = SfsService.Instance;
