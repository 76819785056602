import React, { FunctionComponent, useEffect, useState } from 'react';
import moment from 'moment';

import './countdown-component.scss';

export type CountdownProps = {
    startTime?: number;
    onCountdownEnd?: () => void;
};

export const CountdownComponent: FunctionComponent<CountdownProps> = (props) => {
    const { startTime, onCountdownEnd } = props;
    const [currentTime, setCurrentTime] = useState<CountdownProps['startTime']>(undefined);

    useEffect(() => {
        if (startTime !== null) {
            setCurrentTime(startTime);
        } else {
            setCurrentTime(undefined);
        }
    }, [startTime]);

    useEffect(() => {
        if (currentTime != null) {
            setTimeout(() => {
                setCurrentTime(Math.max(currentTime - 1, 0));
            }, 1000);
            if (currentTime === 0 && onCountdownEnd) {
                onCountdownEnd();
            }
        }
    }, [currentTime]);

    if (currentTime == null) {
        return null;
    } else {
        const duration = moment.duration(currentTime, 'seconds');
        const days = Math.floor(currentTime / 86400);
        const format =
            currentTime < 43200
                ? currentTime < 120
                    ? 'mm : ss'
                    : 'H[h] : m[m]'
                : days < 1
                ? 'H[h] : m[m]'
                : `${days < 10 ? '' : ''}${days}[ days] : HH[ hours]`;
        return (
            <section className="CountdownComponent">{moment(duration.asMilliseconds()).utc().format(format)}</section>
        );
    }
};
