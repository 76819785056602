import {
    Auction,
    Bid,
    BridgePosition,
    Card,
    ForeignBoardReviewData,
    ForeignBoardReviewPlayerData,
    GamePhase,
    SeatPosition,
    SharkGameResultsV2,
    Trick,
    levels,
    strains,
    suitOrders,
} from '../app/types';
import { AuxiliaryMessage, TableInfo } from '../slices/tableSlice';
import {
    Explanation,
    GameEngineInterface,
    ProcessorFunction,
    mapDispatchToProps,
    mapStateToProps,
} from './game-engine-interface.class';
import { MeetingState, SFSVAR, getSFSFalse, getSFSNumber } from './sfsVar';
import { S3BoardData, convertS3toForeignBoardReviewData } from './s3-board-map';
import { SFSConnectionStatus } from '../slices/appSlice';
import { SFSObject, SFSRoom, SFSUser } from 'sfs2x-api';
import {
    SeatPosFor,
    convertIntToSuitStr,
    convertPlayerIdToIntBridgePosition,
    strToCall,
    swapPlayerIdToBridgePosition,
} from './game-engine-helper';
import { bridgePos, numBridgePos } from '../app/defaults';
import { claimButton, concedeButton, dashboardButton, replayButton, undoButton } from './buttonsState';
import { connect } from 'react-redux';
import { environment } from '../env';
import { getMySeatData } from '../utils/mixed';
import {
    getPartnerBridgePosition,
    getSocketErrorlogin,
    getUndoConfirmPrompt,
    insertSuits,
    insertSuitsFromCall,
} from '../utils/shark-helper';
import { lineForMinMaxValue, lineForPoints, lineForSimpleString, lineForSuitEndingWith } from './handle-bid-explanation';
import { modalGeneric } from '../utils/modal.helper';
import { sfsService } from './sfs-service';
import { tableInfoFromSFSObject } from './handState';
import process from './process-mid/collect';

export class SharkGameEngineInterface extends GameEngineInterface {
    private mid_processor: Record<number, ProcessorFunction> = {
        [SFSVAR.netMID.MID_NEW_DEAL]: process.MID_NEW_DEAL_SB, // 0
        [SFSVAR.netMID.MID_CURRENT_DEAL]: process.MID_CURRENT_DEAL_SB, // 1
        [SFSVAR.netMID.MID_TIME_BID]: process.MID_TIME_TO_BID, // 5
        [SFSVAR.netMID.MID_MADE_BID]: process.MID_MADE_BID_SB, // 6
        [SFSVAR.netMID.MID_CONTRACT]: process.MID_CONTRACT, // 7
        [SFSVAR.netMID.MID_TIME_PLAY]: process.MID_TIME_TO_PLAY, // 8
        [SFSVAR.netMID.MID_MADE_PLAY]: process.MID_MADE_PLAY, // 9
        [SFSVAR.netMID.MID_TIME_CLOSE_TRICK]: process.MID_TIME_CLOSE_TRICK, // 10
        [SFSVAR.netMID.MID_TIME_SCORE]: process.MID_TIME_TO_SCORE_SB, // 11
        [SFSVAR.netMID.MID_CLAIM_RESPONSE]: process.MID_CLAIM_RESPONSE_SB, //15
        [SFSVAR.netMID.MID_CLAIM_NOTIFICATION]: process.MID_CLAIM_NOTIFICATION, //16
        [SFSVAR.netMID.MID_UNDO_RESPONSE]: process.MID_UNDO_RESPONSE, //18
        [SFSVAR.netMID.MID_UNDO_NOTIFICATION]: process.MID_UNDO_NOTIFICATION, //19
        [SFSVAR.netMID.MID_CHANGE_HAND_VISIBILITY]: process.MID_CHANGE_HAND_VISIBILITY, //25
        [SFSVAR.netMID.MID_CONTROL_ACTION]: process.MID_ACTION_CONTROL, //27
        [SFSVAR.netMID.MID_END_OF_SET]: process.MID_END_OF_SET, //36
        [SFSVAR.netMID.MID_START_ROUND]: process.MID_START_ROUND,
        [SFSVAR.netMID.MID_SET_HANDS]: process.MID_SET_HANDS,
        [SFSVAR.netMID.MID_TEXT_MESSAGE]: process.MID_TEXT_MESSAGE,
        [SFSVAR.netMID.MID_WAIT_FOR_ROUND]: process.MID_WAIT_FOR_ROUND, //44
        [SFSVAR.netMID.MID_SHOW_WELCOME]: process.MID_SHOW_WELCOME, //45
    };
    protected processObject = (cmdObj: SFSObject) => {
        const mid: number = cmdObj.getInt(SFSVAR.EXTENSION_MID);
        const processor = this.mid_processor[mid];
        if (processor) {
            processor(cmdObj, this); // Call the method
        } else {
            console.log(`No processor found for MID ${mid}`);
        }
    };

    protected configureSFS() {
        const useEncryption = true;
        // Create configuration object
        let hostName: string = this.props.app.urlParams?.['sfshost']?.toLowerCase() ?? '';
        const teacherName: string = this.props.app.urlParams?.['sfsteacher'] ?? '';
        if (teacherName === 'Shark Table' && hostName === 'acbl') {
            hostName = 'sfs';
        }
        const zone: string = this.props.app.urlParams?.['service'] ?? 'OnlineBridgeClub';
        this.config = {
            host: hostName === 'local' ? 'local.onlinebridge.club' : hostName + '.emabridge.com',
            port: hostName === 'local' ? 8443 : 443,
            useSSL: useEncryption,
            zone: zone,
        };
    }

    protected onLoginError = ({ errorMessage, errorCode }: { errorMessage: string; errorCode: number }) => {
        console.log('Shark Bridge onLoginError : ', errorMessage, errorCode);
        this.props.app_setEngineState({
            sfsConnectionStatus: SFSConnectionStatus.disconnected,
        });
        this.props.app_addModal(
            getSocketErrorlogin(() => {
                sfsService.sfs.disconnect();
                parent.postMessage('reloaddash', '*');

            })
        );
    };

    protected onUserEnterRoom = ({ room, user }: { room: SFSRoom; user: SFSUser }) => {
        //this.props.showChatSendForm(room.getUserList().length > 1);
    };

    //IMPORTANT: if the user that left is ME, then move out of table to lobby, for now probably just a simple note or somethign.
    /**/
    protected onUserExitRoom = ({ room, user }: { room: SFSRoom; user: SFSUser }) => {
        // if (user.isItMe) {
        //     if (room.id !== this.sfs?.lastJoinedRoom) return;
        //     //Close the table interface and back to lobby.
        //     this.props.resetGameExcept(['jitsi', 'metaData', 'gamePhase']);
        //     this.props.setGamePhase(GamePhase.END);
        //     //this.props.resetGamePartials(['tricks', 'winner', 'contract', 'stake', 'cards', 'auction','gamePhase','gameResults']);
        //     if (room.isGame) {
        //         /*
        //         this.setState({
        //             meetingState:
        //                 this.props.app.engineState.interface.jitsiconf !== undefined || this.props.app.engineState.interface.twitchconf !== undefined ? MeetingState.inLobby : MeetingState.NONE
        //         });
        //         //this.props.app.engineState.interface.jitsibreakconf = undefined;
        //         this.refreshMeeting();
        //
        //          */
        //     }
        // } else {
        //     this.props.showChatSendForm(room.getUserList().length > 1);
        // }
    };

    //This is like the onRoomJoin, but the player was actually kibitzing an is now seated at the table
    protected onSpectatorToPlayerSwitch = ({
        room,
        user,
        playerID,
    }: {
        room: SFSRoom;
        user: SFSUser;
        playerID: number;
    }) => {
        // if (!room.isGame || room !== this.sfs?.lastJoinedRoom || !user.isItMe) {
        //     return;
        // }
        // const playerRID: number = this.sfs?.mySelf.getPlayerId(room);
        // this.props.setMyBridgePosition(convertPlayerToBridgePosition(playerRID));
        // this.setState({
        //     myposition: convertPlayerToBridgePosition(playerRID)
        // });
        // this.sendRoomCommand(SFSVAR.CMD_INTERFACE_READY, new SFSObject());
    };

    protected onSpectatorToPlayerSwitchError = ({
        errorMessage,
        errorCode,
    }: {
        errorMessage: string;
        errorCode: number;
    }) => {
        //console.log('Unable to become a player due to the following error: ' + errorMessage);
    };

    //Unseat the player, it turns in Kibitz. The important action here is to adjust hand visibility based on current rules for kibitzers
    protected onPlayerToSpectatorSwitch = ({ room, user }: { room: SFSRoom; user: SFSUser }) => {
        // if (!room.isGame || room !== this.sfs?.lastJoinedRoom || user.isItMe) {
        //     return;
        // }
        // this.props.hideButtons(['undo', 'claim']);
        // this.props.setMyBridgePosition(convertIntToBridgePosition(this.props.app.engineState.interface.currentPlayerOnServer));
        // this.setState({
        //     myposition: BridgePosition.south
        // });
        // this.sendRoomCommand(SFSVAR.CMD_INTERFACE_READY, new SFSObject());
    };

    protected onPlayerToSpectatorSwitchError = ({
        errorMessage,
        errorCode,
    }: {
        errorMessage: string;
        errorCode: number;
    }) => {
        //console.log('Unable to become a spectator due to the following error: ' + errorMessage);
    };

    protected onRoomJoined = ({ room }: { room: SFSRoom }) => {
        //console.log('Room joined successfully: ',room);
        const { app } = this.props;
        const playerID: number = sfsService.sfs?.mySelf.getPlayerId(room) ?? 0;
        switch (room.isGame) {
            case false: {
                this.props.app_updateEngineInterfaceState({
                    lobbyRoom: room,
                    competeMode: room.containsVariable(SFSVAR.SFSGAME_ROOM_ROUND),
                });
                sfsService.lobby = room;
                break;
            }
            case true: {
                sfsService.table = room;
                this.props.table_setTopBridgePosition(
                    environment === 'audrey'
                        ? BridgePosition.north
                        : swapPlayerIdToBridgePosition(playerID ?? 4) ?? BridgePosition.north,
                );

                const startTime: number = sfsService.getPlayerNumber(SFSVAR.SFSGAME_ROOM_STT, undefined);
                const endTime: number = sfsService.getLobbyNumber(SFSVAR.SFSGAME_ROOM_UTC_ENDTTIME, undefined);
                this.props.table_updateTable({
                    timeToGameStart:startTime > 1 ? startTime : undefined,
                    timeToGameEnd:startTime > 1 ? startTime : undefined,
                });

                if (endTime) {
                    this.props.app_setSoundPlaying('tournamentStart');
                    this.props.table_updateTable({
                        timeToGameStart: undefined,
                        timeToGameEnd: endTime - Math.floor(Date.now() / 1000),
                    });
                }

                this.props.app_updateEngineInterfaceState({
                    isMiniBridge: room.containsVariable(SFSVAR.SFSGAME_MINIBRIDGE)
                        ? (room.getVariable(SFSVAR.SFSGAME_MINIBRIDGE).value as boolean)
                        : false,
                    playerID: playerID,
                    //                   lobbyRoom: this.props.app.engineState.interface.lobbyRoom ?? room,
                });

                if (room.containsVariable(SFSVAR.SFSGAME_MODE)) {
                    this.props.app_updateEngineInterfaceState({
                        temp: {
                            gameMode: room.getVariable(SFSVAR.SFSGAME_MODE).value as number,
                            isBiddingQuiz: room.getVariable(SFSVAR.SFSGAME_MODE).value === SFSVAR.gameMode.BiddingQuiz,
                        },
                    });
                }

                this.props.app_updateEngineInterfaceState({
                    temp: {
                        tableID: room.containsVariable(SFSVAR.SFSGAME_ROOM_DISPLAY)
                            ? ` ${room.getVariable(SFSVAR.SFSGAME_ROOM_DISPLAY).value} `
                            : `Table: ${room.id} `,
                        meetingState: this.props.app.engineState.interface.lobbyRoom?.containsVariable(
                            SFSVAR.RVAR_MEETING_STATE,
                        )
                            ? (this.props.app.engineState.interface.lobbyRoom.getVariable(SFSVAR.RVAR_MEETING_STATE)
                                  .value as MeetingState)
                            : MeetingState.NONE,
                    },
                });

                if (room.containsVariable(SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS_V2)) {
                    try {
                        const resultV = room.getVariable(SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS_V2).value as SFSObject;
                        const json = new TextDecoder('utf-8').decode(resultV.getByteArray('data'));
                        const game_results = JSON.parse(json) as SharkGameResultsV2;
                        this.props.table_updateTable({ sharkGameResultsV2: game_results });
                    } catch (e) {
                        console.error('could not parse ', e);
                    }
                }

                this.sendRoomCommand(SFSVAR.CMD_INTERFACE_READY, new SFSObject());

                this.clientRequestsSettingsUpdate();
                break;
            }
        }
    };

    //This one can have data, regarding request to unblock a user, we will not deal with it now.
    protected onAdminMessage = ({ message, sender, data }: { message: any; sender: SFSUser; data: SFSObject }) => {
        const apiCall: boolean = data ? data.containsKey('sendername') : false;
        const isPop: boolean = data ? data.containsKey('popup') : false;
        if (!isPop) {
            const senderName: string = apiCall ? data.getUtfString('sendername') : 'Shark';
            const m: AuxiliaryMessage = {
                text: `${message}`,
                title: `${senderName}`,
                titleColor: undefined,
                buttonOk: undefined,
                buttonCancel: undefined,
                buttonAuxiliary: undefined,
            };
            this.props.table_setAuxiliaryMessage({ position: 'left', message: m });
            //this.props.addChatEntry({ timestamp: moment.utc().format(), sender: senderName, message: message, isSystemMessage: !apiCall });
            return;
        }

        modalGeneric(`${message}`, apiCall ? data.getUtfString('sendername') : 'Shark', this.props.app_removeModal);
    };

    protected onModeratorMessage = ({ message, sender }: { message: string; sender: SFSUser }) => {
        return;
    };
    //On a special case where there is data sent back,
    protected onPublicMessage = ({ message, sender, data }: { message: string; sender: SFSUser; data: SFSObject }) => {
        // this.props.addChatEntry({
        //     timestamp: moment.utc().format(),
        //     sender: sender.containsVariable('pn')
        //             ? sender.getVariable('pn').value
        //             : sender.containsVariable('nn')
        //               ? sender.getVariable('nn').value
        //               : sender.name,
        //     message: message
        // });
        // if (message === 'fullscreen') {
        //     this.props.setJitsiFullSize(true);
        // } else if (message === 'normalscreen') {
        //     this.props.setJitsiFullSize(false);
        // }
    };

    protected onPrivateMessage = ({ message, sender, data }: { message: string; sender: SFSUser; data: SFSObject }) => {
        // this.props.addChatEntry({
        //     timestamp: moment.utc().format(),
        //     sender: sender.containsVariable('pn')
        //             ? sender.getVariable('pn').value
        //             : sender.containsVariable('nn')
        //               ? sender.getVariable('nn').value
        //               : sender.name,
        //     message: message
        // });
        // if (this.consolOut) {
        //     console.log('Received message: ' + message);
        // }
    };

    //protected process_MID_MADE_BID = process.MID_MADE_BID_SB;

    public setTableInfo(cmdObj: SFSObject) {
        const state: SFSObject | undefined = cmdObj.getSFSObject('tablestate');
        const infoObj: SFSObject | undefined = state?.containsKey('tbinfo') ? state.getSFSObject('tbinfo') : undefined;
        const leftinfo: TableInfo[] = [];
        const rightinfo: TableInfo[] = [];

        const sIndex: number =
            getSFSNumber(cmdObj, SFSVAR.SFSGAME_MID_LOAD_STATE, 0) ??
            getSFSNumber(cmdObj, SFSVAR.SFSGAME_MID_LOAD_STATE) ??
            state.containsKey('bn')
                ? state.getInt('bn') + 1
                : 1;

        const nBoards: number | undefined = this.props.app.engineState.interface.lobbyRoom?.containsVariable(
            SFSVAR.SFSGAME_ROOM_NB,
        )
            ? (this.props.app.engineState.interface.lobbyRoom?.getVariable(SFSVAR.SFSGAME_ROOM_NB).value as number)
            : sfsService.getNumber(SFSVAR.SFSGAME_ROOM_NB, undefined);
        const boardInfo: object = {
            label: `${
                getSFSNumber(cmdObj, SFSVAR.SFSGAME_ROOM_ROUND) ?? getSFSNumber(cmdObj, SFSVAR.SFSGAME_MID_LOAD_STATE)
            }`,
            value: `${sIndex} of ${nBoards}`,
            boardNumber: getSFSNumber(cmdObj, SFSVAR.SFSGAME_MID_LOAD_STATE),
        };

        const hasNext: boolean =
            (cmdObj.containsKey('hasnext') && cmdObj.getBool('hasnext')) ||
            (nBoards !== undefined && sIndex !== nBoards);
        this.props.app_updateEngineInterfaceState({
            hasNext: hasNext,
        });
        this.props.table_setBoard(boardInfo);

        //Set the dealer
        leftinfo.push(
            tableInfoFromSFSObject(infoObj, 'dealer') ?? {
                label: 'Dealer: ',
                value: this.props.table.dealer
                    ? this.props.table.dealer.charAt(0).toUpperCase() +
                      this.props.table.dealer.substring(1).toLowerCase()
                    : '',
                hidden: false,
            },
        );

        leftinfo.push(
            tableInfoFromSFSObject(infoObj, 'vuln') ?? {
                label: 'Vul: ',
                value: this.props.table.vulnerable
                    ? this.props.table.vulnerable.length === 2
                        ? this.props.table.vulnerable.toUpperCase()
                        : this.props.table.vulnerable.charAt(0).toUpperCase() +
                          this.props.table.vulnerable.substring(1).toLowerCase()
                    : '',
                hidden: false,
            },
        );

        leftinfo.push(
            tableInfoFromSFSObject(infoObj, 'deal') ?? {
                label: 'Board ',
                value: nBoards ? `${sIndex} of ${nBoards}` : cmdObj.getInt(SFSVAR.SFSGAME_MID_LOAD_STATE).toString(),
                hidden: false,
            },
        );

        if (infoObj !== undefined) {
            rightinfo.push(tableInfoFromSFSObject(infoObj, 'cont'));
            rightinfo.push(tableInfoFromSFSObject(infoObj, 'decl'));
            rightinfo.push(tableInfoFromSFSObject(infoObj, 'obj'));
        }
        if (environment === 'shark') {
            this.props.table_setTableInfo({ left: undefined, right: rightinfo.length > 0 ? rightinfo : undefined });
            this.props.table_setTableSymbol(true);
        } else {
            this.props.table_setTableInfo({ left: leftinfo, right: rightinfo });
            this.props.table_setTableSymbol(false);
        }
    }

    public setControlButtons(cmdObj: SFSObject | undefined) {
        //TODO: adjust control buttons
        // console.log("Control Buttons : " , cmdObj);
        // const footerButtons: TableState['footerButtons'] = [...this.props.table.footerButtons];
        // const noButtons = cmdObj === undefined;
        //Add the ever present Dashboard
        // footerButtons.push({
        //     label: noButtons || !cmdObj.containsKey('button0') ? 'Dashboard' : cmdObj.getUtfString('button0'),
        //     id: 'dashboard',
        //     value: true,
        //     highlighted: false,
        //     index: 0
        // });

        this.showButtons([dashboardButton]);
        if (this.shouldShowUndo()) {
            this.showButtons([undoButton]);
        }
        //       this.hideButtons([prevHandButton, nextHandButton, undoButton, claimButton]);
    }

    public setContractFromSFSObject(sfsObject: SFSObject) {
        const gameState: SFSObject | undefined = sfsObject.containsKey('contract') ? sfsObject : undefined;
        const contract: SFSObject | undefined = gameState?.getSFSObject('contract')
            ? sfsObject.getSFSObject('contract')
            : sfsObject;
        //console.log("Set SB Contract", sfsObject?.getDump());
        if (!contract) {
            return;
        }

        if (this.props.app.engineState.interface.isMiniBridge || getSFSFalse(gameState, SFSVAR.SFSGAME_NOAUCTION)) {
            this.props.table_setShowAuctionLine(false);
        } else {
            this.props.table_setShowAuctionLine(environment === 'audrey');
        }

        const showUndo = this.shouldShowUndo();
        const passedHand = contract.getBool('passed');
        const completeAuction = contract.getInt('declarer') >= 0;

        if (this.shouldShowReplay()) {
            this.showButtons([replayButton]);
        }

        if (showUndo && !passedHand) {
            //       this.showButtons([undoButton]);
        }
        this.hideButtons([claimButton, concedeButton]);

        if (passedHand || !completeAuction) {
            //sender.props.removeModal('NonBlockingSpalash');
            //We are at the end of passed out deal, the End of the hand logic should kick in, when we figured what is it
            //There being no declarer, not stakes, just 4 passes in the auction.
            if (passedHand) {
                this.props.table_updateTable({ gamePhase: GamePhase.END });
            }
            return;
        }

        // this.props.removeModal('NonBlockingSpalash');
        const declInt = contract.getInt('declarer');

        //console.log('Declarer pos', bridgePos(declInt), SeatPosFor(bridgePos(declInt), this.props.table));
        if (SeatPosFor(bridgePos(declInt), this.props.table) === SeatPosition.top) {
            // sender.props.table_setTopBridgePosition(playerIdToBridgePosition(sender.state.playerID));
        }
        const stake: number = contract.getInt('stake');
        const levelIndex: number = contract.getInt('level');
        const strainIndex: number = contract.getInt('trump'); //Use that one for proper suit rotation in the hand
        const nextPlayerBridgePosition: BridgePosition = bridgePos(contract.getInt('leader'));
        this.props.table_updateTable({
            contract: {
                level: levels[levelIndex],
                strain: strains[strainIndex],
                stake: stake as 1 | 2 | 4,
                call: strToCall(`${levelIndex}${convertIntToSuitStr(strainIndex)}`),
            },
            gamePhase: GamePhase.PLAY,
            player: nextPlayerBridgePosition,
            suitOrder: suitOrders[strainIndex],
            showTrickCounter: environment,
            declarer: bridgePos(declInt),
        });
        this.updateBoardData(undefined);
    }

    public updateBoardData(
        extraPlayer:
            | { auction: Auction; tricks: Trick[]; winner: BridgePosition[]; declarer: BridgePosition }
            | undefined,
    ) {
        //   console.log('update SB stats', this.props.table.boardStats, this.props.table.myBoardStatKeys);
        const playerData: ForeignBoardReviewPlayerData[] = [];

        playerData.push({
            auction: this.props.table.auction,
            tricks: this.props.table.tricks,
            //      winners: this.props.table.winners,
            uuid: '',
            declarer: this.props.table.declarer,
        });
        if (extraPlayer) {
            playerData.push({
                auction: extraPlayer.auction,
                tricks: extraPlayer.tricks,
                //         winners: extraPlayer.winner,
                uuid: '',
                declarer: extraPlayer.declarer,
            });
        }

        this.props.table_setForeignBoardReviewData({
            cards: this.props.cards,
            seatData: this.props.table.seatData,
            contract: this.props.table.contract,
            gamePhase: this.props.table.gamePhase,
            vulnerable: this.props.table.vulnerable,
            showAuctionBox: this.props.table.showAuctionBox,
            boardStats: this.props.table.boardStats,
            myBoardStatKeys: this.props.table.myBoardStatKeys,
            playerData: playerData,
            declarer: this.props.table.declarer,
            dealer: this.props.table.dealer,
            //       winners: this.props.table.winners,
        });
    }

    protected loadBoardReview(g_uuid: string, p_uuid: string, bn: number, c_uuid: string | undefined) {
        if (!this.props.app.engineState.interface.boardData[p_uuid]) {
            const resultUrl = `https://duplicate-games.s3.us-east-2.amazonaws.com/${g_uuid}/pairs/${p_uuid}.json`;
            fetch(resultUrl)
                .then((response) => {
                    return response.json();
                })
                .then((responseJson) => {
                    this.props.app_updateEngineInterfaceState(
                        {
                            boardData: {
                                ...this.props.app.engineState.interface.boardData,
                                [p_uuid]: responseJson as S3BoardData,
                            },
                        },
                        // () =>  {
                        //     this.loadPlayerBoardReview(g_uuid, p_uuid, bn, c_uuid)
                        // },
                    );
                })
                .catch((error) => {
                    console.error(`S3 bucket failed Error:  ${resultUrl}`, error);
                });
        } else {
            this.loadPlayerBoardReview(g_uuid, p_uuid, bn, c_uuid);
        }
        return;
    }

    private loadPlayerBoardReview(g_uuid: string, p_uuid: string, bn: number, c_uuid: string | undefined) {
        const topPair: string | undefined = c_uuid
            ? c_uuid
            : this.props.table.sharkGameResultsV2?.Pairs[0].uuid !== p_uuid
            ? this.props.table.sharkGameResultsV2?.Pairs[0].uuid
            : this.props.table.sharkGameResultsV2?.Pairs[1] &&
              this.props.table.sharkGameResultsV2?.Pairs[1].uuid !== p_uuid
            ? this.props.table.sharkGameResultsV2?.Pairs[1].uuid
            : undefined;
        if (!topPair) {
            const boarddata: ForeignBoardReviewData | undefined = convertS3toForeignBoardReviewData(
                this.props.app.engineState.interface.boardData[p_uuid],
                bn,
                0,
            );
            boarddata.playerData[1] = {
                tricks: [],
                auction: [],
                //           winners: [],
                uuid: '',
                declarer: undefined,
            };
            this.props.table_updateTable({
                dealer: boarddata.dealer,
                vulnerable: boarddata.vulnerable,
            });
            this.props.table_setForeignBoardReviewData(boarddata);
            return;
        }

        if (!this.props.app.engineState.interface.boardData[topPair]) {
            const resultUrl = `https://duplicate-games.s3.us-east-2.amazonaws.com/${g_uuid}/pairs/${topPair}.json`;

            fetch(resultUrl)
                .then((response) => {
                    return response.json();
                })
                .then((responseJson) => {
                    const s3data = responseJson as S3BoardData;
                    this.props.app_updateEngineInterfaceState(
                        {
                            boardData: {
                                ...this.props.app.engineState.interface.boardData,
                                [topPair]: s3data,
                            },
                        },
                        // () => {
                        // this.updateForegndata(g_uuid, p_uuid, bn, topPair);
                        // }
                    );
                })
                .catch((error) => {
                    console.error(`S3 bucket failed Error:  ${resultUrl}`, error);
                });
        } else {
            this.updateForegndata(g_uuid, p_uuid, bn, topPair);
        }
    }

    private updateForegndata(g_uuid: string, p_uuid: string, bn: number, c_uuid: string | undefined) {
        // const delay = () => new Promise((resolve) => setTimeout(resolve, 0));
        // await delay();
        const boarddata: ForeignBoardReviewData | undefined = convertS3toForeignBoardReviewData(
            this.props.app.engineState.interface.boardData[p_uuid],
            bn,
            0,
        );

        const boarddataComp: ForeignBoardReviewData | undefined = c_uuid
            ? convertS3toForeignBoardReviewData(this.props.app.engineState.interface.boardData[c_uuid], bn, 0)
            : boarddata;

        if (boarddata?.playerData?.length > 0 && boarddataComp?.playerData?.length > 0) {
            boarddata.playerData[1] = boarddataComp.playerData[0];
        }
        this.props.table_updateTable({
            dealer: boarddata.dealer,
            vulnerable: boarddata.vulnerable,
        });
        this.props.table_setForeignBoardReviewData(boarddata);
    }

    protected clientRequestsReplay = () => {
        if (!this.shouldShowReplay()) {
            //Post a message, that replay is not allowed at this time. That should be rear since replay button is not allowed. Server also checks and does nothing.
        }
        const sfsObj = new SFSObject();
        sfsObj.putBool(SFSVAR.SFSGAME_MID_LOAD, false);
        sfsObj.putInt(SFSVAR.EXTENSION_MID, SFSVAR.netMID.MID_CONTROL_ACTION);
        this.sendRoomCommand(SFSVAR.CMD_REPLAY_DEAL, sfsObj);
    };

    protected clientRequestsUndo = () => {
        const id = 'undoNotification';
        this.props.app_addModal(
            getUndoConfirmPrompt(sfsService.getString('gs5') ?? '', () => {
                this.sendRoomCommand(SFSVAR.CMD_REQUEST_UNDO, new SFSObject());
            }),
        );
    };

    // Client responds request to undo
    private clientResponseUndo(response: string) {
        const resp: boolean = response === 'approve';
        // TODO send notification to server
        const sfsObj = new SFSObject();
        sfsObj.putInt(SFSVAR.EXTENSION_MID, SFSVAR.netMID.MID_UNDO_RESPONSE);
        sfsObj.putBool(SFSVAR.UNDO_RESPONSE, response === 'approve');
        sfsObj.putInt(
            SFSVAR.SFSGAME_SEAT_ID,
            convertPlayerIdToIntBridgePosition(this.props.app.engineState.interface.playerID),
        );
        this.sendRoomCommand(SFSVAR.CMD_RESPONSE_UNDO, sfsObj);
        // this.props.removeCurrentModal();
    }

    protected clientRequestsMakeCall = (bid: Bid) => {
        //console.log("Request Bid", bid);
        this.props.output_reset();
        if (!bid.call) {
            return;
        }
        const updatedAuction = [...this.props.table.auction.filter(item => item.call !== 'q')];
        this.props.table_setAuction(updatedAuction);
        //Disable Bidding Keyboard
        this.props.table_updateBiddingLadder({
            isActive: false,
        });
        this.clearAuxiliaryMessages();
        const sfsObj = new SFSObject();
        //IMPORTANT Call need to have Int parameter, one of  /* Call intValue consta nts */
        sfsObj.putUtfString(SFSVAR.SFSGAME_MID_LOAD, bid.call);
        sfsObj.putBool(SFSVAR.SFSGAME_MID_ROBOT, false);
        this.sendRoomCommand(SFSVAR.CMD_MAKE_CALL, sfsObj);
        if (this.shouldShowUndo()) {
            this.showButtons([undoButton]);
        }
    };

    protected clientRequestsMakePlay(card: Card) {
        this.props.output_reset();
        this.props.table_setWalkTrough(undefined);
        this.props.table_setAuxiliaryMessage({ position: 'left', message: undefined });
        this.props.table_setAuxiliaryMessage({ position: 'right', message: undefined });
        this.props.table_setAuxiliaryMessage({ position: 'center', message: undefined });
        this.props.table_setSeatData({
            seatPosition: SeatPosFor(card.bridgePosition, this.props.table),
            isInteractive: false,
            background: undefined,
        });
        const sfsObj = new SFSObject();
        sfsObj.putUtfString(SFSVAR.SFSGAME_MID_LOAD, card.id);
        sfsObj.putBool(SFSVAR.SFSGAME_MID_ROBOT, false);
        sfsObj.putInt(SFSVAR.SFSGAME_CURENT_PLAYER, card.bridgePosition ? numBridgePos[card.bridgePosition] ?? -1 : -1);
        this.sendRoomCommand(SFSVAR.CMD_MAKE_PLAY, sfsObj);
        this.hideButtons([claimButton, concedeButton]);

        if (this.shouldShowUndo()) {
            this.showButtons([undoButton]);
        }
    }

    protected clientRequestsClaim = (claim: number | undefined) => {
        if (!this.props.app.isInteractive) {
            this.clearAuxiliaryMessages();
            return;
        }
        const sfsObj = new SFSObject();
        sfsObj.putInt(SFSVAR.SFSGAME_MID_LOAD, claim ?? 0);
        sfsObj.putInt(
            SFSVAR.SFSGAME_CURENT_PLAYER,
            numBridgePos[this.props.app.engineState.interface.currentPlayer ?? BridgePosition.south],
        );
        this.sendRoomCommand(SFSVAR.CMD_REQUEST_CLAIM, sfsObj);
        this.hideButtons([claimButton, concedeButton]);
        this.clearAuxiliaryMessages();
    };

    private shouldShowReplay() {
        if (this.isPostMortem()) {
            return true;
        }
        return sfsService.getBoolean(SFSVAR.SFSGAME_ALLOW_REPLAY, false);
    }

    public shouldShowUndo() {

        if (this.props.app.engineState.interface.playerID === 0) {
            return false;
        }

        const { gamePhase } = this.props.table;

        if (gamePhase === GamePhase.DEAL) {
            return false;
        }

        if (this.props.app.engineState.interface.playerID <= 0) {
            return false;
        }

        if (sfsService.hasVar('sg5')) {
            return sfsService.getNumber('sg5') > 0;
        }

        return !sfsService.getBoolean(SFSVAR.SFSGAME_NOUNDO, false);
    }

    public shouldShowClaim() {
        if (this.props.app.engineState.interface.playerID === 0) {
            return false;
        }
        const { table } = this.props;
        const { gamePhase, seatData } = table;

        //     return true;

        // if (gamePhase !== GamePhase.PLAY) {
        //     return false;
        // }
        // if (this.sfs && this.sfs.mySelf.getPlayerId(this.sfs.lastJoinedRoom) <= 0) {
        //     return false;
        // }
        //
        const noclaim = sfsService.getBoolean(SFSVAR.SFSGAME_NOCLAIM, false);
        if (noclaim) {
            return false;
        }

        const mybridgeposition: BridgePosition | undefined = getMySeatData(seatData)?.bridgePosition;

        if (mybridgeposition === table.declarer || mybridgeposition === getPartnerBridgePosition(table.declarer)) {
            return !noclaim;
        }
        return false;
    }

    public shouldShowConcede() {
        if (this.props.app.engineState.interface.playerID === 0) {
            return false;
        }
        const { table } = this.props;
        const { gamePhase, seatData } = table;

        const noclaim = sfsService.getBoolean(SFSVAR.SFSGAME_NOCLAIM, false);
        if (noclaim) {
            return false;
        }

        const mybridgeposition: BridgePosition | undefined = getMySeatData(seatData)?.bridgePosition;

        if (table.declarer && mybridgeposition !== table.declarer  && mybridgeposition !== getPartnerBridgePosition(table.declarer)) {
            return !noclaim;
        }
        return false;
    }

    protected clientRequestsExplCall = (bid: Bid) => {
        if (bid.alertMessage) {
            this.props.table_setAuxiliaryMessage({ position: 'right', message: {
                    text: `${bid.alertMessage ?? bid.explanation}`,
                    title: `${bid.call} Alert!`,
                    titleColor: undefined,
                    buttonOk: undefined,
                    buttonCancel: 'OK',
                    buttonAuxiliary: undefined,
                } });
            return;
        }
        const expl = bid.explanation as Explanation;
        if (!expl) {return;}

        const call: object | undefined = 'call' in expl ? expl['call']  : undefined;
        const hand: object | undefined = 'hand' in expl ? expl['hand'] : undefined;
        let message = '';
        if ('alert' in expl) {
            message = '</br>' + lineForSimpleString( 'alert', expl);
        } else if (call || hand) {
            message += lineForPoints(call, hand);
            if (call) {
             //   message += lineForMinMaxValue('minl', 'maxl', 'Losers: ', call, hand);
            }
            message += lineForSuitEndingWith('spade', call,hand) ?? '';
            message += lineForSuitEndingWith('heart', call, hand) ?? '';
            message += lineForSuitEndingWith('diamond', call, hand) ?? '';
            message += lineForSuitEndingWith('club', call,hand) ?? '';
            message += lineForMinMaxValue('minaces', 'maxaces', 'Aces: ', call);
            message += lineForSimpleString('distro', call);
            message += lineForSimpleString('conv', call);
            if (hand && 'misc' in hand) {
                message += lineForSimpleString('misc', hand);
            }
        } else {
            message = 'Natural';
        }
        this.props.table_setAuxiliaryMessage({ position: 'right', message: {
                text: `${insertSuits(message)}`,
                title: `${insertSuitsFromCall(bid.call)} explanation`,
                titleColor: undefined,
                buttonOk: undefined,
                buttonCancel: 'OK',
                buttonAuxiliary: undefined,
            } });
        this.props.output_reset();
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SharkGameEngineInterface);
