import { classNames } from '../../utils/mixed';
import { useAppSelector } from '../../app/hooks';
import AuctionBoxComponent from '../auction-box-component/auction-box-component';
import React, { FunctionComponent } from 'react';
import SharkBoardStatsComponent from '../shark-board-stats-component/shark-board-stats-component';
import SharkTrickHistoryComponent from '../shark-trick-history-component/shark-trick-history-component';
import ShortTableComponent from '../short-table-component/short-table-component';

import './shark-board-review-component.scss';

const SharkBoardReviewComponent: FunctionComponent<{
    isStandAlone?: boolean;
}> = ({ isStandAlone }) => {
    const game = useAppSelector((state) => state.table);

    if (!game.foreignBoardReviewData) {
        return null;
    }

    const { playerData, seatData, contract, gamePhase, cards, vulnerable, showAuctionBox, declarer } =
        game.foreignBoardReviewData;

    return (
        <section className={classNames('SharkBoardReviewComponent', isStandAlone && 'stand-alone')}>
            <div className="board-stats">
                <SharkBoardStatsComponent />
            </div>
            {isStandAlone && (
                <div className="short-table">
                    <ShortTableComponent seatData={seatData} contract={contract} gamePhase={gamePhase} cards={cards} />
                </div>
            )}

            <div className="auctionBox-trickHistory">
                {playerData.map((_playerData, index) => (
                    <div key={`auctionBox-trickHistory-column-${index}`} className="auctionBox-trickHistory-column">
                        <h3>{index === 0 ? 'Your Auction' : 'Recommended'}</h3>
                        <AuctionBoxComponent
                            foreignBoardData={{
                                seatData,
                                vulnerable,
                                showAuctionBox,
                                auction: _playerData.auction,
                            }}
                        />
                        <SharkTrickHistoryComponent
                            playerData={_playerData}
                            seatData={seatData}
                            declarer={declarer}
                            cards={cards}
                        />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default SharkBoardReviewComponent;
