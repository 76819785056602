import { GameEngine } from '../game-engine-helper';
import { SFSObject } from 'sfs2x-api';
import { SFSVAR } from '../sfsVar';
import { sfsService } from '../sfs-service';

export function MID_START_ROUND(cmdObj: SFSObject, sender: GameEngine) {
    sender.props.app_setSoundPlaying('tournamentStart');
    const endTime: number = sfsService.getLobbyNumber(SFSVAR.SFSGAME_REM_TIME, sfsService.getNumber(SFSVAR.SFSGAME_REM_TIME, undefined));
    if (endTime) {
        sender.props.table_updateTable({
            timeToGameStart: undefined,
            timeToGameEnd: endTime,
        });
    }
}
