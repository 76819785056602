import { LabeledInput } from '../../_basics/labeled-input/labeled-input';
import { soundTypes } from '../../_basics/audioPlayer';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent, useEffect } from 'react';
import useLocalStorage from 'react-use-localstorage';

import {
    possibleCardBacks,
    possibleCurrentPlayerHighlights,
    possibleGeneralLayouts,
    possibleHandLayouts,
    possibleInteractions,
    possibleSuitColorSets,
    possibleSuitOrders,
    tableBackgrounds,
    trayBackgrounds,
} from './options';

import './shark-settings-component.scss';
import { AppState, appActions } from '../../slices/appSlice';
import { SuitOrder, localStorageKeys } from '../../app/types';
import { getCssVarsForSuitColorSet } from '../../utils/shark-helper';
import { renderSuits } from '../../utils/mixed';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

const {
    app_setSharkLayout,
    app_setSharkSettings,
    app_setShowSettings,
    app_addModal,
    app_requestLogin,
    app_requestLogout,
} = appActions;

const SharkSettingsComponent: FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { sharkLayout, sharkSettings, user } = useAppSelector((state) => state.app);
    const [storedUser, setStoredUser] = useLocalStorage(localStorageKeys.shark.user, user);
    const [storedToken, setStoredToken] = useLocalStorage(localStorageKeys.shark.token, undefined);
    const [storedSettings, setStoredSettings] = useLocalStorage(
        localStorageKeys.shark.settings,
        JSON.stringify(sharkSettings),
    );
    const [storedLayout, setStoredLayout] = useLocalStorage(localStorageKeys.shark.layout, JSON.stringify(sharkLayout));
    const { suitOrder, colorSets, cardBack } = sharkLayout;

    useEffect(() => {
        setStoredSettings(JSON.stringify(sharkSettings));
    }, [sharkSettings]);

    useEffect(() => {
        setStoredLayout(JSON.stringify(sharkLayout));
    }, [sharkLayout]);

    const changeLayout = (layoutPartial: Partial<AppState['sharkLayout']>) => () => {
        dispatch(app_setSharkLayout(layoutPartial));
    };

    const changeSettings = (key: keyof AppState['sharkSettings'], value: any) => () => {
        dispatch(
            app_setSharkSettings({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                [key]: value,
            }),
        );
    };

    const changeSettingsArray = (key: keyof AppState['sharkSettings'], value: any) => () => {
        if (!Array.isArray(sharkSettings[key])) {
            return;
        }

        const array = (sharkSettings[key] || []) as any[];

        if (array.includes(value)) {
            dispatch(
                app_setSharkSettings({
                    [key]: array.filter((_value) => _value !== value),
                }),
            );
        } else {
            dispatch(
                app_setSharkSettings({
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    [key]: [...array, value],
                }),
            );
        }
    };

    const handleLogout = () => {
        const id = 'logoutConfirmation';
        dispatch(
            app_addModal({
                id,
                header: t('modal.logout.header'),
                body: [<div key="body">{t('modal.logout.body')}</div>],
                buttons: [
                    {
                        onClick: () => {
                            setStoredUser('');
                            setStoredToken('');
                            dispatch(app_requestLogout());
                        },
                        label: t('modal.logout.confirm'),
                        primary: true,
                    },
                ],
            }),
        );
    };

    const renderSelectCardBack = () => {
        return (
            possibleCardBacks.length > 1 && (
                <div className="selectCardBack">
                    <h3>{t('sharkSettings.selectCardBacks.title')}</h3>
                    <div className="cardBacks">
                        {possibleCardBacks.map((possibleCardBack, index) => (
                            <div
                                key={`cardBack-${index}`}
                                className={`cardBack ${cardBack === index ? 'selected' : ''}`}
                                style={{ backgroundImage: `url(${possibleCardBack})` }}
                                onClick={changeLayout({ cardBack: index })}
                            />
                        ))}
                    </div>
                </div>
            )
        );
    };

    const renderSelectCurrentPlayerHighlight = () => {
        return (
            possibleCurrentPlayerHighlights.length > 1 && (
                <div className="selectCurrentPlayerHighlight">
                    <h3>{t('sharkSettings.selectCurrentPlayerHighlight.title')}</h3>
                    <div className="currentPlayerHighlights">
                        {possibleCurrentPlayerHighlights.map((currentPlayerHighlight) => {
                            const checked = sharkSettings.currentPlayerHighlight.indexOf(currentPlayerHighlight) !== -1;
                            return (
                                <React.Fragment key={currentPlayerHighlight}>
                                    <LabeledInput
                                        label={t(
                                            `sharkSettings.selectCurrentPlayerHighlight.${currentPlayerHighlight}`,
                                        )}
                                        type="checkbox"
                                        checked={checked}
                                        onChange={changeSettingsArray('currentPlayerHighlight', currentPlayerHighlight)}
                                    />
                                    {currentPlayerHighlight === 'background' && checked && (
                                        <div className="trayBackgrounds">
                                            {trayBackgrounds.map((trayBackground) => (
                                                <div
                                                    key={trayBackground}
                                                    onClick={changeSettings(
                                                        'selectedCurrentPlayerBackground',
                                                        trayBackground,
                                                    )}
                                                    className={
                                                        trayBackground === sharkSettings.selectedCurrentPlayerBackground
                                                            ? 'selected'
                                                            : ''
                                                    }
                                                    style={{ background: trayBackground }}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            )
        );
    };

    const renderSelectGeneralLayout = () => {
        return (
            possibleGeneralLayouts.length > 1 && (
                <div className="selectGeneralLayout">
                    <h3>{t('sharkSettings.selectGeneralLayout.title')}</h3>
                    <div className="currentGeneralLayout">
                        {possibleGeneralLayouts.map((generalLayout) => {
                            const checked = sharkLayout.generalLayout === generalLayout;
                            return (
                                <LabeledInput
                                    key={generalLayout}
                                    label={t(`sharkSettings.selectGeneralLayout.${generalLayout}`)}
                                    type="checkbox"
                                    checked={checked}
                                    onChange={() => {
                                        if (generalLayout === 'v_impaired') {
                                            changeLayout({
                                                handLayout: 'straight',
                                                generalLayout,
                                            })();
                                        } else {
                                            changeLayout({ generalLayout })();
                                        }
                                    }}
                                />
                            );
                        })}
                    </div>
                </div>
            )
        );
    };

    const renderSelectHandLayout = () => {
        return (
            possibleHandLayouts.length > 1 && (
                <div className="selectHandLayout">
                    <h3>{t('sharkSettings.selectHandLayout.title')}</h3>
                    <div className="currentHandLayout">
                        {possibleHandLayouts.map((handLayout) => {
                            const checked = sharkLayout.handLayout === handLayout;
                            const disabled = sharkLayout.generalLayout === 'v_impaired' && handLayout === 'fan';
                            return (
                                <LabeledInput
                                    key={handLayout}
                                    label={t(`sharkSettings.selectHandLayout.${handLayout}`)}
                                    type="checkbox"
                                    checked={checked}
                                    disabled={disabled}
                                    onChange={changeLayout({ handLayout })}
                                />
                            );
                        })}
                    </div>
                </div>
            )
        );
    };

    const renderSelectInteractionMode = (interactionKey: keyof AppState['sharkSettings']) => {
        return (
            possibleInteractions.length > 1 && (
                <div className="selectPossibleInteractions">
                    <h3>{t(`sharkSettings.selectPossibleInteractions.${interactionKey}.title`)}</h3>
                    <div className="possibleInteractions">
                        {possibleInteractions.map((possibleInteraction, index) => {
                            return (
                                <LabeledInput
                                    key={possibleInteraction}
                                    label={t(
                                        `sharkSettings.selectPossibleInteractions.${interactionKey}.${possibleInteraction}`,
                                    )}
                                    type="checkbox"
                                    checked={sharkSettings[interactionKey] === possibleInteraction}
                                    onChange={changeSettings(interactionKey, possibleInteraction)}
                                />
                            );
                        })}
                    </div>
                </div>
            )
        );
    };

    const renderSelectSuitOrder = () => {
        return (
            possibleSuitOrders.length > 1 && (
                <div className="selectSuitOrder">
                    <h3>{t('sharkSettings.selectSuitOrder.title')}</h3>
                    {possibleSuitOrders.map((possibleSuitOrder) => (
                        <label key={possibleSuitOrder}>
                            <input
                                type="radio"
                                checked={possibleSuitOrder === suitOrder}
                                onChange={changeLayout({ suitOrder: possibleSuitOrder })}
                            />
                            <span className="label">{t(`sharkSettings.selectSuitOrder.${possibleSuitOrder}`)}</span>(
                            {renderSuits(possibleSuitOrder)})
                        </label>
                    ))}
                </div>
            )
        );
    };

    const renderSelectSounds = () => {
        const { sounds } = sharkSettings;
        return (
            <div className="selectSounds">
                <h3>{t('sharkSettings.selectSounds.title')}</h3>
                {soundTypes.map((soundType, index) => (
                    <LabeledInput
                        key={soundType}
                        label={t(`sharkSettings.selectSounds.soundType.${soundType}`)}
                        type="checkbox"
                        checked={sounds[soundType]}
                        onChange={changeSettings('sounds', {
                            ...sounds,
                            [soundType]: !sounds[soundType],
                        })}
                    />

                    // <label key={`soundTypes-${index}`}>
                    //   <input
                    //     type="checkbox"
                    //     checked={sounds[soundType]}
                    //     onChange={changeSettings('sounds', {
                    //       ...sounds,
                    //       [soundType]: !sounds[soundType]
                    //     })}
                    //   />
                    //   <span className="label">{t(`sharkSettings.selectSounds.soundType.${soundType}`)}</span>
                    // </label>
                ))}
            </div>
        );
    };

    const renderSelectSuitColors = () => {
        return (
            possibleSuitColorSets.length > 1 && (
                <div className="selectSuitColors">
                    <h3>{t('sharkSettings.selectSuitColors.title')}</h3>
                    {possibleSuitColorSets.map((possibleSuitColorSet, index) => (
                        <label key={`possibleSuitColorSet-${index}`} style={{ ...getCssVarsForSuitColorSet(index) }}>
                            <input
                                type="radio"
                                checked={index === colorSets.suits}
                                onChange={changeLayout({
                                    colorSets: {
                                        ...colorSets,
                                        suits: index,
                                    },
                                })}
                            />
                            {renderSuits(suitOrder || SuitOrder.SHCD)}
                        </label>
                    ))}
                </div>
            )
        );
    };

    const renderSelectTableBackgrounds = () => {
        const tableBackgroundKeys: (keyof AppState['sharkSettings']['tableBackgrounds'])[] = [
            'default',
            'blockedByTeacher',
        ];

        return (
            possibleCurrentPlayerHighlights.length > 1 && (
                <div className="selectTableBackgrounds">
                    <h3>{t('sharkSettings.selectTableBackgrounds.title')}</h3>
                    {tableBackgroundKeys.map((tableBackgroundKey) => (
                        <div key={tableBackgroundKey}>
                            <h4>{t(`sharkSettings.selectTableBackgrounds.${tableBackgroundKey}`)}</h4>
                            <div className="tableBackgrounds">
                                {tableBackgrounds.map((tableBackground) => (
                                    <div
                                        key={tableBackground}
                                        onClick={changeSettings('tableBackgrounds', {
                                            ...sharkSettings.tableBackgrounds,
                                            [tableBackgroundKey]: tableBackground,
                                        })}
                                        className={
                                            tableBackground === sharkSettings.tableBackgrounds[tableBackgroundKey]
                                                ? 'selected'
                                                : ''
                                        }
                                        style={{ backgroundColor: tableBackground }}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )
        );
    };

    const renderShowMock = () => {
        return (
            <div className="showMock">
                <h3>Local Helper</h3>
                <div>
                    <LabeledInput
                        label="Mock Buttons"
                        type="checkbox"
                        checked={sharkSettings.showMock}
                        onChange={changeSettings('showMock', !sharkSettings.showMock)}
                    />
                </div>
                <div>
                    <LabeledInput
                        label="Table Slot Borders"
                        type="checkbox"
                        checked={sharkSettings.showTableSlotBorders}
                        onChange={changeSettings('showTableSlotBorders', !sharkSettings.showTableSlotBorders)}
                    />
                </div>
            </div>
        );
    };

    const renderToggleAnimations = () => {
        return (
            <div className="toggleAnimations">
                <h3>{t('sharkSettings.toggleAnimations.title')}</h3>
                <LabeledInput
                    label={t(`sharkSettings.toggleAnimations.${sharkSettings.animations ? 'on' : 'off'}`)}
                    type="checkbox"
                    checked={sharkSettings.animations}
                    onChange={changeSettings('animations', !sharkSettings.animations)}
                />
            </div>
        );
    };

    const renderVideoSettings = () => {
        return (
            <div className="videoSettings">
                <h3>{t('sharkSettings.videoSettings.title')}</h3>
                <div>
                    <LabeledInput
                        label={t(`sharkSettings.videoSettings.show`)}
                        type="checkbox"
                        checked={sharkSettings.showVideo}
                        onChange={changeSettings('showVideo', !sharkSettings.showVideo)}
                    />
                </div>
                <div>
                    <LabeledInput
                        label={t(`sharkSettings.8x8audioSettings.show`)}
                        type="checkbox"
                        checked={sharkSettings.silent8x8}
                        onChange={changeSettings('silent8x8', !sharkSettings.silent8x8)}
                    />
                </div>
            </div>
        );
    };

    return (
        <section className="SharkSettingsComponent">
            <div className="sharkSettings">
                <div className="buttons">
                    {user && (
                        <button className="logout" onClick={handleLogout}>
                            {t('tray.logout')}
                        </button>
                    )}
                    <button className="hideSettings" onClick={() => dispatch(app_setShowSettings(false))}>
                        {t('sharkSettings.close')}
                    </button>
                </div>
                {renderSelectInteractionMode('interactionBid')}
                {renderSelectInteractionMode('interactionCard')}
                {/*{renderSelectGeneralLayout()}*/}
                {renderSelectHandLayout()}
                {renderSelectSounds()}

                <div>
                    {renderSelectSuitColors()}
                    {renderSelectSuitOrder()}
                </div>
                {renderSelectCardBack()}
                {/*{renderSelectButtons()}*/}
                {renderSelectTableBackgrounds()}
                {renderSelectCurrentPlayerHighlight()}
                {renderToggleAnimations()}
                {/*{renderVideoSettings()}*/}
                {/*{window.location.hostname === 'localhost' && renderShowMock()}*/}
            </div>
        </section>
    );
};

export default SharkSettingsComponent;
