/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Auction,
    Bid,
    BonusIndicator,
    BridgePosition,
    Card,
    CardPosition,
    ClickEventData,
    PossibleButtonId,
    SeatData,
    SeatPosition,
    SharkGameResultsV2,
    Trick,
} from '../app/types';
import { AuxiliaryMessage, Button, ButtonKey, TableState, tableActions } from '../slices/tableSlice';
import { CMD, SFSVAR, SFS_EXT, SFS_HAND_STATE, SFS_NAV, getSFSTrue } from './sfsVar';
import { CardUpdateProps, cardActions } from '../slices/cardsSlice';
import { ExtensionRequest, LoginRequest, SFSArray, SFSEvent, SFSObject, SFSRoom, SFSUser, SmartFox } from 'sfs2x-api';
import { RootState } from '../app/store';
import { SFSConnectionStatus, appActions } from '../slices/appSlice';
import { SFS_HAND_PROPS } from '../utils/mixed';
import {
    SeatPosFor,
    convertIntToCall,
    convertPlayerIdToIntBridgePosition,
    fixColor,
    playerIdToBridgePosition,
    strToCall,
    strToCardId,
    strToSize,
} from './game-engine-helper';
import { cardSetFromString, cardsToHands, getSFSHandState, seatDataFromSFSObject } from './handState';
import { connect } from 'react-redux';
import { emptyTrick, numBridgePos } from '../app/defaults';
import { getPartnerBridgePosition, getSocketErrorSFS, getSocketErrorlogin, simplePopUp } from '../utils/shark-helper';
import { modalCencede, modalMakeClaim } from '../utils/modal.helper';
import { outputActions } from '../slices/outputSlice';
import { sfsService } from './sfs-service';
import { undoButton } from './buttonsState';
import React from 'react';

export type GameEngineInterfaceProps = StateProps &
    DispatchProps & {
        gameResults?: string;
        pn?: string;
    };

type ComponentState = {
    initialized: boolean;
};

export type ProcessorFunction = (cmdObj: SFSObject, sender: GameEngineInterface) => void;

let uniqueIdCounter = 0;

function generateUniqueId(): number {
    return uniqueIdCounter++;
}

export type Explanation = { call?: object; hand?: object };

export class GameEngineInterface extends React.Component<GameEngineInterfaceProps, ComponentState> {
    protected config = {
        host: 'sfs03.emabridge.com',
        port: 8443,
        useSSL: true,
        zone: 'BetterBridgeClub',
    };
    protected initialized = false;

    //public sfs?: SmartFox;
    public indexButtons: TableState['indexButtons'] = [];

    protected favurl?: string = undefined;

    public forceShowAllCards = false;

    uniqueId: number;
    protected messageEvent: OmitThisParameter<(event: MessageEvent) => void>;

    constructor(props: GameEngineInterfaceProps) {
        super(props);
        this.uniqueId = generateUniqueId();
        this.messageEvent = this.handleMessageEvent.bind(this);
        console.log('Game Engine Constructor', this.uniqueId, this.props.app.engineState);
    }

    public init() {
        this.props.app_setEngineState({
            initialized: true,
        });
        if (this.props.app.engineState.sfsConnectionStatus === SFSConnectionStatus.ignore) {
            this.gameResultInit();
        } else {
            this.normalInit();
        }
    }

    private gameResultInit() {
        if (this.props.app.urlParams?.['gameResults']) {
            const resultUrl = `https://duplicate-games.s3.us-east-2.amazonaws.com/${this.props.app.urlParams?.['gameResults']}/game.json`;
            fetch(resultUrl)
                .then((response) => {
                    return response.json();
                })
                .then((responseJson) => {
                    this.props.table_updateTable({
                        sharkGameResultsV2: responseJson as SharkGameResultsV2,
                    });
                })
                .catch((error) => {
                    console.error(`S3 bucket failed Error:  ${resultUrl}`, error);
                });
        }
    }

    private normalInit() {
        this.configureSFS();
        sfsService.init(this.config); // = new SmartFox(this.config);
        this.addSFSListeners();
        //Establish Connection
        sfsService.connect();
    }

    // public state: GameEngineInterfaceState = {
    //     currentPlayer: undefined,
    //     farewellMessage: undefined, // 'This was the last hand in this set!'
    //     isMiniBridge: false,
    //     lag: 10,
    //     lobbyRoom: undefined,
    //     playerID: 0,
    //     competeMode: false,
    //     boardData: {},
    //     hasNext: false,
    // };

    componentDidMount() {
        window.addEventListener('message', this.messageEvent);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.messageEvent);
    }

    protected handleMessageEvent(event: MessageEvent) {
        // IMPORTANT: check the origin of the data!
        //console.log("Received Message, ", event);
        if (event.data === 'closefav') {
            // console.log('Received Message, ', event);
            this.props.table_setWalkThroughIframe(undefined);
        }
    }

    //  protected sfs: any = undefined;
    public componentDidUpdate(
        prevProps: Readonly<GameEngineInterfaceProps>,
        prevState: Readonly<ComponentState>,
        snapshot?: any,
    ): void {
        // states
        const { app, output } = this.props;
        // output actions
        const { output_reset } = this.props;

        //      const { user, token, uuid, requestLogin, requestLogout, connection } = app;
        if (this.props.app.engineState && !this.props.app.engineState.initialized) {
            this.init();
        } else if (
            prevProps.app.settings.speedCards !== app.settings.speedCards ||
            prevProps.app.settings.requiredNext !== app.settings.requiredNext ||
            prevProps.app.settings.speedScreens !== app.settings.speedScreens
        ) {
            // do something
            this.clientRequestsSettingsUpdate();
            output_reset();
        } else if (!prevProps.output.button && output.button) {
            const button_id = output.button.id;
            output_reset();
            switch (button_id) {
                case 'next':
                    this.clientRequestsNextPoint();
                    break;
                case 'nexthand':
                    this.clientRequestsNextHand();
                    break;
                case 'prevhand':
                    this.clientRequestsPrevHand();
                    break;
                case 'back':
                    this.clientRequestsPreviousPoint();
                    break;
                case 'playDeal':
                    this.clientRequestsNextPoint();
                    break;
                case 'practice':
                    this.clientRequestsTraining();
                    break;
                case 'autoBid':
                    this.clientRequestsAutoBid();
                    break;
                case 'answer':
                    this.clientRequestsAnswer();
                    break;
                case 'undo':
                    this.clientRequestsUndo();
                    break;
                case 'replay':
                    this.clientRequestsReplay();
                    break;
                case 'skip':
                    this.clientRequestsSkip();
                    break;
                case 'favorite':
                    this.clientRequestsFav('Some notes');
                    break;
                case 'claim': {
                    const modal = modalMakeClaim(
                        this.props.table.ewtricks + this.props.table.nstricks,
                        this.props.output_setMakeClaim,
                    );
                    this.props.app_addModal(modal);
                    // this.clientRequestsClaim(0);
                    break;
                }
                case 'concede': {
                    const modal = modalCencede(
                        this.props.table.ewtricks + this.props.table.nstricks,
                        this.props.output_setMakeClaim,
                    );
                    this.props.app_addModal(modal);
                    // this.clientRequestsClaim(0);
                    break;
                }
                case 'acceptClaim':
                    this.clientRequestsAcceptClaim();
                    break;
                case 'auxiliaryCancel':
                    try {
                        this.props.table_setAuxiliaryMessage({
                            position: Array.isArray(output.button.value) ? output.button.value[1] as keyof TableState['auxiliaryMessages']
                                                                         : output.button.value as keyof TableState['auxiliaryMessages'],
                            message: undefined,
                        });
                        this.props.table_setAuxiliaryMessage({ position: 'center', message: undefined });
                    } catch (e) {
                        //
                    }
                    // this.clientRequestsAcceptClaim();
                    break;
                case 'auxiliaryOk':
                    if (Array.isArray(output.button.value)){
                        this.props.table_setAuxiliaryMessage({
                            position: output.button.value[1] as keyof TableState['auxiliaryMessages'],
                            message: undefined,
                        });
                        this.clientRequestsMakeCall(output.button.value[0] as Bid);
                    }else {
                        this.clientRequestsNextPoint();
                    }
                    break;
                case 'auxiliaryAuxiliary':
                    this.clientRequestsAcceptClaim();
                    break;
                case 'dashboard':
                    parent.postMessage('reloaddash', '*');
                    break;
                case 'closeTrick': {
                    this.clientRequestsCloseTrick();
                    break;
                }
                case 'index': {
                    this.toggleIndexMenu();
                    break;
                }
                case 'indexButton': {
                    this.clientRequestsTopicAtIndex(output.button.value as number);
                    break;
                }
                case 'showAll': {
                    this.clientRequestToggleVisibility();
                    break;
                }
                case 'showSharkSettings': {
                    this.props.app_setShowSettings(true);
                    break;
                }

                default:
                    console.warn('pressed button is unhandled');
            }
            return;
        }

        //HTML Component click, catch clicked target and deal with it.
        if (!prevProps.output.clickEventData && output.clickEventData) {
            this.clientRequestsHTMLLink(output.clickEventData);
            output_reset();
            return;
        }

        // MAKE CALL
        if (prevProps.output.bid !== output.bid && output.bid) {
            output_reset();
            if (this.props.app.settings.bidLayConfirm){
                const m: AuxiliaryMessage = {
                    text: `<div id="${output.bid.call}" class="bid" style="background-image: url(/calls/${output.bid.call}.png);"></div>`,
                    title: '',
                    titleColor: '',
                    buttonOk: 'ok',
                    buttonCancel: 'cancel',
                    buttonAuxiliary: '',
                    bottom: true,
                    value: output.bid
                };
                this.props.table_setAuxiliaryMessage({ position: 'right', message: m });
            } else {
                this.clientRequestsMakeCall(output.bid);
            }


        } else if (output.bidExp && prevProps.output.bidExp !== output.bidExp) {
            this.clientRequestsExplCall(output.bidExp);
            output_reset();
        }
        //MAKE PLAY
        else if (prevProps.output.card !== output.card && output.card) {
            this.clientRequestsMakePlay(output.card);
            output_reset();
        }
        //
        else if (!prevProps.output.loadBoardReview && output.loadBoardReview) {
            //   console.log('Action Request', output.loadBoardReview);
            this.loadBoardReview(
                output.loadBoardReview.game,
                output.loadBoardReview.pair,
                output.loadBoardReview.bn,
                output.loadBoardReview.comparisonUuid,
            );
            output_reset();
        }

        // DEAL CARD
        // if (prevProps.output.dealCard !== output.dealCard && output.dealCard) {
        //     this.clientRequestsDealCard(output.dealCard);
        // }

        // Send Chat Messsage
        // if (prevProps.output.chatEntry !== output.chatEntry && output.chatEntry) {
        //     this.sendChatMessage(output.chatEntry);
        // }

        // Request Director
        // if (prevProps.output.director !== output.director && output.director != null) {
        //     //  debugger;
        //     this.clientRequestsDirector(output.director);
        // }

        //Request Claim
        else if (prevProps.output.claim !== output.claim && output.claim != null) {
            //  debugger;
            this.props.app_clearModals();
            this.clientRequestsClaim(output.claim);
            output_reset();
        }

        // // Respond with YES Undo
        // if (prevProps.output.approveUndo !== output.approveUndo && output.approveUndo) {
        //     if (output.approveUndo !== undefined) {
        //         this.clientResponseUndo(output.approveUndo);
        //     }
        //     output_reset();
        // }

        // Respond with YES Undo
        else if (prevProps.output.approveClaim !== output.approveClaim && output.approveClaim) {
            this.clientResponseClaim(output.approveClaim);
            output_reset();
        }

        // SET ACTIVE SEAT
        // if (prevState.currentPlayerOnServer !== currentPlayerOnServer) {
        //     this.props.setIsHighlighted(convertIntToBridgePosition(currentPlayerOnServer), true);
        //     if (
        //         this.sfs?.mySelf.getPlayerId(this.sfs?.lastJoinedRoom) <= 0 &&
        //         visibility !== 31 &&
        //         this.sfs?.mySelf.containsVariable('kbd')
        //     ) {
        //         const seatid: number = this.sfs?.mySelf.getVariable('kbd').value;
        //         if (seatid === 4) {
        //             this.props.setMyBridgePosition(convertIntToBridgePosition(currentPlayerOnServer));
        //         }
        //     }
        // }
        //output_reset();
    }

    public render(): null {
        return null;
    }

    protected configureSFS() {
        // console.log('Configuring Default SFS Connection', this);
    }

    protected addSFSListeners() {
        // Add sfsService Event Listeners
        sfsService.addEventListener(SFSEvent.SOCKET_ERROR, this.onSocketError, this);
        sfsService.addEventListener(SFSEvent.CONNECTION, this.onConnection, this);
        sfsService.addEventListener(SFSEvent.CONNECTION_LOST, this.onConnectionLost, this);
        sfsService.addEventListener(SFSEvent.ROOM_JOIN, this.onRoomJoined, this);
        sfsService.addEventListener(SFSEvent.ROOM_JOIN_ERROR, this.onRoomJoinError, this);
        sfsService.addEventListener(SFSEvent.ROOM_VARIABLES_UPDATE, this.onRoomVarsUpdate, this);
        sfsService.addEventListener(SFSEvent.USER_ENTER_ROOM, this.onUserEnterRoom, this);
        sfsService.addEventListener(SFSEvent.USER_EXIT_ROOM, this.onUserExitRoom, this);
        sfsService.addEventListener(SFSEvent.USER_VARIABLES_UPDATE, this.onUserVarsUpdate, this);
        sfsService.addEventListener(SFSEvent.SPECTATOR_TO_PLAYER, this.onSpectatorToPlayerSwitch, this);
        sfsService.addEventListener(SFSEvent.SPECTATOR_TO_PLAYER_ERROR, this.onSpectatorToPlayerSwitchError, this);
        sfsService.addEventListener(SFSEvent.PLAYER_TO_SPECTATOR, this.onPlayerToSpectatorSwitch, this);
        sfsService.addEventListener(SFSEvent.PLAYER_TO_SPECTATOR_ERROR, this.onPlayerToSpectatorSwitchError, this);
        sfsService.addEventListener(SFSEvent.EXTENSION_RESPONSE, this.onExtensionResponse, this);
        sfsService.addEventListener(SFSEvent.OBJECT_MESSAGE, this.onObjectMessage, this);
        sfsService.addEventListener(SFSEvent.ADMIN_MESSAGE, this.onAdminMessage, this);
        sfsService.addEventListener(SFSEvent.MODERATOR_MESSAGE, this.onModeratorMessage, this);
        sfsService.addEventListener(SFSEvent.PUBLIC_MESSAGE, this.onPublicMessage, this);
        sfsService.addEventListener(SFSEvent.PRIVATE_MESSAGE, this.onPrivateMessage, this);
        sfsService.addEventListener(SFSEvent.PING_PONG, this.onPING_PONG, this);
        sfsService.addEventListener(SFSEvent.LOGOUT, this.onLogout, this);

        // Add Event Listeners
        // this.sfs?.addEventListener(SFSEvent.SOCKET_ERROR, this.onSocketError, this);
        // this.sfs?.addEventListener(SFSEvent.CONNECTION, this.onConnection, this);
        // this.sfs?.addEventListener(SFSEvent.CONNECTION_LOST, this.onConnectionLost, this);
        // this.sfs?.addEventListener(SFSEvent.ROOM_JOIN, this.onRoomJoined, this);
        // this.sfs?.addEventListener(SFSEvent.ROOM_JOIN_ERROR, this.onRoomJoinError, this);
        // this.sfs?.addEventListener(SFSEvent.ROOM_VARIABLES_UPDATE, this.onRoomVarsUpdate, this);
        // this.sfs?.addEventListener(SFSEvent.USER_ENTER_ROOM, this.onUserEnterRoom, this);
        // this.sfs?.addEventListener(SFSEvent.USER_EXIT_ROOM, this.onUserExitRoom, this);
        // this.sfs?.addEventListener(SFSEvent.USER_VARIABLES_UPDATE, this.onUserVarsUpdate, this);
        // this.sfs?.addEventListener(SFSEvent.SPECTATOR_TO_PLAYER, this.onSpectatorToPlayerSwitch, this);
        // this.sfs?.addEventListener(SFSEvent.SPECTATOR_TO_PLAYER_ERROR, this.onSpectatorToPlayerSwitchError, this);
        // this.sfs?.addEventListener(SFSEvent.PLAYER_TO_SPECTATOR, this.onPlayerToSpectatorSwitch, this);
        // this.sfs?.addEventListener(SFSEvent.PLAYER_TO_SPECTATOR_ERROR, this.onPlayerToSpectatorSwitchError, this);
        // this.sfs?.addEventListener(SFSEvent.EXTENSION_RESPONSE, this.onExtensionResponse, this);
        // this.sfs?.addEventListener(SFSEvent.OBJECT_MESSAGE, this.onObjectMessage, this);
        // this.sfs?.addEventListener(SFSEvent.ADMIN_MESSAGE, this.onAdminMessage, this);
        // this.sfs?.addEventListener(SFSEvent.MODERATOR_MESSAGE, this.onModeratorMessage, this);
        // this.sfs?.addEventListener(SFSEvent.PUBLIC_MESSAGE, this.onPublicMessage, this);
        // this.sfs?.addEventListener(SFSEvent.PRIVATE_MESSAGE, this.onPrivateMessage, this);
        // this.sfs?.addEventListener(SFSEvent.PING_PONG, this.onPING_PONG, this);
        // this.sfs?.addEventListener(SFSEvent.LOGOUT, this.onLogout, this);
    }

    public onConnection = (evtParams: { success: boolean }) => {
        if (evtParams.success) {
            console.log('Connected to SmartFoxServer 2X!', evtParams, this.uniqueId);
            this.clientRequestsLogin();
        } else {
            console.log('Connection failed. Is the server running at all?', evtParams);
        }
    };

    protected onSocketError = ({ errorMessage }: { errorMessage: string }) => {
        console.log('onSocketError: ', errorMessage);
        parent.postMessage('reloaddash', '*');
        if (sfsService.sfs === null || sfsService.sfs?.isConnected) {
            return;
        }
        const { app } = this.props;
        // this.props.setJitsi({});
        // TODO: Michael : show Modal, we should have pop up message with a possibility of some buttons.
        // this.props.addModal(
        //     getSocketErrorConfig({
        //         cancel: () => {
        //             window.location.href =
        //                 app.urlParams['sfshost'].toLowerCase() === 'local'
        //                     ? 'https://local.onlinebridge.club/index.php'
        //                     : 'https://thesharkbridgecompany.com/blogs/students-manual/how-to-practice-with-robots-or-fellow-students-with-shark-bridge-app';
        //         },
        //         retry: () => {
        //             this.sfs = undefined;
        //             this.logout();
        //         },
        //     }),
        // );
        this.props.app_setEngineState({
            sfsConnectionStatus: SFSConnectionStatus.disconnected,
        });
        this.props.app_addModal(
            getSocketErrorSFS({
                cancel: () => {
                    parent.postMessage('reloaddash', '*');
                },
                retry: () => {
                    sfsService.connect();
                },
            }),
        );
    };

    protected onConnectionLost = ({ reason }: { reason: string }) => {
        console.log('onConnectionLost', reason, this.props.app.engineState);
        const { app } = this.props;
        this.props.app_setEngineState({
            sfsConnectionStatus: SFSConnectionStatus.disconnected,
        });
        this.props.app_addModal(
            getSocketErrorSFS({
                cancel: () => {
                    parent.postMessage('reloaddash', '*');
                },
                retry: () => {
                    sfsService.connect();
                },
            }),
        );
    };

    protected clientRequestsLogin = () => {
        console.log('Login to SmartFoxServer 2X!', this.uniqueId);
        if (sfsService.sfs?.mySelf != null) {
            return;
        }

        this.props.app_setEngineState({
            sfsConnectionStatus: SFSConnectionStatus.registering,
        });

        //const call: Call = strToCall("7c");
        //this.props.table_setAuction(mockedAuction);

        const sfsObj = new SFSObject();
        sfsObj.putInt(SFSVAR.PROTOCOL_VERSION, 50);
        sfsObj.putUtfString(SFSVAR.INTERFACE, 'betterb');
        sfsObj.putUtfString('jwe', this.props.app.urlParams?.['jwe'] ?? '');
        sfsObj.putUtfString('stuid', this.props.app.uuid || '');

        sfsService.sfs?.addEventListener(SFSEvent.LOGIN, this.onLogin, this);
        sfsService.sfs?.addEventListener(SFSEvent.LOGIN_ERROR, this.onLoginError, this);
        const loginRequest = new LoginRequest(
            this.props.app.uuid || '',
            this.props.app.appToken,
            sfsObj,
            this.config.zone,
        );
        sfsService.sfs?.send(loginRequest);
    };

    // protected clientRequestsLogout = () => {
    //     if (this.consoleOut) {
    //         console.log('Logout requested');
    //     }
    //     this.props.app_setEngineState({
    //         sfsConnectionStatus: SFSConnectionStatus.disconnected,
    //     });
    //     this.sfs?.send(new LogoutRequest());
    // };

    protected onLogin = ({ user, data }: { user: SFSUser; data: SFSObject }) => {
        this.props.app_setIsLoggedIn(true);
        const monitor_interval: number = data.getInt(SFSVAR.SFS_LAG_MONITOR);
        this.props.app_setEngineState({
            sfsConnectionStatus: SFSConnectionStatus.connected,
        });
        this.props.app_setPID(user.name);
    };

    protected onLoginError = ({ errorMessage, errorCode }: { errorMessage: string; errorCode: number }) => {
        this.props.app_addModal(
            getSocketErrorlogin(() => {
                parent.postMessage('reloaddash', '*');
            }),
        );
        return;
    };

    protected onLogout = () => {
        this.props.app_setEngineState({
            sfsConnectionStatus: SFSConnectionStatus.disconnected,
        });
        sfsService.sfs?.disconnect();
        //  this.sfs = undefined;
        // TODO: Milen Unclear what would be the logic here
        this.props.app_reset();
        parent.postMessage('reloaddash', '*');
        // this.props.game_reset();
    };

    protected onRoomJoined = ({ room }: { room: SFSRoom }) => {
        //console.log('Room joined successfully: ',room);
        const { app } = this.props;
        const playerID: number = sfsService.sfs?.mySelf.getPlayerId(room) ?? 0;
        switch (room.isGame) {
            case false: {
                this.props.app_updateEngineInterfaceState({
                    lobbyRoom: room,
                });
                break;
            }
            case true: {
                //        this.props.table_setTopBridgePosition(environment === 'audrey' ? BridgePosition.north : swapPlayerIdToBridgePosition(playerID ?? 4) ?? BridgePosition.north);
                this.props.app_updateEngineInterfaceState({
                    isMiniBridge: room.containsVariable(SFSVAR.SFSGAME_MINIBRIDGE)
                        ? (room.getVariable(SFSVAR.SFSGAME_MINIBRIDGE).value as boolean)
                        : false,
                    playerID: playerID,
                    defaultBridgePosition: playerIdToBridgePosition(playerID),
                });
                this.sendRoomCommand(SFSVAR.CMD_INTERFACE_READY, new SFSObject());
                break;
            }
        }
    };

    protected onRoomJoinError = ({ errorMessage, errorCode }: { errorMessage: string; errorCode: number }) => {
        // console.log('Room joining failed: ' + errorMessage);
        //TODO: Close iFrame or simply provide go to URL parameter.
    };

    protected onUserEnterRoom = ({ room, user }: { room: SFSRoom; user: SFSUser }) => {
        //console.log('onUserEnterRoom: ', room , user);
    };

    //IMPORTANT: if the user that left is ME, then move out of table to lobby, for now probably just a simple note or somethign.
    /**/
    protected onUserExitRoom = ({ room, user }: { room: SFSRoom; user: SFSUser }) => {
        //console.log('onUserExitRoom: ', room , user);
    };

    //This is like the onRoomJoin, but the player was actually kibitzing an is now seated at the table
    protected onSpectatorToPlayerSwitch = ({
        room,
        user,
        playerID,
    }: {
        room: SFSRoom;
        user: SFSUser;
        playerID: number;
    }) => {
        //console.log('onSpectatorToPlayerSwitch: ', room , user, playerID);
        this.props.app_updateEngineInterfaceState({
            playerID: playerID,
        });
    };

    protected onSpectatorToPlayerSwitchError = ({
        errorMessage,
        errorCode,
    }: {
        errorMessage: string;
        errorCode: number;
    }) => {
        //console.log('onSpectatorToPlayerSwitchError: ', errorMessage , errorCode);
    };

    //Unseat the player, it turns in Kibitz. The important action here is to adjust hand visibility based on current rules for kibitzers
    protected onPlayerToSpectatorSwitch = ({ room, user }: { room: SFSRoom; user: SFSUser }) => {
        console.log('onPlayerToSpectatorSwitch: ', room, user.getPlayerId(room));
        this.props.app_updateEngineInterfaceState({
            playerID: 0,
        });
    };

    protected onPlayerToSpectatorSwitchError = ({
        errorMessage,
        errorCode,
    }: {
        errorMessage: string;
        errorCode: number;
    }) => {
        //console.log('onPlayerToSpectatorSwitchError: ', errorMessage, errorCode);
    };

    protected onRoomVarsUpdate = ({ room, changedVars }: { room: SFSRoom; changedVars: string[] }) => {
        // console.log(`Room Var Update: `, changedVars);
        if (changedVars.includes(SFSVAR.SFSGAME_ROOM_UTC_ENDTTIME)) {
            this.props.table_updateTable({
                timeToGameStart: undefined,
                timeToGameEnd: undefined,
            });

            const endTime: number = sfsService.getRoomNumber(room, SFSVAR.SFSGAME_ROOM_UTC_ENDTTIME, undefined);
            if (endTime) {
                this.props.app_setSoundPlaying('tournamentStart');
                this.props.table_updateTable({
                    timeToGameStart: undefined,
                    timeToGameEnd: endTime - Math.floor(Date.now() / 1000),
                });
            }

            if (room.containsVariable(SFSVAR.SFSGAME_ROOM_UTC_STRTIME)) {
                this.props.table_updateTable({
                    timeToGameStart: sfsService.getPlayerNumber(SFSVAR.SFSGAME_ROOM_STT, undefined),
                    timeToGameEnd: sfsService.getPlayerNumber(SFSVAR.SFSGAME_ROOM_STT, undefined),
                });
            }
        }

        if (room.containsVariable(SFSVAR.SFSGAME_NOAUCTION) && changedVars.includes(SFSVAR.SFSGAME_NOAUCTION)) {
            //this.props.showAuctionBox(!room.getVariable(SFSVAR.SFSGAME_NOAUCTION).value);
        }

        if (room.containsVariable(SFSVAR.SFSGAME_NOUNDO) && changedVars.includes(SFSVAR.SFSGAME_NOUNDO)) {
            this.hideButtons([undoButton]);
        }

        if (room.containsVariable(SFSVAR.SFSGAME_NOLASTTRICK) && changedVars.includes(SFSVAR.SFSGAME_NOLASTTRICK)) {
            //this.props.showClosedTricks(!room.getVariable(SFSVAR.SFSGAME_NOLASTTRICK).value);
        }

        if (room.containsVariable(SFSVAR.SFSGAME_ROOM_DISPLAY) && changedVars.includes(SFSVAR.SFSGAME_ROOM_DISPLAY)) {
            //this.props.setTableId(' ' + room.getVariable(SFSVAR.SFSGAME_ROOM_DISPLAY).value + ' ');
        }

        if (changedVars.includes(SFSVAR.SFSGAME_VIDEO_CLIP)) {
            //TODO: Show Video Screen this could be useful to load some video play like Vimeo or You tube
        }

        if (room.isGame && changedVars.includes(SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS_V2)) {
            try {
                const resultV = room.getVariable(SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS_V2).value as SFSObject;
                const json = new TextDecoder().decode(resultV.getByteArray('data'));
                try {
                    const game_results = JSON.parse(json) as SharkGameResultsV2;
                    this.props.table_updateTable({ sharkGameResultsV2: game_results });
                } catch (e) {
                    console.error('could not parse ', json);
                }
         //       const json = new TextDecoder('utf-8').decode(resultV.getByteArray('data'));
         //       const game_results = JSON.parse(json) as SharkGameResultsV2;
            } catch (e) {
                console.error('could not parse ', e);
            }
        }
    };

    protected onUserVarsUpdate = ({ user, changedVars }: { user: SFSUser; changedVars: string[] }) => {
        if (user.isItMe) {
            return;
        }
    };

    protected onPING_PONG = ({ lagValue }: { lagValue: number }) => {
        //console.log(`PING PONG: ${lagValue}`);
        if (!sfsService.sfs?.isConnected) {
            return;
        }
        //const diff: number = this.props.app.engineState.interface.lag - lagValue;
        //console.log(`PING PONG DIFF: ${diff}`);
        //this.sfs?.send(new ExtensionRequest(SFSVAR.EXTENSION_LAG));
    };

    protected onExtensionResponse = ({ cmd, params }: { cmd: string; params: SFSObject }) => {
        //console.log('Object Command: ' + cmd + ' object : ' + params.getDump());
        if (cmd === CMD.CMD_GAME_LOGIC) {
            // We expect atleast one INT value in the params: EXTENSION_MID with values one of netMID
            //console.log('The custom Extension is: ' + params.getDump());
            this.processObject(params);
        } else if (cmd === SFSVAR.CMD_CONTROL_ACTION) {
            this.processObject(params);
        } else if (cmd === SFSVAR.SFSGAME_CARD_HIGHLIGHT) {
            //TO Do highlight cards
        } else if (cmd === SFSVAR.SFSGAME_VIDEO_CLIP) {
            //TO DO Show Video Screen
        } else if (cmd === SFSVAR.SFSGAME_FRAME_URL) {
            //TO DO Show frame URL, for play cards IO, or somethign else
        } else if (cmd === SFS_EXT.EXT_COLUMN_INDEX) {
            this.setIndexMenu(params);
            this.clientRequestsSettingsUpdate();
        } else if (cmd === SFSVAR.SFSGAME_EXTENSION_GAME_RESULTS_V2) {
            try {
                type Explanation = Record<string, ArrayBuffer> | undefined;
            //    let game_results: SharkGameResultsV2 = [];
                const json = new TextDecoder().decode(params.getByteArray('data'));
                try {
                    const game_results = JSON.parse(json) as SharkGameResultsV2;
                    this.props.table_setSharkGameResultsV2(game_results);
                } catch (e) {
                    console.error('could not parse ', json);
                }

        //        const json = new TextDecoder('utf-8').decode(params.getByteArray('data'));
        //        const game_results = JSON.parse(json) as SharkGameResultsV2;

            } catch (e) {
                console.error('could not parse ', e);
            }
        } else if (cmd === SFSVAR.CMD_POPUP) {
            simplePopUp(params.getUtfString('oe'), params.getUtfString('o'));
        }
    };

    private process_CMD_POPUP(cmdObj: SFSObject) {
        this.props.app_clearModals();

        //  simplePopUp(cmdObj.getUtfString('oe'), cmdObj.getUtfString('o') );
    }

    //This one can have data, regarding request to unblock a user, we will not deal with it now.
    protected onAdminMessage = ({ message, sender, data }: { message: any; sender: SFSUser; data: SFSObject }) => {
        return;
    };

    protected onModeratorMessage = ({ message, sender }: { message: string; sender: SFSUser }) => {
        return;
    };

    //On a special case where there is data sent back,
    protected onPublicMessage = ({ message, sender, data }: { message: string; sender: SFSUser; data: SFSObject }) => {
        return;
    };

    protected onPrivateMessage = ({ message, sender, data }: { message: string; sender: SFSUser; data: SFSObject }) => {
        return;
    };

    protected onObjectMessage = ({ message, sender }: { message: SFSObject; sender: SFSUser }) => {
        if (message.containsKey(SFSVAR.EXTENSION_MID)) {
            //Pass the same SFS_OBJECT_MESSAGE processor. Server will often send object as a result of game logic , rather than in response
            // to an Extention request.
            // console.log('The object command is: ' + message.getInt(SFSVAR.EXTENSION_MID));
            this.processObject(message);
        }
    };

    protected processObject = (cmdObj: SFSObject) => {
        //
    };

    public setHandsFromState(state: SFSObject, visibleWalkthru: boolean) {
        //console.log('Hands Data: ', state);
        const visibility: number = state.getByte(SFSVAR.SFSGAME_MID_HANDPLAYER_VISIBILITY);

        Object.values(this.props.table.seatData).forEach((value, index) => {
            this.props.table_setSeatData({
                seatPosition: value?.seatPosition ?? SeatPosition.top,
                background: undefined,
            });
            this.setHandFromState(getSFSHandState(value, state, visibility));
        });

        if (state.containsKey(SFS_HAND_PROPS.center)) {
            this.setHandFromState(getSFSHandState(undefined, state, visibility));
            this.props.table_setCenterSeat(true);
        } else {
            this.props.table_setCenterSeat(false);
        }
    }

    public setHandsFromScore(state: SFSObject, visibleWalkthru: boolean, suffix: string) {
        Object.values(this.props.table.seatData).forEach((value: SeatData, index) => {
            this.props.table_setSeatData({
                seatPosition: value?.seatPosition ?? SeatPosition.top,
                isVisible: true,
                isInteractive: false,
                background: undefined,
                dummyCards: 0,
            });
            const key: string | undefined =
                value.bridgePosition && suffix.length === 1
                    ? `${value.bridgePosition[0].toLowerCase()}${suffix}`
                    : value.bridgePosition
                    ? `${value.bridgePosition.toLowerCase()}${suffix}`
                    : undefined;

            if (key && state.containsKey(key)) {
                const hand: string[] | undefined = state.getUtfStringArray(key) as string[];
                if (hand && hand.length > 0 && hand[0].length > 0) {
                   // console.log('Hand form score', value.bridgePosition[0].toLowerCase(), hand);
                    this.props.cards_setCards(cardsToHands(hand, value.bridgePosition));
                }
            }
        });
    }

    public setHandsFromClaim(state: SFSObject, visibleWalkthru: boolean) {
        Object.values(this.props.table.seatData).forEach((value: SeatData, index) => {
            this.props.table_setSeatData({
                seatPosition: value?.seatPosition ?? SeatPosition.top,
                isVisible: true,
                isInteractive: false,
                background: undefined,
                dummyCards: 0,
            });
            const key: string | undefined = value.bridgePosition
                ? `${value.bridgePosition[0].toLowerCase()}` + 'hand'
                : undefined;

            if (key) {
                const hand: string[] | undefined = state.containsKey(key)
                    ? (state.getUtfStringArray(key) as string[])
                    : undefined;
                if (hand && hand.length > 0 && hand[0].length > 0) {
                    this.props.cards_setCards(cardsToHands(hand, value.bridgePosition));
                }
            }
        });
    }

    protected setHandFromState(sfsHandState: SFS_HAND_STATE | undefined) {
        if (sfsHandState === undefined) {
            return;
        }
        //console.log("Hand Highlight : ", state.containsKey('highlight') ? fixColor(state.getUtfString('highlight')) : undefined);
        //console.log("Hand Object : " , sfsHandState.sfsObject.getDump())
        const isdummy: boolean = sfsHandState.sfsObject.getBool('dummy');
        const isvisible: boolean = sfsHandState.sfsObject.getBool('faceup');
        const isactive: boolean = sfsHandState.sfsObject.getBool('isactive');
        const isbottomhand: boolean = sfsHandState.seatPosition === SeatPosition.bottom;
        let handSize: 's' | 'm' | 'l' | 'n' =
            sfsHandState.sfsObject.getUtfString('size') === ''
                ? isbottomhand
                    ? 'l'
                    : 'n'
                : strToSize(sfsHandState.sfsObject.getUtfString('size'));
        const v: boolean = isdummy && !isbottomhand;
        if (v) {
            //   this.props.table_setDummy(sfsHandState.bridgePosition);
        }

        let dynamicTray = false;
        if (isbottomhand && isactive && handSize === 'l') {
            //    dynamicTray = true;
        }
        let sizeModifier = 0;
        if (!this.props.table.centerSeat && this.props.table.walkThrough) {
            //sizeModifier += 0.15;
            if (!isdummy && handSize === 'l') {
                sizeModifier += 0.5;
            }
        } else {
            if (!isdummy && handSize === 'l') {
                sizeModifier += 0.445;
            } else if (handSize === 's') {
                sizeModifier -= 0.15;
            }
        }

        if (isbottomhand && isdummy) {
            sizeModifier += 0.445;
            dynamicTray = true;
            handSize = 'm';
        }

        const stateStr: string = sfsHandState.sfsObject.getUtfString('highlighstr');
        const hand: string[] = sfsHandState.sfsObject.getUtfStringArray('handstr') as string[];
        //console.log("Hand State", sfsHandState);
        this.props.table_setSeatData(
            seatDataFromSFSObject(sfsHandState, sizeModifier, dynamicTray, hand, isdummy, isvisible, handSize),
        );
        if (hand.length > 0 && hand[0].length > 0) {
            this.props.cards_setCards(cardSetFromString(hand, sfsHandState, stateStr));
        }
    }

    public setAuctionFromState(state: SFSObject, dealer: BridgePosition) {
        if (!state.containsKey('auction') && !state.containsKey('bbauction')) {
            return;
        }

        const bridgePositionArray =
            dealer === BridgePosition.north
                ? [BridgePosition.north, BridgePosition.east, BridgePosition.south, BridgePosition.west]
                : dealer === BridgePosition.east
                ? [BridgePosition.east, BridgePosition.south, BridgePosition.west, BridgePosition.north]
                : dealer === BridgePosition.south
                ? [BridgePosition.south, BridgePosition.west, BridgePosition.north, BridgePosition.east]
                : [BridgePosition.west, BridgePosition.north, BridgePosition.east, BridgePosition.south];

        type Explanation = Record<string, ArrayBuffer> | undefined;
        let expl: Explanation = undefined;
        if (state.containsKey('auctionExpl')) {
            const json = new TextDecoder().decode(state.getByteArray('auctionExpl'));
            try {
                expl = JSON.parse(json) as Explanation;
            } catch (e) {
                console.error('could not parse ', json);
            }
        }
        if (state.containsKey('bbauction_123')) {
            const callArray: SFSArray = state.getSFSArray('bbauction');
            const mockedAuction: Auction = [];
            for (let i = 0; i < callArray.size(); i++) {
                const call: SFSObject = callArray.getSFSObject(i);
                mockedAuction.push({
                    bridgePosition: bridgePositionArray[i % bridgePositionArray.length],
                    call: strToCall(call.getUtfString('c')),
                    backgroundColor: call.containsKey('bg') ? fixColor(call.getUtfString('bg')) : undefined,
                    key: i,
                });
                // etc...
            }
            this.props.table_setAuction(mockedAuction);
        } else if (state.containsKey('auction')) {
            try {
                const callArray: number[] = state.getIntArray('auction') as number[];
                const mockedAuction: Auction = [];
                for (let i = 0; i < callArray.length; i++) {
                    const index: number | undefined = expl?.[`${mockedAuction.length}`]
                        ? mockedAuction.length + 1
                        : undefined;
                    const bid: Bid = {
                        bridgePosition: bridgePositionArray[i % bridgePositionArray.length],
                        call: convertIntToCall(callArray[i]),
                        backgroundColor: undefined,
                    };
                    //,
                    if (index) {
                        try {
                            const uint8Array = new Uint8Array(expl[`${index - 1}`]);
                            const json = new TextDecoder().decode(uint8Array.buffer);
                            try {
                                bid.explanation = JSON.parse(json) as Explanation;
                            } catch (e) {
                                console.error('could not parse ', json, e);
                            }
                        } catch (e) {
                            // noop
                            console.error('could not parse ', e);
                        }
                    }
                    mockedAuction.push(bid);
                }
                this.props.table_setAuction(mockedAuction);
            } catch (e) {
                const callArray: SFSArray = state.getSFSArray('auction');
                const mockedAuction: Auction = [];
                for (let i = 0; i < callArray.size(); i++) {
                    const call: SFSObject = callArray.getSFSObject(i);
                    mockedAuction.push({
                        bridgePosition: bridgePositionArray[i % bridgePositionArray.length],
                        call: strToCall(call.getUtfString('c')),
                        backgroundColor: call.containsKey('bg') ? fixColor(call.getUtfString('bg')) : undefined,
                        key: i,
                    });
                    // etc...
                }
                this.props.table_setAuction(mockedAuction);
            }
        } else {
            this.props.table_setAuction([]);
        }
    }

    public setBiddingLadderFromState(state: SFSObject) {
        //console.log("Show Bidding box" , state.getSFSObject('biddingbox'));
        if (!state.containsKey('biddingbox')) {
            this.props.table_updateBiddingLadder({
                isVisible: false,
            });
            return;
        }
        const bl: SFSObject = state.getSFSObject('biddingbox');
        const stake: number = bl.getInt('stake');

        this.props.table_updateBiddingLadder({
            showArrow: bl.getBool('showarrow'),
            firstVisibleCall: strToCall(bl.getUtfString('mc')),
            minimalValidCall: strToCall(bl.getUtfString('mvc')),
            isActive: bl.getBool('active'),
            isVisible: true,
            showBonusIndicator: bl.getBool('bonus'),
            bonusIndicators: [BonusIndicator.j, BonusIndicator.m, BonusIndicator.s, BonusIndicator.g],
            stake:
                stake === 4
                    ? {
                          backgroundColor: undefined,
                          call: 'rdbl',
                          disabled: false,
                          invisible: false,
                      }
                    : {
                          backgroundColor: undefined,
                          call: 'xdbl',
                          disabled: stake !== 2,
                          invisible: false,
                      },
        });

        if (bl.containsKey('hcalls')) {
            const calls: SFSArray = bl.getSFSArray('hcalls');
            //    console.log("Bidding Ladder HCalls : ", calls);
            const callsArray: Auction = [];
            for (let i = 0; i < calls.size(); i++) {
                const call: SFSObject = calls.getSFSObject(i);
                callsArray.push({
                    call: strToCall(call.getUtfString('c')),
                    backgroundColor: call.containsKey('bg') ? fixColor(call.getUtfString('bg')) : undefined,
                    key: i,
                });
            }
            //      console.log("Bidding Ladder Result : ", callsArray);
            this.props.table_updateBiddingLadderBids(callsArray);
        }
    }

    protected setCardsToTrickFromHand(state: SFSObject) {
        //
        const cardUpdates: CardUpdateProps[] = [];
        this.props.table_addTrick(emptyTrick);
        const bridgePositions = Object.values(BridgePosition);
        Object.values(SFS_HAND_PROPS).forEach((key, index) => {
            // 👇️ Small, Medium, Large
            if (index !== 4) {
                //console.log("Card from hand : " , Object.values(SFS_HAND_PROPS) , hands);
                if (state.containsKey(key)) {
                    const hand: SFSObject = state.getSFSObject(key);
                    const card: string | undefined = hand.containsKey('cc') ? hand.getUtfString('cc') : undefined;
                    //console.log("Card to trick : " , card," from hand: ", hands[index]);
                    if (card !== undefined && card.length === 2) {
                        this.props.table_playCard(strToCardId(card));
                        cardUpdates.push({
                            bridgePosition: bridgePositions[index],
                            cardPosition: CardPosition.TRICK,
                            id: strToCardId(card),
                            visible: true,
                        });
                    }
                }
            }
        });

        this.props.cards_setCards(cardUpdates);
    }

    public setCurrentPlayer(player: BridgePosition) {
        this.props.table_updateTable({
            player: player,
        });
        Object.values(this.props.table.seatData).forEach((value, index) => {
            this.props.table_setSeatData({
                seatPosition: value.seatPosition ?? SeatPosition.top,
                background: value.bridgePosition === player ? fixColor('y') : undefined,
            });
        });
        this.props.app_updateEngineInterfaceState({
            currentPlayer: player,
        });
    }

    /*
     public SFSObject getSFS(){
        SFSObject result = new SFSObject();
        result.putUtfString("m", message );
        if (title != null)  result.putUtfString("t", title );
        if (color != null)  result.putUtfString("c", color );
        if (button != null) result.putUtfString("ok", button );
        if (cancelButton != null) result.putUtfString("cancel", cancelButton );
        return result;
    }
     */

    public setMessage(position: 'center' | 'left' | 'right', message: SFSObject) {
        const m: AuxiliaryMessage = {
            text: message.getUtfString('m'),
            title: message.containsKey('t') ? message.getUtfString('t') : undefined,
            titleColor: message.containsKey('c') ? message.getUtfString('c') : undefined,
            buttonOk: message.containsKey('ok') ? message.getUtfString('ok') : undefined,
            buttonCancel: message.containsKey('cancel') ? message.getUtfString('cancel') : undefined,
            buttonAuxiliary: message.containsKey('aux') ? message.getUtfString('aux') : undefined,
        };
        this.props.table_setAuxiliaryMessage({ position: position, message: m });
    }

    public setSimpleMessage(position: 'center' | 'left' | 'right', message: string, okButton: string | undefined) {
        const m: AuxiliaryMessage = {
            text: message,
            title: undefined,
            titleColor: undefined,
            buttonOk: undefined,
            buttonCancel: okButton,
            buttonAuxiliary: undefined,
        };
        this.props.table_setAuxiliaryMessage({ position: position, message: m });
    }

    public setControlButtons(cmdObj: SFSObject | undefined) {
        const noButtons = cmdObj === undefined;
        //Add the ever present Dashboard
        const buttons: Record<string, Button> = {
            'button-0': {
                label: noButtons || !cmdObj.containsKey('button0') ? 'Dashboard' : cmdObj.getUtfString('button0'),
                id: 'dashboard',
                value: true,
                highlighted: false,
                key: 'button-0',
            },
        };
        if (!noButtons) {
            for (let i = 1; i <= 10; i++) {
                const buttonIndex = `button${i}`;

                if (cmdObj?.containsKey(buttonIndex)) {
                    buttons[`button-${i}`] = {
                        label: cmdObj.getSFSObject(buttonIndex).getUtfString('t'),
                        id: cmdObj.getSFSObject(buttonIndex).getUtfString('bid') as PossibleButtonId,
                        value: cmdObj.getSFSObject(buttonIndex).getUtfString('a'),
                        highlighted: cmdObj.getSFSObject(buttonIndex).getBool('h'),
                        key: `button-${i}` as ButtonKey,
                    };
                }
            }
        }
        this.props.table_setFooterButtons(buttons);
    }

    public setTableInfo(state: SFSObject) {
        //
    }

    public setContractFromSFSObject(cmdObj: SFSObject) {
        //
    }

    protected setIndexMenu(params: SFSObject) {
        //TODO: We have SFSArray with the topic index.
        const buttons: SFSArray = params.getSFSArray(SFSVAR.SFSGAME_MID_LOAD);
        const index: TableState['indexButtons'] = [];
        for (let i = 0; i < buttons.size(); i++) {
            const button: SFSObject = buttons.getSFSObject(i);
            index.push({
                label: button.getUtfString('title'),
                id: 'indexButton',
                value: i,
                highlighted: false,
                key: `button-${i}` as ButtonKey,
            });
        }
        this.indexButtons = index;
        //console.log("protected Buttons:" , this.indexButtons);

        this.props.table_setIndexButtons(this.indexButtons);

        if (params.containsKey('favurl')) {
            this.favurl = params.getUtfString('favurl');
        }
    }

    public setHandVisibility(visibility: number) {
        //  console.log('Visibility to chage to ', visibility);
        const bridgeRulesOn: boolean = (visibility & 1) !== 0;

        if (visibility === 0) {
            Object.values(SeatPosition).forEach((value, index) => {
                this.props.table_setSeatData({ seatPosition: value, isVisible: false, isVertical: false });
            });
            return;
        }

        Object.values(BridgePosition).forEach((value, index) => {
            this.props.table_setSeatData({
                seatPosition: SeatPosFor(value, this.props.table),
                isVisible: (visibility & Math.pow(2, index + 1)) !== 0,
            });
        });

        if (bridgeRulesOn) {
            this.props.table_setSeatData({ seatPosition: SeatPosition.bottom, isVisible: true });
            if (this.props.table.declarer) {
                const dummy = getPartnerBridgePosition(this.props.table.declarer);
                this.props.table_setSeatData({
                    seatPosition: SeatPosFor(dummy, this.props.table),
                    isVisible: true,
                });
            }
        }
    }

    protected loadBoardReview(g_uuid: string, p_uuid: string, bn: number, c_uuid: string | undefined) {
        //
    }

    public updateBoardData(
        extraPlayer:
            | { auction: Auction; tricks: Trick[]; winner: BridgePosition[]; declarer: BridgePosition }
            | undefined,
    ) {
        //
    }

    protected clearAuxiliaryMessages() {
        this.props.table_setAuxiliaryMessage({ position: 'left', message: undefined });
        this.props.table_setAuxiliaryMessage({ position: 'right', message: undefined });
        this.props.table_setAuxiliaryMessage({ position: 'center', message: undefined });
        this.props.app_removeModal(undefined);
    }

    public isPostMortem() {
        return (
            this.props.app.engineState.interface.lobbyRoom?.containsVariable(SFSVAR.SFSGAME_ROOM_IS_POSPORTEM) &&
            (this.props.app.engineState.interface.lobbyRoom?.getVariable(SFSVAR.SFSGAME_ROOM_IS_POSPORTEM)
                .value as boolean)
        );
    }

    //Locally handled user actions

    protected toggleIndexMenu = () => {
        //  console.log('Toggle Index Menu');
        this.props.table_setWalkThroughIframe(undefined);
        this.props.table_updateTable({
            showIndexButtons: !this.props.table.showIndexButtons,
        });
    };

    public hideButtons(buttons: Button[]) {
        buttons.forEach((button, index) => {
            this.props.table_updateFooterButtons([{ key: button.key, button: undefined }]);
        });
    }

    public showButtons(buttons: Button[]) {
        buttons.forEach((button, index) => {
            this.props.table_updateFooterButtons([{ key: button.key, button: button }]);
        });
    }

    public shouldShowUndo() {
        return false;
    }

    public shouldShowClaim() {
        return false;
    }

    public shouldShowConcede() {
        return false;
    }

    //Navigation actions by user sent to server
    protected clientRequestsNextPoint = () => {
        this.props.table_setWalkThroughIframe(undefined);
        this.sendRoomCommand(SFS_NAV.CMD_NEXT_POINT, new SFSObject());
    };

    protected clientRequestsPrevHand = () => {
        const sfsObj = new SFSObject();
        sfsObj.putBool(SFSVAR.SFSGAME_MID_LOAD, false);
        sfsObj.putInt(SFSVAR.EXTENSION_MID, SFSVAR.netMID.MID_CONTROL_ACTION);
        this.sendRoomCommand(SFSVAR.CMD_PREV_DEAL, new SFSObject());
        this.sendRoomCommand(SFSVAR.CMD_CONTROL_ACTION, sfsObj);
    };

    protected clientRequestsNextHand = () => {
        const sfsObj = new SFSObject();
        sfsObj.putBool(SFSVAR.SFSGAME_MID_LOAD, false);
        sfsObj.putInt(SFSVAR.EXTENSION_MID, SFSVAR.netMID.MID_CONTROL_ACTION);
        this.sendRoomCommand(SFSVAR.CMD_NEXT_DEAL, new SFSObject());
        this.sendRoomCommand(SFSVAR.CMD_CONTROL_ACTION, sfsObj);
    };

    protected clientRequestsPreviousPoint = () => {
        this.sendRoomCommand(SFS_NAV.CMD_PREV_POINT, new SFSObject());
    };

    protected clientRequestsTraining = () => {
        this.forceShowAllCards = false;
        this.sendRoomCommand(SFS_NAV.CMD_LOAD_TRAINING, new SFSObject());
    };

    protected clientRequestsAutoBid = () => {
        this.sendRoomCommand(SFS_NAV.CMD_AUTOBID, new SFSObject());
    };

    protected clientRequestsAnswer = () => {
        this.sendRoomCommand(SFS_NAV.CMD_ANSWER, new SFSObject());
    };

    protected clientRequestsUndo = () => {
        this.sendRoomCommand(SFS_NAV.CMD_UNDO, new SFSObject());
    };

    protected clientRequestsReplay = () => {
        this.sendRoomCommand(SFS_NAV.CMD_REPLAY, new SFSObject());
    };

    protected clientRequestsSkip = () => {
        this.props.table_setWalkThroughIframe(undefined);
        this.sendRoomCommand(SFS_NAV.CMD_NEXT_SCREEN, new SFSObject());
    };

    protected clientRequestsTopicAtIndex = (index: number) => {
        //console.log("Index Button clicked" , index);
        const sfsObj = new SFSObject();
        sfsObj.putInt(SFSVAR.SFSGAME_MID_LOAD, index);
        this.sendRoomCommand(SFS_NAV.CMD_LOAD_INDEX, sfsObj);
    };

    protected clientRequestToggleVisibility() {
        this.forceShowAllCards = !this.props.table.forceShowAllCards;
        this.props.table_setForceShowAllCards(!this.props.table.forceShowAllCards);
    }

    protected clientRequestsFav = (notes: string) => {
        // console.log('Add Fav Press', this.favurl);
        this.props.table_setWalkThroughIframe(this.props.table.walkThroughIframe ? undefined : this.favurl);
        //    window.postMessage('closefav', '*');
        /*    const sfsObj = new SFSObject();
        sfsObj.putUtfString(SFSVAR.SFSGAME_MID_LOAD, notes);
        this.sendRoomCommand(SFS_NAV.CMD_FAV, sfsObj);*/
    };

    protected clientRequestsHTMLLink = (clickEventData: ClickEventData) => {
        //parce the link and handle it.
        //console.log('HTML Clicked ', clickEventData);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (clickEventData.target && clickEventData.target === 'quiz_submit_OK') {
            this.sendRoomCommand(SFS_NAV.CMD_NEXT_SCREEN, new SFSObject());
        } else if (clickEventData.target && clickEventData.target === 'link') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore

            if (clickEventData.value.startsWith('quiz')) {
                const obj: SFSObject = new SFSObject();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                obj.putUtfString(SFSVAR.SFSGAME_MID_LOAD, clickEventData.href.split(':', 2)[1]);
                this.sendRoomCommand(SFS_NAV.CMD_ANSWER, obj);
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            else if (clickEventData.value.startsWith('glossarylink::')) {
                // console.log("Event enter : ", clickEventData);
                /* const m: AuxiliaryMessage = {
                    text: 'Description of the current term',
                    title: clickEventData.value?.replaceAll('glossarylink::',''),
                    titleColor: undefined,
                    buttonOk: undefined,
                    buttonCancel: undefined,
                    buttonAuxiliary: undefined,
                };*/
                if (clickEventData.value?.replaceAll('glossarylink::', '')) {
                    this.props.table_setTooltip({
                        name: clickEventData.value?.replaceAll('glossarylink::', '').toLowerCase(),
                        boundingClientRect: clickEventData.boundingClientRect,
                    });
                }
            }
        } else if (clickEventData.target === 'cancel') {
            //console.log("Event exit : ", clickEventData);
            if (clickEventData?.value && clickEventData?.value.startsWith('glossarylink::')) {
                this.props.table_setTooltip(undefined);
            }
        }
        this.props.output_reset();
    };

    protected clientRequestsSettingsUpdate() {
        const sfsObj = new SFSObject();
        sfsObj.putUtfString('speedCards', this.props.app.settings.speedCards);
        sfsObj.putUtfString('speedScreens', this.props.app.settings.speedScreens);
        sfsObj.putBool('requiredNext', this.props.app.settings.requiredNext);
        this.sendRoomCommand(SFS_NAV.CMD_LOAD_SETTINS, sfsObj);
    }

    //Game actions by user sent to server
    protected clientRequestsMakeCall = (bid: Bid) => {
        this.props.output_reset();
        console.log("Test Test", bid);
        if (!bid.call) {
            return;
        }
        //console.log('bid: ', bid);
        //Disable Bidding Keyboard
        this.props.table_updateBiddingLadder({
            //  isActive: false,
        });
        const sfsObj = new SFSObject();
        //IMPORTANT Call need to have Int parameter, one of  /* Call intValue consta nts */
        sfsObj.putUtfString(SFSVAR.SFSGAME_MID_LOAD, bid.call);
        sfsObj.putBool(SFSVAR.SFSGAME_MID_ROBOT, false);
        this.sendRoomCommand(SFSVAR.CMD_MAKE_CALL, sfsObj);
    };

    protected clientRequestsExplCall = (bid: Bid) => {
        //
    };

    protected clientRequestsMakePlay(card: Card) {
        //    console.log('Make a Play : ', card);
        this.props.output_reset();
        this.props.table_setWalkTrough(undefined);
        this.props.table_setAuxiliaryMessage({ position: 'left', message: undefined });
        this.props.table_setAuxiliaryMessage({ position: 'center', message: undefined });
        this.props.table_setSeatData({
            seatPosition: SeatPosFor(card.bridgePosition, this.props.table),
            isInteractive: false,
            background: undefined,
        });
        const sfsObj = new SFSObject();
        sfsObj.putUtfString(SFSVAR.SFSGAME_MID_LOAD, card.id);
        sfsObj.putBool(SFSVAR.SFSGAME_MID_ROBOT, false);
        sfsObj.putInt(SFSVAR.SFSGAME_CURENT_PLAYER, card.bridgePosition ? numBridgePos[card.bridgePosition] ?? -1 : -1);
        this.sendRoomCommand(SFSVAR.CMD_MAKE_PLAY, sfsObj);
    }

    public clientResponseClaim(response: string) {
       // console.log('Sending Accept Claim', response);
        this.props.output_reset();
        const resp: boolean = response === 'approve';
        // TODO send notification to server
        const sfsObj = new SFSObject();
        sfsObj.putInt(SFSVAR.EXTENSION_MID, SFSVAR.netMID.MID_CLAIM_RESPONSE);
        sfsObj.putBool(SFSVAR.CLAIM_RESPONSE, resp);
        sfsObj.putInt(
            SFSVAR.SFSGAME_SEAT_ID,
            convertPlayerIdToIntBridgePosition(sfsService.sfs?.mySelf.getPlayerId(sfsService.sfs?.lastJoinedRoom) ?? 0),
        );
        this.sendRoomCommand(SFSVAR.CMD_RESPONSE_CLAIM, sfsObj);
    }

    protected clientRequestsClaim = (claim: number | undefined) => {
        const sfsObj = new SFSObject();
        this.sendRoomCommand(SFSVAR.CMD_REQUEST_CLAIM, sfsObj);
    };

    protected clientRequestsAcceptClaim = () => {
        const sfsObj = new SFSObject();
        sfsObj.putBool(SFSVAR.CLAIM_RESPONSE, true);
        this.sendRoomCommand(SFSVAR.CMD_RESPONSE_CLAIM, sfsObj);
    };

    protected clientRequestsCloseTrick() {
        this.props.output_reset();
        this.sendRoomCommand(SFSVAR.CMD_CLOSE_TRICK, new SFSObject());
    }

    //SFS send helpers
    public sendRoomCommand(cmd: string, params: SFSObject | undefined) {
        if (!sfsService.sfs?.lastJoinedRoom.isGame) {
            return;
        }
        sfsService.sfs?.send(new ExtensionRequest(cmd, params, sfsService.sfs?.lastJoinedRoom));
    }
}

export const mapStateToProps = ({ app, cards, output, table }: RootState) => ({
    app,
    cards,
    output,
    table,
});

export const mapDispatchToProps = {
    ...appActions,
    ...cardActions,
    ...outputActions,
    ...tableActions,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GameEngineInterface);
