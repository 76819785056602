import { FunctionComponent, useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import tournamentStart from '../assets/sounds/tournament-start-2.mp3';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { appActions } from '../slices/appSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import yourTurn from '../assets/sounds/your-turn-2.mp3';

export type SoundFile = 'tournamentStart' | 'yourTurn';

export const soundTypes: (keyof SoundFiles)[] = ['yourTurn', 'tournamentStart'];

export type SoundFiles = Record<SoundFile, string>;

type Audios = {
    [key in SoundFile]?: {
        url: string;
        audio: HTMLAudioElement;
    };
};

const soundFiles: SoundFiles = {
    tournamentStart: tournamentStart as string,
    yourTurn: yourTurn as string,
};

const AudioPlayer: FunctionComponent = (props) => {
    const dispatch = useAppDispatch();
    const { sharkSettings, soundPlaying } = useAppSelector((state) => state.app);
    const { app_setSoundPlaying } = appActions;

    const [audios, setAudios] = useState<Audios | undefined>(undefined);

    const getSoundFileKeysArray = (): (keyof SoundFiles)[] => {
        return Object.keys(soundFiles).map((_key) => {
            return _key as keyof SoundFiles;
        });
    };

    useEffect(() => {
        const _audios: Audios = {};
        getSoundFileKeysArray().forEach((key) => {
            const url = soundFiles[key];
            const audio = new Audio(url);
            audio.addEventListener('ended', () => {
                dispatch(app_setSoundPlaying());
            });
            _audios[key] = { url, audio };
        });
        setAudios(_audios);
    }, []);

    useEffect(() => {
        if (!audios) {
            return;
        }
        getSoundFileKeysArray().forEach((key) => {
            if (!audios[key]) {
                return;
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            audios[key].audio.pause();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            audios[key].audio.currentTime = 0;
        });
        if (soundPlaying && sharkSettings.sounds[soundPlaying]) {
            audios[soundPlaying]?.audio.play();
        }
    }, [soundPlaying]);

    return null;
};
export default AudioPlayer;
