import './shark-tray-component.scss';
import { BridgePosition, GamePhase, SeatData } from '../../app/types';
import { ButtonComponent } from '../button-component/button-component';
import { CountdownComponent } from '../countdown-component/countdown-component';
import { LabeledInput } from '../../_basics/labeled-input/labeled-input';
import { TableState, Video, tableActions } from '../../slices/tableSlice';
import { Translate } from '../../_basics/translate';
import { appActions } from '../../slices/appSlice';
import { classNames } from '../../utils/mixed';
import { getGameResultModalConfig, getMetaDataToList, getPartnerBridgePosition, insertSuits } from '../../utils/shark-helper';
import { infoButton, settingsButton } from '../../app-interfaces/buttonsState';
import { outputActions } from '../../slices/outputSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import SharkBoardReviewComponent from '../shark-board-review-component/shark-board-review-component';
import moment from 'moment';
import useLocalStorage from 'react-use-localstorage';

export type SharkTrayComponentProps = {
    currentSeatData: SeatData;
    onToggleShow?: () => void;
    tableId?: string;
    onSetMyBridgePosition?: (bridgePosition: BridgePosition) => void;
};

const SharkTrayComponent: FunctionComponent<SharkTrayComponentProps> = (props) => {
    const { currentSeatData, onToggleShow, onSetMyBridgePosition, tableId } = props;
    const dispatch = useAppDispatch();
    const { sharkSettings, urlParams } = useAppSelector((state) => state.app);
    const {
        buttons,
        conventionCards,
        conventionCardUrlSet,
        declarer,
        directorIssue,
        directorIssueDone,
        gamePhase,
        sharkGameResults,
        sharkGameResultsV2,
        isSpectator,
        jitsi,
        sharkMetaData,
        showInvites,
        timeToGameEnd,
        video,
    } = useAppSelector((state) => state.table);
    const { output_setCloseResults, output_setDirector, output_setInvite, output_sendConventionCardUrl } =
        outputActions;
    const { table_updateTable, table_setActive, table_setVideo, table_setConventionCards } = tableActions;
    const { app_addModal, app_removeModal } = appActions;

    const { bridgePosition, isMe, labelPrimary, isVisible, isInteractive, isHighlighted } = currentSeatData;
    const isDummy = bridgePosition && declarer && getPartnerBridgePosition(declarer) === bridgePosition;
    //const [storedConventionCardUrl, setStoredConventionCardUrl] = useLocalStorage('conventionCardUrl', undefined);
    const [storedEmails, setStoredEmails] = useLocalStorage('emails', undefined);
    const [conventionCardUrl] = useState<string>('');
    const [inviteMail, setInviteMail] = useState<string>('');
    const [showInvite, setShowInvite] = useState(false);
    const [emailArray, setEmailArray] = useState<string[]>([]);
    const { t } = useTranslation();
    useEffect(() => {
        if (isMe && conventionCardUrlSet) {
            dispatch(table_updateTable({ conventionCardUrlSet: false }));
            dispatch(output_sendConventionCardUrl(conventionCards.url));
        }
    }, [conventionCardUrlSet]);
    useEffect(() => {
        if (isMe && directorIssueDone) {
            dispatch(output_setDirector(directorIssue));
            dispatch(table_updateTable({ directorIssue: '' }));
            dispatch(table_updateTable({ directorIssueDone: false }));
        }
    }, [directorIssueDone]);
    const showFlipCardsButton = isVisible && !isMe && !isDummy;

    useEffect(() => {
        try {
            setEmailArray(storedEmails.split('|'));
        } catch (e) {
            setEmailArray([]);
        }
    }, [storedEmails]);

    const style = {
        ...(isHighlighted && sharkSettings.currentPlayerHighlight.indexOf('background') !== -1
            ? { background: sharkSettings.selectedCurrentPlayerBackground }
            : {}),
    };

    const handleDownload = () => {
        if (!urlParams) {
            return;
        }
        const element = document.createElement('a');
        const file = new Blob(['12345678'], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `${urlParams.sfsteacher.replace(/[^-\w]/, '')}-${moment(Date.now()).format(
            t('base.dateFormat'),
        )}.bpn`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        document.body.removeChild(element);
    };

    const handleSetMyBridgePosition = () => {
        if (isSpectator && onSetMyBridgePosition && currentSeatData?.bridgePosition) {
            onSetMyBridgePosition(currentSeatData.bridgePosition);
        }
    };

    const handleTrayClick = () => {
        if (gamePhase === GamePhase.DEAL) {
            dispatch(table_setActive(currentSeatData.bridgePosition));
        }
    };

    const handleToggleVideoAttribute = (attribute: keyof Video) => () => {
        if (!video) {
            return;
        }
        dispatch(table_setVideo({ [attribute]: !video[attribute] }));
    };

    const hasMetaData = () => {
        if (!sharkMetaData) {
            return false;
        }
        const keys = Object.keys(sharkMetaData).map((key) => key as keyof TableState['sharkMetaData']);
        keys.filter((key) => sharkMetaData[key]);

        return !!keys.filter((key) => sharkMetaData[key]);
    };

    const handleChangeConventionCardUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(table_setConventionCards({ partnership: 'url', url: e.target.value }));
    };

    const handleSubmitChangeConventionCardUrl = () => {
        dispatch(table_updateTable({ conventionCardUrlSet: true }));
    };

    const handleSubmitDirectorIssue = () => {
        dispatch(app_removeModal('contactDirectorModal'));
        dispatch(table_updateTable({ directorIssueDone: true }));
    };

    const handleInvite = () => {
        const mailCheck = new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
        if (mailCheck.test(inviteMail) && bridgePosition) {
            dispatch(
                output_setInvite({
                    email: inviteMail,
                    bridgePosition,
                }),
            );
            setShowInvite(false);
            if (!emailArray.includes(inviteMail)) {
                setStoredEmails([...(storedEmails ? storedEmails.split('|') : []), inviteMail].join('|'));
            }
        } else {
            console.log('Invalid Email Address');
        }
    };

    const showConventionCardModal = (partnership: keyof TableState['conventionCards']) => () => {
        const url = conventionCards[partnership];
        if (url) {
            dispatch(app_removeModal('metaDataModal'));
            dispatch(
                app_addModal({
                    id: 'conventionCardModal',
                    noCancel: true,
                    header: t('table.conventionCardModal.header', { partnership: partnership.toUpperCase() }),
                    body: [<iframe key="iframe" src={url} />],
                }),
            );
        }
    };

    const showContactDirectorModal = () => {
        dispatch(app_removeModal('metaDataModal'));
        dispatch(
            app_addModal({
                id: 'contactDirectorModal',
                header: t('table.contactDirectorModal.header'),
                body: [
                    <div key="submitIssue" className="submitIssue">
                        <LabeledInput
                            label={t(`table.contactDirectorModal.label`)}
                            type="text"
                            value={directorIssue}
                            onChange={(e) => dispatch(table_updateTable({ directorIssue: e.target.value }))}
                        />
                    </div>,
                ],
                buttons: [
                    {
                        label: t(`table.sharkMetaData.conventionCard.submit.button`),
                        onClick: handleSubmitDirectorIssue,
                    },
                ],
                onCancel: () => {
                    dispatch(table_updateTable({ directorIssue: '' }));
                },
            }),
        );
    };

    const showConventionCard = (partnership: keyof TableState['conventionCards']) => () => {
        const url = conventionCards[partnership];
        window.open(url, '_blank');
    };

    const renderConventionCards = () => {
        return (
            <div className="conventionCards">
                <div className="conventionCard">
                    <span>{t(`table.sharkMetaData.conventionCard.ns.label`)}</span>
                    <button onClick={showConventionCard('ns')} disabled={!conventionCards.ns}>
                        {t(`table.sharkMetaData.conventionCard.ns.button`)}
                    </button>
                </div>
                <div className="conventionCard">
                    <span>{t(`table.sharkMetaData.conventionCard.ew.label`)}</span>
                    <button onClick={showConventionCard('ew')} disabled={!conventionCards.ew}>
                        {t(`table.sharkMetaData.conventionCard.ew.button`)}
                    </button>
                </div>
                <div className="submitUrl">
                    <LabeledInput
                        label={t(`table.sharkMetaData.conventionCard.submit.placeholder`)}
                        type="text"
                        value={conventionCardUrl}
                        onChange={handleChangeConventionCardUrl}
                    />
                    <button onClick={handleSubmitChangeConventionCardUrl}>
                        {t(`table.sharkMetaData.conventionCard.submit.button`)}
                    </button>
                </div>
            </div>
        );
    };

    const showMetaData = () => {
        if (!sharkMetaData) {
            return;
        }
        const { welcomeMessage, resultUrl, director, isSupervised } = sharkMetaData;
        dispatch(app_removeModal());
        dispatch(
            app_addModal({
                id: 'metaDataModal',
                cancelButtonLabel: t('table.sharkMetaData.ok'),
                // header: welcomeMessage ? welcomeMessage : undefined,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                body: [...getMetaDataToList(sharkMetaData), renderConventionCards()],
                noCancel: true,
                buttons: [
                    ...(director && isSupervised
                        ? [{ label: director, onClick: () => showContactDirectorModal() }]
                        : []),
                    ...(resultUrl
                        ? [{ label: t(`table.sharkMetaData.resultUrl.label`), onClick: showResultsUrlModal }]
                        : []),
                    ...(sharkGameResults || sharkGameResultsV2
                        ? [{ label: t(`table.sharkMetaData.sharkGameResults.label`), onClick: showGameResultsModal }]
                        : []),
                    {
                        label: t('table.sharkMetaData.boardReview'),
                        onClick: () => {
                            sharkMetaData.isBiddingQuiz ? showAuctionReviewModal() : showBoardReviewModal();
                            dispatch(app_removeModal('metaDataModal'));
                        },
                    },
                    { label: t('table.sharkMetaData.ok'), onClick: () => dispatch(app_removeModal('metaDataModal')) },
                ],
            }),
        );
    };

    const showGameResultsModal = () => {
        dispatch(app_removeModal('metaDataModal'));
        dispatch(
            app_addModal({
                ...getGameResultModalConfig(sharkGameResultsV2 ?? sharkGameResults, !!sharkGameResultsV2),
                onCancel: () => dispatch(output_setCloseResults(true)),
            }),
        );
    };

    const showResultsUrlModal = () => {
        if (!sharkMetaData) {
            return;
        }
        const { resultUrl } = sharkMetaData;
        dispatch(app_removeModal('metaDataModal'));
        dispatch(
            app_addModal({
                id: 'gameResultsUrlModal',
                cancelButtonLabel: t('table.sharkMetaData.ok'),
                header: <span>GameResults</span>,
                body: [<iframe key="iframe" src={resultUrl} />],
            }),
        );
    };

    const showBoardReviewModal = () => {
        dispatch(
            app_addModal({
                id: 'showBoardReviewModal',
                body: [<SharkBoardReviewComponent key="SharkBoardReviewComponent" />],
                noCancel: true,
                buttons: [
                    {
                        label: t('table.boardReview.ok'),
                        onClick: () => dispatch(app_removeModal('showBoardReviewModal')),
                    },
                ],
            }),
        );
    };

    const showAuctionReviewModal = () => {
        if (!sharkMetaData) {
            return;
        }
        const id = 'nextBoard';
        const { comment } = sharkMetaData;

        dispatch(
            app_addModal({
                id,
                noClickOutside: true,
                noHeaderClose: true,
                body: [<div key="nextBoard-body-1" dangerouslySetInnerHTML={{ __html: insertSuits(comment ?? '') }} />],
                cancelButtonLabel: <Translate contentKey="modal.ok" />,
                onCancel: () => dispatch(app_removeModal(id)),
                buttons: [],
                className: id,
                showForeignBoardReviewData: true,
                fullSize: true,
            }),
        );
    };

    const videoOptions = ['micro', 'camera'];

    const handleSelectEmail = (email: string) => {
        console.log('email', email);
        setInviteMail(email);
    };

    const invite = !isMe && !isSpectator && showInvites && (
        <div className="invite">
            {showInvite && (
                <div className="input">
                    <div className="storedEmails">
                        {emailArray
                            .filter((email) => email.startsWith(inviteMail) && email !== inviteMail)
                            .map((email) => (
                                <div key={email} className="storedEmail" onClick={() => handleSelectEmail(email)}>
                                    {email}
                                </div>
                            ))}
                    </div>
                    <input
                        autoComplete="off"
                        type="text"
                        name="invite"
                        value={inviteMail}
                        onChange={(e) => setInviteMail(e.target.value)}
                    />
                </div>
            )}
            {showInvite && <button onClick={handleInvite} className="sendInvite" />}
            {showInvite && <button onClick={() => setShowInvite(false)} className="closeInvite" />}
            {!showInvite && (
                <button onClick={() => setShowInvite(true)} className="showInvite">
                    @
                </button>
            )}
        </div>
    );

    const buttonArray = Object.values(buttons);

    return (
        <section
            className={classNames(
                'SharkTrayComponent',
                isInteractive ? 'isInteractive' : '',
                isHighlighted ? 'isHighlighted' : '',
                ...sharkSettings.currentPlayerHighlight.map(
                    (currentPlayerDisplay) => `currentPlayerDisplay-${currentPlayerDisplay}`,
                ),
            )}
            style={style}
            onClick={handleTrayClick}
        >
            <div className="general">
                <div className="bridgePosition">{t(`tray.bridgePosition.${bridgePosition}`)}</div>
                <div className="player">
                    <div className="name">{labelPrimary}</div>
                    {isMe && (
                        <div className="isMe">
                            ({t('tray.isMe')}
                            {tableId && ` ${tableId}`})
                        </div>
                    )}
                    {isDummy && <div className="isDummy">({t('tray.isDummy')})</div>}
                </div>
                <div className="actions">
                    {!isMe && isSpectator && <button className="rotateTable" onClick={handleSetMyBridgePosition} />}
                    {showFlipCardsButton && <button className="toggleShowCards" onClick={onToggleShow} />}
                    {isInteractive && <div className="isInteractive" />}
                    {invite}
                </div>
            </div>
            {gamePhase !== GamePhase.DEAL && isMe && (
                <div className={`justMe ${buttonArray.map((button) => button.id)}`}>
                    {timeToGameEnd != null && (
                        <div className="info">
                            <div className="timeToGameEnd">
                                <CountdownComponent startTime={timeToGameEnd} />
                            </div>
                        </div>
                    )}

                    <div className="buttonsCenter">
                        {buttonArray.map((button) => (
                            <ButtonComponent key={`${button.id} ${button.key}`} button={button} />
                        ))}
                    </div>
                    <div className="buttonsRight">
                        {/*{!!jitsi.roomName &&*/}
                        {/*    videoOptions.map((key) => {*/}
                        {/*        const attribute = key as keyof TableState['video'];*/}
                        {/*        return (*/}
                        {/*            <button*/}
                        {/*                key={`video ${attribute} ${video ? video[attribute] : ''}`}*/}
                        {/*                onClick={handleToggleVideoAttribute(attribute)}*/}
                        {/*                className={`video ${attribute} ${video ? video[attribute] : ''}`}*/}
                        {/*            />*/}
                        {/*        );*/}
                        {/*    })}*/}
                        {hasMetaData() && <ButtonComponent button={infoButton} />}
                        <ButtonComponent button={settingsButton} />
                    </div>
                </div>
            )}
        </section>
    );
};

export default SharkTrayComponent;
